"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SurgeryVitals_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ECGSnapshot_1 = require("./ECGSnapshot");
var SurgeryRecording_1 = require("./SurgeryRecording");
var SurgeryVitalsRecordingTime_1 = require("./SurgeryVitalsRecordingTime");
var SurgeryVitals_Factory = /** @class */ (function () {
    function SurgeryVitals_Factory() {
    }
    SurgeryVitals_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AnesthesiaEndTime: Date_Factory_1.Date_Factory.CreateIncoming(model.AnesthesiaEndTime),
            AnesthesiaStartTime: Date_Factory_1.Date_Factory.CreateIncoming(model.AnesthesiaStartTime),
            ECGSnapshots: model.ECGSnapshots && model.ECGSnapshots.map(function (i) { return ECGSnapshot_1.ECGSnapshot_Factory.CreateIncoming(i); }),
            EKGOther: model.EKGOther,
            EventRecordings: model.EventRecordings && model.EventRecordings.map(function (i) { return SurgeryRecording_1.SurgeryRecording_Factory.CreateIncoming(i); }),
            FluidSolution: model.FluidSolution,
            FluidVolume: model.FluidVolume,
            NormalSinusRhythum: model.NormalSinusRhythum,
            Notes: model.Notes,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SurgeryEndTime: Date_Factory_1.Date_Factory.CreateIncoming(model.SurgeryEndTime),
            SurgeryStartTime: Date_Factory_1.Date_Factory.CreateIncoming(model.SurgeryStartTime),
            SurgeryVitalsRecordingTimes: model.SurgeryVitalsRecordingTimes && model.SurgeryVitalsRecordingTimes.map(function (i) { return SurgeryVitalsRecordingTime_1.SurgeryVitalsRecordingTime_Factory.CreateIncoming(i); }),
            TimeOutReviewed: model.TimeOutReviewed,
            TimeOutReviewedAt: Date_Factory_1.Date_Factory.CreateIncoming(model.TimeOutReviewedAt),
            TimeToRecovery: Date_Factory_1.Date_Factory.CreateIncoming(model.TimeToRecovery),
        };
        return result;
    };
    SurgeryVitals_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AnesthesiaEndTime: Date_Factory_1.Date_Factory.CreateOutgoing(model.AnesthesiaEndTime),
            AnesthesiaStartTime: Date_Factory_1.Date_Factory.CreateOutgoing(model.AnesthesiaStartTime),
            ECGSnapshots: model.ECGSnapshots && model.ECGSnapshots.map(function (i) { return ECGSnapshot_1.ECGSnapshot_Factory.CreateOutgoing(i); }),
            EKGOther: model.EKGOther,
            EventRecordings: model.EventRecordings && model.EventRecordings.map(function (i) { return SurgeryRecording_1.SurgeryRecording_Factory.CreateOutgoing(i); }),
            FluidSolution: model.FluidSolution,
            FluidVolume: model.FluidVolume,
            NormalSinusRhythum: model.NormalSinusRhythum,
            Notes: model.Notes,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SurgeryEndTime: Date_Factory_1.Date_Factory.CreateOutgoing(model.SurgeryEndTime),
            SurgeryStartTime: Date_Factory_1.Date_Factory.CreateOutgoing(model.SurgeryStartTime),
            SurgeryVitalsRecordingTimes: model.SurgeryVitalsRecordingTimes && model.SurgeryVitalsRecordingTimes.map(function (i) { return SurgeryVitalsRecordingTime_1.SurgeryVitalsRecordingTime_Factory.CreateOutgoing(i); }),
            TimeOutReviewed: model.TimeOutReviewed,
            TimeOutReviewedAt: Date_Factory_1.Date_Factory.CreateOutgoing(model.TimeOutReviewedAt),
            TimeToRecovery: Date_Factory_1.Date_Factory.CreateOutgoing(model.TimeToRecovery),
        };
        return result;
    };
    return SurgeryVitals_Factory;
}());
exports.SurgeryVitals_Factory = SurgeryVitals_Factory;
