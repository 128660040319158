"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectedMonitor_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ConnectedMonitor_Factory = /** @class */ (function () {
    function ConnectedMonitor_Factory() {
    }
    ConnectedMonitor_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ARRecordId: model.ARRecordId,
            COMPort: model.COMPort,
            ECGGain1: model.ECGGain1,
            ECGGain2: model.ECGGain2,
            ECGPrinterSpeed: model.ECGPrinterSpeed,
            ECGWaveform1: model.ECGWaveform1,
            ECGWaveform2: model.ECGWaveform2,
            FriendlyName: model.FriendlyName,
            IsRecording: model.IsRecording,
            Model: model.Model,
            MonitorName: model.MonitorName,
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SerialNumber: model.SerialNumber,
        };
        return result;
    };
    ConnectedMonitor_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ARRecordId: model.ARRecordId,
            COMPort: model.COMPort,
            ECGGain1: model.ECGGain1,
            ECGGain2: model.ECGGain2,
            ECGPrinterSpeed: model.ECGPrinterSpeed,
            ECGWaveform1: model.ECGWaveform1,
            ECGWaveform2: model.ECGWaveform2,
            FriendlyName: model.FriendlyName,
            IsRecording: model.IsRecording,
            Model: model.Model,
            MonitorName: model.MonitorName,
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SerialNumber: model.SerialNumber,
        };
        return result;
    };
    return ConnectedMonitor_Factory;
}());
exports.ConnectedMonitor_Factory = ConnectedMonitor_Factory;
