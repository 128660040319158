"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pharmacy_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Address_1 = require("./Address");
var PhoneNumber_1 = require("./PhoneNumber");
var Pharmacy_Factory = /** @class */ (function () {
    function Pharmacy_Factory() {
    }
    Pharmacy_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Address: Address_1.Address_Factory.CreateIncoming(model.Address),
            AddressId: model.AddressId,
            Identifier: model.Identifier,
            Name: model.Name,
            Notes: model.Notes,
            PhoneNumber: PhoneNumber_1.PhoneNumber_Factory.CreateIncoming(model.PhoneNumber),
            PhoneNumberId: model.PhoneNumberId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    Pharmacy_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Address: Address_1.Address_Factory.CreateOutgoing(model.Address),
            AddressId: model.AddressId,
            Identifier: model.Identifier,
            Name: model.Name,
            Notes: model.Notes,
            PhoneNumber: PhoneNumber_1.PhoneNumber_Factory.CreateOutgoing(model.PhoneNumber),
            PhoneNumberId: model.PhoneNumberId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return Pharmacy_Factory;
}());
exports.Pharmacy_Factory = Pharmacy_Factory;
