"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhoenixModel_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Address_1 = require("./Address");
var AddressType_1 = require("./AddressType");
var AlignerBrand_1 = require("./AlignerBrand");
var AlignerTrackingPlan_1 = require("./AlignerTrackingPlan");
var AlignerTrayPlan_1 = require("./AlignerTrayPlan");
var AlignerTrayUsageType_1 = require("./AlignerTrayUsageType");
var AllergyType_1 = require("./AllergyType");
var AllowanceTable_1 = require("./AllowanceTable");
var AmbulatingType_1 = require("./AmbulatingType");
var AnesthesiaRecord_1 = require("./AnesthesiaRecord");
var Appliance_1 = require("./Appliance");
var ApplianceType_1 = require("./ApplianceType");
var AppointmentAuditLog_1 = require("./AppointmentAuditLog");
var AppointmentNote_1 = require("./AppointmentNote");
var AppointmentNoteType_1 = require("./AppointmentNoteType");
var Appointment_1 = require("./Appointment");
var AppointmentStatusCode_1 = require("./AppointmentStatusCode");
var AppointmentType_1 = require("./AppointmentType");
var ArchiveAccessRequest_1 = require("./ArchiveAccessRequest");
var ArchivedDaysheet_1 = require("./ArchivedDaysheet");
var ArchType_1 = require("./ArchType");
var Archwire_1 = require("./Archwire");
var ArchwireType_1 = require("./ArchwireType");
var BalanceAdjustment_1 = require("./BalanceAdjustment");
var BlockBookingTemplateDate_1 = require("./BlockBookingTemplateDate");
var BlockBookingTemplate_1 = require("./BlockBookingTemplate");
var BookingTemplateBlock_1 = require("./BookingTemplateBlock");
var BoxFileMigrationLog_1 = require("./BoxFileMigrationLog");
var BPEExam_1 = require("./BPEExam");
var Bracket_1 = require("./Bracket");
var BracketType_1 = require("./BracketType");
var BulkCorrespondenceBatch_1 = require("./BulkCorrespondenceBatch");
var BulkCorrespondencePatientInstance_1 = require("./BulkCorrespondencePatientInstance");
var BulkInsurancePaymentAdjustment_1 = require("./BulkInsurancePaymentAdjustment");
var BulkInsurancePaymentClaim_1 = require("./BulkInsurancePaymentClaim");
var BulkInsurancePayment_1 = require("./BulkInsurancePayment");
var BulkStatementBatch_1 = require("./BulkStatementBatch");
var BulkStatementBatchGuarantorPatient_1 = require("./BulkStatementBatchGuarantorPatient");
var BulkStatementBatchGuarantor_1 = require("./BulkStatementBatchGuarantor");
var BulkStatementBatchPatient_1 = require("./BulkStatementBatchPatient");
var CDTCodeCategoryType_1 = require("./CDTCodeCategoryType");
var CDTCode_1 = require("./CDTCode");
var Chair_1 = require("./Chair");
var ClinicalEventConditionCode_1 = require("./ClinicalEventConditionCode");
var ClinicalEventGroup_1 = require("./ClinicalEventGroup");
var ClinicalEvent_1 = require("./ClinicalEvent");
var ClinicalEventWatchDetail_1 = require("./ClinicalEventWatchDetail");
var ClinicalNote_1 = require("./ClinicalNote");
var ClinicalTreatmentMaterial_1 = require("./ClinicalTreatmentMaterial");
var CollectionAllocation_1 = require("./CollectionAllocation");
var CompletedMedicalQuestionnaireAnswer_1 = require("./CompletedMedicalQuestionnaireAnswer");
var CompletedMedicalQuestionnaire_1 = require("./CompletedMedicalQuestionnaire");
var ConcernType_1 = require("./ConcernType");
var ConnectedDevice_1 = require("./ConnectedDevice");
var ConnectedMonitor_1 = require("./ConnectedMonitor");
var ContactInfoType_1 = require("./ContactInfoType");
var ControlNumber_1 = require("./ControlNumber");
var ConversionNote_1 = require("./ConversionNote");
var CorrespondenceLetterPurposeType_1 = require("./CorrespondenceLetterPurposeType");
var CorrespondenceLetter_1 = require("./CorrespondenceLetter");
var CorrespondenceMergeFieldGroup_1 = require("./CorrespondenceMergeFieldGroup");
var CorrespondenceMergeField_1 = require("./CorrespondenceMergeField");
var CorrespondenceTemplateAddresseeType_1 = require("./CorrespondenceTemplateAddresseeType");
var CorrespondenceTemplateCategory_1 = require("./CorrespondenceTemplateCategory");
var CorrespondenceTemplateContent_1 = require("./CorrespondenceTemplateContent");
var CorrespondenceTemplateContentType_1 = require("./CorrespondenceTemplateContentType");
var CorrespondenceTemplateFormatType_1 = require("./CorrespondenceTemplateFormatType");
var CorrespondenceTemplate_1 = require("./CorrespondenceTemplate");
var Country_1 = require("./Country");
var CPTCode_1 = require("./CPTCode");
var CPTModifier_1 = require("./CPTModifier");
var DataExportTemplateMergeField_1 = require("./DataExportTemplateMergeField");
var DataExportTemplate_1 = require("./DataExportTemplate");
var DaysheetTransaction_1 = require("./DaysheetTransaction");
var DBScriptsLog_1 = require("./DBScriptsLog");
var DefaultRecallSetting_1 = require("./DefaultRecallSetting");
var ECGSnapshot_1 = require("./ECGSnapshot");
var EFormsAttachment_1 = require("./EFormsAttachment");
var EFormsAttachmentType_1 = require("./EFormsAttachmentType");
var EFormsInstance_1 = require("./EFormsInstance");
var EFormsBinaryElementDetail_1 = require("./EFormsBinaryElementDetail");
var EFormsDataElementCategory_1 = require("./EFormsDataElementCategory");
var EFormsDataElementOptionType_1 = require("./EFormsDataElementOptionType");
var EFormsDataElement_1 = require("./EFormsDataElement");
var EFormsDataElementType_1 = require("./EFormsDataElementType");
var EFormsInstanceDetail_1 = require("./EFormsInstanceDetail");
var EFormsInstanceHistoryLog_1 = require("./EFormsInstanceHistoryLog");
var EFormsInstanceView_1 = require("./EFormsInstanceView");
var EFormsLocationSettings_1 = require("./EFormsLocationSettings");
var EFormsNumericElementDetail_1 = require("./EFormsNumericElementDetail");
var EFormsSelectElementOption_1 = require("./EFormsSelectElementOption");
var EFormsSourceType_1 = require("./EFormsSourceType");
var EFormsStatusTracking_1 = require("./EFormsStatusTracking");
var EFormsStatus_1 = require("./EFormsStatus");
var EFormsTemplateCategory_1 = require("./EFormsTemplateCategory");
var EFormsTemplateDataElement_1 = require("./EFormsTemplateDataElement");
var EFormsTemplateDetail_1 = require("./EFormsTemplateDetail");
var EFormsToothChartElementDentitionType_1 = require("./EFormsToothChartElementDentitionType");
var EFormsToothChartElementDetail_1 = require("./EFormsToothChartElementDetail");
var EFormsToothChartElementTooth_1 = require("./EFormsToothChartElementTooth");
var EFormsTemplate_1 = require("./EFormsTemplate");
var Elastic_1 = require("./Elastic");
var ElasticType_1 = require("./ElasticType");
var ElasticUsageType_1 = require("./ElasticUsageType");
var EmploymentStatus_1 = require("./EmploymentStatus");
var EnterpriseConsultation_1 = require("./EnterpriseConsultation");
var EnterpriseConsultationsAuditLog_1 = require("./EnterpriseConsultationsAuditLog");
var EnterpriseConsultationSubjectLink_1 = require("./EnterpriseConsultationSubjectLink");
var EnterpriseLocationDepartment_1 = require("./EnterpriseLocationDepartment");
var EnterpriseLocation_1 = require("./EnterpriseLocation");
var EnterpriseReportingColumnDefinition_1 = require("./EnterpriseReportingColumnDefinition");
var EnterpriseReportingReport_1 = require("./EnterpriseReportingReport");
var EnterpriseUserLocation_1 = require("./EnterpriseUserLocation");
var EntityType_1 = require("./EntityType");
var EPaymentDevice_1 = require("./EPaymentDevice");
var EPaymentMerchant_1 = require("./EPaymentMerchant");
var EscortType_1 = require("./EscortType");
var EServicesReport_1 = require("./EServicesReport");
var EServicesSetting_1 = require("./EServicesSetting");
var EstateReferralAdditionalProcedure_1 = require("./EstateReferralAdditionalProcedure");
var EstateReferralAuditLog_1 = require("./EstateReferralAuditLog");
var EstateReferralRejectionReason_1 = require("./EstateReferralRejectionReason");
var EstateReferral_1 = require("./EstateReferral");
var EthnicityType_1 = require("./EthnicityType");
var GenderType_1 = require("./GenderType");
var HealthMeasurement_1 = require("./HealthMeasurement");
var HealthMeasurementType_1 = require("./HealthMeasurementType");
var HISIntegrationQueryHistory_1 = require("./HISIntegrationQueryHistory");
var ImagingOrder_1 = require("./ImagingOrder");
var ImagingReferenceType_1 = require("./ImagingReferenceType");
var CreateImagingRequest_1 = require("./CreateImagingRequest");
var ImplantBrand_1 = require("./ImplantBrand");
var ImplantBrandSize_1 = require("./ImplantBrandSize");
var ImplantDetail_1 = require("./ImplantDetail");
var ImplantDetailVersion_1 = require("./ImplantDetailVersion");
var ImplantManufacturer_1 = require("./ImplantManufacturer");
var ImplantRecord_1 = require("./ImplantRecord");
var InitialBalance_1 = require("./InitialBalance");
var InsuranceClaimAttachmentFile_1 = require("./InsuranceClaimAttachmentFile");
var InsuranceClaimAttachment_1 = require("./InsuranceClaimAttachment");
var InsuranceClaimBatchSubmission_1 = require("./InsuranceClaimBatchSubmission");
var InsuranceClaimContinuationItem_1 = require("./InsuranceClaimContinuationItem");
var InsuranceClaimItemAttachmentRequirement_1 = require("./InsuranceClaimItemAttachmentRequirement");
var InsuranceClaimItem_1 = require("./InsuranceClaimItem");
var InsuranceClaimNote_1 = require("./InsuranceClaimNote");
var InsuranceClaimNoteType_1 = require("./InsuranceClaimNoteType");
var InsuranceClaim_1 = require("./InsuranceClaim");
var InsuranceClaimStatusCode_1 = require("./InsuranceClaimStatusCode");
var InsuranceClaimType_1 = require("./InsuranceClaimType");
var InsuranceEligibilityType_1 = require("./InsuranceEligibilityType");
var InsurancePaperClaimFormat_1 = require("./InsurancePaperClaimFormat");
var InsurancePayerDetail_1 = require("./InsurancePayerDetail");
var InsurancePayer_1 = require("./InsurancePayer");
var InsurancePayerType_1 = require("./InsurancePayerType");
var InsurancePlanDetail_1 = require("./InsurancePlanDetail");
var InsurancePlan_1 = require("./InsurancePlan");
var InsurancePolicy_1 = require("./InsurancePolicy");
var InsurancePolicyCoverageType_1 = require("./InsurancePolicyCoverageType");
var ITeroScan_1 = require("./ITeroScan");
var IVLocation_1 = require("./IVLocation");
var IVType_1 = require("./IVType");
var JobStatus_1 = require("./JobStatus");
var LabCase_1 = require("./LabCase");
var Lab_1 = require("./Lab");
var LabtracDoctor_1 = require("./LabtracDoctor");
var LabtracEnclosure_1 = require("./LabtracEnclosure");
var LabtracItemOption_1 = require("./LabtracItemOption");
var LabtracLab_1 = require("./LabtracLab");
var LabtracShippingRoute_1 = require("./LabtracShippingRoute");
var Language_1 = require("./Language");
var LegacyIdentifier_1 = require("./LegacyIdentifier");
var LocationAccountsReceivable_1 = require("./LocationAccountsReceivable");
var LocationBulkStatementSetting_1 = require("./LocationBulkStatementSetting");
var LocationClaimsActivity_1 = require("./LocationClaimsActivity");
var LocationDefaultWorkingDay_1 = require("./LocationDefaultWorkingDay");
var LocationFinancialActivity_1 = require("./LocationFinancialActivity");
var Location_1 = require("./Location");
var LocationSetting_1 = require("./LocationSetting");
var LocationWorkingDay_1 = require("./LocationWorkingDay");
var LocationWorkingDayTime_1 = require("./LocationWorkingDayTime");
var MasterDataDistributionEvent_1 = require("./MasterDataDistributionEvent");
var MasterDataDistributionEventStats_1 = require("./MasterDataDistributionEventStats");
var MawidDeltaExtractProblem_1 = require("./MawidDeltaExtractProblem");
var MawidDeltaExtract_1 = require("./MawidDeltaExtract");
var MawidDeltaResponse_1 = require("./MawidDeltaResponse");
var MawidFirstExtractProblem_1 = require("./MawidFirstExtractProblem");
var MawidFirstExtract_1 = require("./MawidFirstExtract");
var MawidFirstResponse_1 = require("./MawidFirstResponse");
var MawidRequest_1 = require("./MawidRequest");
var MedicalAlertType_1 = require("./MedicalAlertType");
var MedicalClaimAdditionalInfo_1 = require("./MedicalClaimAdditionalInfo");
var MedicalClaimItemAdditionalInfo_1 = require("./MedicalClaimItemAdditionalInfo");
var MedicalInsuranceType_1 = require("./MedicalInsuranceType");
var MedicalQuestionnaireQuestionGroup_1 = require("./MedicalQuestionnaireQuestionGroup");
var MedicalQuestionnaireQuestion_1 = require("./MedicalQuestionnaireQuestion");
var MedicalQuestionnaire_1 = require("./MedicalQuestionnaire");
var MedicationType_1 = require("./MedicationType");
var MouthRegionGroup_1 = require("./MouthRegionGroup");
var NewChargeProcedure_1 = require("./NewChargeProcedure");
var NewCharge_1 = require("./NewCharge");
var NewPaymentResult_1 = require("./NewPaymentResult");
var NewPayment_1 = require("./NewPayment");
var NewPaymentSplit_1 = require("./NewPaymentSplit");
var NHSAcornAssessment_1 = require("./NHSAcornAssessment");
var NHSActivityBand_1 = require("./NHSActivityBand");
var NHSActivityBandValue_1 = require("./NHSActivityBandValue");
var NHSActivityValueSet_1 = require("./NHSActivityValueSet");
var NHSCaseMixRecordedCriteria_1 = require("./NHSCaseMixRecordedCriteria");
var NHSCaseMixRecording_1 = require("./NHSCaseMixRecording");
var NHSCaseMixScoreWeight_1 = require("./NHSCaseMixScoreWeight");
var NHSCategory_1 = require("./NHSCategory");
var NHSCategoryBand_1 = require("./NHSCategoryBand");
var NHSCategoryDate_1 = require("./NHSCategoryDate");
var NHSCDSBand_1 = require("./NHSCDSBand");
var NHSChargeBand_1 = require("./NHSChargeBand");
var NHSChargeBandValue_1 = require("./NHSChargeBandValue");
var NHSChargeValueSet_1 = require("./NHSChargeValueSet");
var NHSClaimComment_1 = require("./NHSClaimComment");
var NHSClaimMessageType_1 = require("./NHSClaimMessageType");
var NHSClaim_1 = require("./NHSClaim");
var NHSClaimStatusChange_1 = require("./NHSClaimStatusChange");
var NHSClaimSubmission_1 = require("./NHSClaimSubmission");
var NHSClinicalDataSet_1 = require("./NHSClinicalDataSet");
var NHSCLRN_1 = require("./NHSCLRN");
var NHSContractAdjustment_1 = require("./NHSContractAdjustment");
var NHSContractMembership_1 = require("./NHSContractMembership");
var NHSContractPeriodMonth_1 = require("./NHSContractPeriodMonth");
var NHSContractPeriod_1 = require("./NHSContractPeriod");
var NHSContract_1 = require("./NHSContract");
var NHSContractTarget_1 = require("./NHSContractTarget");
var NHSCourseClaimMessageInfo_1 = require("./NHSCourseClaimMessageInfo");
var NHSCourseDetail_1 = require("./NHSCourseDetail");
var NHSCourseDetailOrtho_1 = require("./NHSCourseDetailOrtho");
var NHSCourseSnapshot_1 = require("./NHSCourseSnapshot");
var NHSExemptCode_1 = require("./NHSExemptCode");
var NHSExemptionCheck_1 = require("./NHSExemptionCheck");
var NHSFP17TriageDetail_1 = require("./NHSFP17TriageDetail");
var NHSInterchange_1 = require("./NHSInterchange");
var NHSLocation_1 = require("./NHSLocation");
var NHSMailbox_1 = require("./NHSMailbox");
var NHSPatientDeclaration_1 = require("./NHSPatientDeclaration");
var NHSPatientDetail_1 = require("./NHSPatientDetail");
var NHSProcessingError_1 = require("./NHSProcessingError");
var NHSRegion_1 = require("./NHSRegion");
var NHSRegistrationType_1 = require("./NHSRegistrationType");
var NHSResponseHistory_1 = require("./NHSResponseHistory");
var NHSSchedulePayment_1 = require("./NHSSchedulePayment");
var NHSSchedule_1 = require("./NHSSchedule");
var NHSTransmissionFile_1 = require("./NHSTransmissionFile");
var NHSTreatmentArrangement_1 = require("./NHSTreatmentArrangement");
var NHSTriageDetail_1 = require("./NHSTriageDetail");
var NHSUserDetail_1 = require("./NHSUserDetail");
var NoteContentType_1 = require("./NoteContentType");
var Note_1 = require("./Note");
var NoteTemplateCategory_1 = require("./NoteTemplateCategory");
var NoteTemplateFavourite_1 = require("./NoteTemplateFavourite");
var NoteTemplate_1 = require("./NoteTemplate");
var NoteVersion_1 = require("./NoteVersion");
var OrthoContractChargeSchedule_1 = require("./OrthoContractChargeSchedule");
var OrthoContractCorrespondenceSetting_1 = require("./OrthoContractCorrespondenceSetting");
var OrthoContractFrequency_1 = require("./OrthoContractFrequency");
var OrthoContract_1 = require("./OrthoContract");
var OrthoContractStatus_1 = require("./OrthoContractStatus");
var OrthoPatientTreatmentStatus_1 = require("./OrthoPatientTreatmentStatus");
var OrthoTreatmentStatus_1 = require("./OrthoTreatmentStatus");
var PatientAllergy_1 = require("./PatientAllergy");
var PatientAllergyVersion_1 = require("./PatientAllergyVersion");
var PatientArchiveRecord_1 = require("./PatientArchiveRecord");
var PatientConcern_1 = require("./PatientConcern");
var PatientConnection_1 = require("./PatientConnection");
var PatientConnectionType_1 = require("./PatientConnectionType");
var PatientContactNote_1 = require("./PatientContactNote");
var PatientERxDetail_1 = require("./PatientERxDetail");
var PatientHistory_1 = require("./PatientHistory");
var PatientImageFavourite_1 = require("./PatientImageFavourite");
var PatientImagingIdentifier_1 = require("./PatientImagingIdentifier");
var PatientLedgerBalance_1 = require("./PatientLedgerBalance");
var PatientMedication_1 = require("./PatientMedication");
var PatientMedicationVersion_1 = require("./PatientMedicationVersion");
var PatientNoteType_1 = require("./PatientNoteType");
var PatientNotificationAlertDismissal_1 = require("./PatientNotificationAlertDismissal");
var PatientNotification_1 = require("./PatientNotification");
var PatientPaymentMethod_1 = require("./PatientPaymentMethod");
var PatientProcedureCPTModifiers_1 = require("./PatientProcedureCPTModifiers");
var PatientProcedureGroup_1 = require("./PatientProcedureGroup");
var PatientProcedureNote_1 = require("./PatientProcedureNote");
var PatientProcedureNoteType_1 = require("./PatientProcedureNoteType");
var PatientProcedure_1 = require("./PatientProcedure");
var PatientRecallCycleUnitType_1 = require("./PatientRecallCycleUnitType");
var PatientRecallInfo_1 = require("./PatientRecallInfo");
var PatientRecallPreference_1 = require("./PatientRecallPreference");
var PatientRecall_1 = require("./PatientRecall");
var PatientResponsibleParty_1 = require("./PatientResponsibleParty");
var PatientRxAuditLog_1 = require("./PatientRxAuditLog");
var PatientRx_1 = require("./PatientRx");
var Patient_1 = require("./Patient");
var PatientsMedicalAlert_1 = require("./PatientsMedicalAlert");
var PatientSnapshot_1 = require("./PatientSnapshot");
var PatientStatement_1 = require("./PatientStatement");
var PatientStatus_1 = require("./PatientStatus");
var PatientTreatmentStatus_1 = require("./PatientTreatmentStatus");
var PatientWaitingListNote_1 = require("./PatientWaitingListNote");
var PatientWaitingListNoteType_1 = require("./PatientWaitingListNoteType");
var PatientWaitingList_1 = require("./PatientWaitingList");
var PatientWaitingListStatusHistory_1 = require("./PatientWaitingListStatusHistory");
var PatientWatch_1 = require("./PatientWatch");
var Person_1 = require("./Person");
var PeopleAdditionalInfoKSA_1 = require("./PeopleAdditionalInfoKSA");
var PerioExam_1 = require("./PerioExam");
var PerioExamSettings_1 = require("./PerioExamSettings");
var PerioProblemThresholds_1 = require("./PerioProblemThresholds");
var PerioRecord_1 = require("./PerioRecord");
var PermissionGroup_1 = require("./PermissionGroup");
var Pharmacy_1 = require("./Pharmacy");
var PhoneNumber_1 = require("./PhoneNumber");
var PhoneNumberType_1 = require("./PhoneNumberType");
var PostOpEvaluation_1 = require("./PostOpEvaluation");
var PracticeEmailAccountType_1 = require("./PracticeEmailAccountType");
var PreferredContactMethodType_1 = require("./PreferredContactMethodType");
var PreOpEvaluation_1 = require("./PreOpEvaluation");
var PreScreenResponse_1 = require("./PreScreenResponse");
var ProcedureCategoryType_1 = require("./ProcedureCategoryType");
var ProcedureDiagnosisCode_1 = require("./ProcedureDiagnosisCode");
var ProcedureGrouping_1 = require("./ProcedureGrouping");
var ProcedureList_1 = require("./ProcedureList");
var Procedure_1 = require("./Procedure");
var ProviderFinancialActivity_1 = require("./ProviderFinancialActivity");
var ProviderTaxonomyCode_1 = require("./ProviderTaxonomyCode");
var QuickbooksDefaultMapping_1 = require("./QuickbooksDefaultMapping");
var QuickbooksLocationDefaultMapping_1 = require("./QuickbooksLocationDefaultMapping");
var QuickbooksLocationPaymentMapping_1 = require("./QuickbooksLocationPaymentMapping");
var QuickbooksPaymentMapping_1 = require("./QuickbooksPaymentMapping");
var QuickbooksSetting_1 = require("./QuickbooksSetting");
var QuickbooksTransmissionHistory_1 = require("./QuickbooksTransmissionHistory");
var QuickbooksTransmission_1 = require("./QuickbooksTransmission");
var RecallDateGroupingsType_1 = require("./RecallDateGroupingsType");
var RecipientType_1 = require("./RecipientType");
var RecordStatus_1 = require("./RecordStatus");
var ReferralFollowUp_1 = require("./ReferralFollowUp");
var Referral_1 = require("./Referral");
var ReferralSourceImplantBrand_1 = require("./ReferralSourceImplantBrand");
var ReferralSourcePracticeName_1 = require("./ReferralSourcePracticeName");
var ReferralSource_1 = require("./ReferralSource");
var ReferralSourceSpecialty_1 = require("./ReferralSourceSpecialty");
var ReferralSourceTransactions_1 = require("./ReferralSourceTransactions");
var ReferralSourceTransactionType_1 = require("./ReferralSourceTransactionType");
var ReferralType_1 = require("./ReferralType");
var RelationshipToPolicyHolderType_1 = require("./RelationshipToPolicyHolderType");
var ReminderPatientDetail_1 = require("./ReminderPatientDetail");
var Reminder_1 = require("./Reminder");
var ReminderUser_1 = require("./ReminderUser");
var ReportCategory_1 = require("./ReportCategory");
var ReportColumn_1 = require("./ReportColumn");
var ReportGeneratorReportCategory_1 = require("./ReportGeneratorReportCategory");
var ReportGeneratorReportType_1 = require("./ReportGeneratorReportType");
var ReportGeneratorColumn_1 = require("./ReportGeneratorColumn");
var ReportGeneratorFilter_1 = require("./ReportGeneratorFilter");
var ReportGroupBy_1 = require("./ReportGroupBy");
var ReportTypeCategoryColumn_1 = require("./ReportTypeCategoryColumn");
var ReportTypeFilter_1 = require("./ReportTypeFilter");
var ReportType_1 = require("./ReportType");
var RequestedReport_1 = require("./RequestedReport");
var RequestedReportStatus_1 = require("./RequestedReportStatus");
var RxDosage_1 = require("./RxDosage");
var RxDrug_1 = require("./RxDrug");
var RxFormulation_1 = require("./RxFormulation");
var RxSig_1 = require("./RxSig");
var RxTemplate_1 = require("./RxTemplate");
var SalutationType_1 = require("./SalutationType");
var SavedReport_1 = require("./SavedReport");
var ScheduledPaymentResult_1 = require("./ScheduledPaymentResult");
var ScheduledPayment_1 = require("./ScheduledPayment");
var ScheduleNote_1 = require("./ScheduleNote");
var ScheduleViewChair_1 = require("./ScheduleViewChair");
var ScheduleView_1 = require("./ScheduleView");
var School_1 = require("./School");
var ServiceLocationInsuranceClaim_1 = require("./ServiceLocationInsuranceClaim");
var ServiceLocation_1 = require("./ServiceLocation");
var ServiceLocationTransaction_1 = require("./ServiceLocationTransaction");
var ServiceScheme_1 = require("./ServiceScheme");
var ServiceSchemeType_1 = require("./ServiceSchemeType");
var SimpleStringContainer_1 = require("./SimpleStringContainer");
var SmsApptConfirmationCode_1 = require("./SmsApptConfirmationCode");
var SmsApptReminder_1 = require("./SmsApptReminder");
var SmsMessage_1 = require("./SmsMessage");
var SmsOptOut_1 = require("./SmsOptOut");
var SmsSetting_1 = require("./SmsSetting");
var SoftTissueCondition_1 = require("./SoftTissueCondition");
var SoftTissueConditionType_1 = require("./SoftTissueConditionType");
var SoftTissueExamFinding_1 = require("./SoftTissueExamFinding");
var SoftTissueExam_1 = require("./SoftTissueExam");
var SoftTissueRegion_1 = require("./SoftTissueRegion");
var SplitTransaction_1 = require("./SplitTransaction");
var StatesProvince_1 = require("./StatesProvince");
var SurgeryDrugDosageType_1 = require("./SurgeryDrugDosageType");
var SurgeryDrugRecording_1 = require("./SurgeryDrugRecording");
var SurgeryDrug_1 = require("./SurgeryDrug");
var SurgeryEventRecording_1 = require("./SurgeryEventRecording");
var SurgeryEventType_1 = require("./SurgeryEventType");
var SurgeryGas_1 = require("./SurgeryGas");
var SurgeryGasRecording_1 = require("./SurgeryGasRecording");
var SurgeryInfo_1 = require("./SurgeryInfo");
var SurgeryRecording_1 = require("./SurgeryRecording");
var SurgeryVitals_1 = require("./SurgeryVitals");
var SurgeryVitalsRecordingTime_1 = require("./SurgeryVitalsRecordingTime");
var Tag_1 = require("./Tag");
var Tooth_1 = require("./Tooth");
var TenantSetting_1 = require("./TenantSetting");
var TimeLock_1 = require("./TimeLock");
var TimeLocksChair_1 = require("./TimeLocksChair");
var TimeZone_1 = require("./TimeZone");
var ToothNumberingType_1 = require("./ToothNumberingType");
var ToothRootMaskValue_1 = require("./ToothRootMaskValue");
var ToothSurfaceMaskValue_1 = require("./ToothSurfaceMaskValue");
var ToothSurfaceNumberType_1 = require("./ToothSurfaceNumberType");
var ToothSurfaceType_1 = require("./ToothSurfaceType");
var TransactionAdjustment_1 = require("./TransactionAdjustment");
var TransactionPendingSplit_1 = require("./TransactionPendingSplit");
var Transaction_1 = require("./Transaction");
var TransactionSubType_1 = require("./TransactionSubType");
var TransactionType_1 = require("./TransactionType");
var TreatmentModalityType_1 = require("./TreatmentModalityType");
var TreatmentPlanNote_1 = require("./TreatmentPlanNote");
var TreatmentPlanNoteType_1 = require("./TreatmentPlanNoteType");
var TreatmentPlanProcedureGroup_1 = require("./TreatmentPlanProcedureGroup");
var TreatmentPlan_1 = require("./TreatmentPlan");
var TreatmentPlanStatus_1 = require("./TreatmentPlanStatus");
var TreatmentStatus_1 = require("./TreatmentStatus");
var TreatmentSubType_1 = require("./TreatmentSubType");
var UserAdditionalInfoKSA_1 = require("./UserAdditionalInfoKSA");
var UserChairMapping_1 = require("./UserChairMapping");
var UserCredentials_1 = require("./UserCredentials");
var UserCredentialType_1 = require("./UserCredentialType");
var UserDefaultWorkingDay_1 = require("./UserDefaultWorkingDay");
var UserLoginHistory_1 = require("./UserLoginHistory");
var UserNotification_1 = require("./UserNotification");
var UserPermission_1 = require("./UserPermission");
var UserRegistration_1 = require("./UserRegistration");
var UserRegistrationType_1 = require("./UserRegistrationType");
var UserReportFavourite_1 = require("./UserReportFavourite");
var UserRoleType_1 = require("./UserRoleType");
var User_1 = require("./User");
var UserSetting_1 = require("./UserSetting");
var UserTimecardOverride_1 = require("./UserTimecardOverride");
var UserTimecard_1 = require("./UserTimecard");
var UserWorkingDayChair_1 = require("./UserWorkingDayChair");
var UserWorkingDayChairTime_1 = require("./UserWorkingDayChairTime");
var UserWorkingDay_1 = require("./UserWorkingDay");
var UserWorkingDayTime_1 = require("./UserWorkingDayTime");
var VitalsRecord_1 = require("./VitalsRecord");
var VitalsRecordType_1 = require("./VitalsRecordType");
var VitalsRecordVersion_1 = require("./VitalsRecordVersion");
var WaitingList_1 = require("./WaitingList");
var Address_2 = require("./Address");
Object.defineProperty(exports, "Address_Factory", { enumerable: true, get: function () { return Address_2.Address_Factory; } });
var AddressType_2 = require("./AddressType");
Object.defineProperty(exports, "AddressType_Factory", { enumerable: true, get: function () { return AddressType_2.AddressType_Factory; } });
var AlignerBrand_2 = require("./AlignerBrand");
Object.defineProperty(exports, "AlignerBrand_Factory", { enumerable: true, get: function () { return AlignerBrand_2.AlignerBrand_Factory; } });
var AlignerTrackingPlan_2 = require("./AlignerTrackingPlan");
Object.defineProperty(exports, "AlignerTrackingPlan_Factory", { enumerable: true, get: function () { return AlignerTrackingPlan_2.AlignerTrackingPlan_Factory; } });
var AlignerTrayPlan_2 = require("./AlignerTrayPlan");
Object.defineProperty(exports, "AlignerTrayPlan_Factory", { enumerable: true, get: function () { return AlignerTrayPlan_2.AlignerTrayPlan_Factory; } });
var AlignerTrayUsageType_2 = require("./AlignerTrayUsageType");
Object.defineProperty(exports, "AlignerTrayUsageType_Factory", { enumerable: true, get: function () { return AlignerTrayUsageType_2.AlignerTrayUsageType_Factory; } });
var AllergyType_2 = require("./AllergyType");
Object.defineProperty(exports, "AllergyType_Factory", { enumerable: true, get: function () { return AllergyType_2.AllergyType_Factory; } });
var AllowanceTable_2 = require("./AllowanceTable");
Object.defineProperty(exports, "AllowanceTable_Factory", { enumerable: true, get: function () { return AllowanceTable_2.AllowanceTable_Factory; } });
var AmbulatingType_2 = require("./AmbulatingType");
Object.defineProperty(exports, "AmbulatingType_Factory", { enumerable: true, get: function () { return AmbulatingType_2.AmbulatingType_Factory; } });
var AnesthesiaRecord_2 = require("./AnesthesiaRecord");
Object.defineProperty(exports, "AnesthesiaRecord_Factory", { enumerable: true, get: function () { return AnesthesiaRecord_2.AnesthesiaRecord_Factory; } });
var Appliance_2 = require("./Appliance");
Object.defineProperty(exports, "Appliance_Factory", { enumerable: true, get: function () { return Appliance_2.Appliance_Factory; } });
var ApplianceType_2 = require("./ApplianceType");
Object.defineProperty(exports, "ApplianceType_Factory", { enumerable: true, get: function () { return ApplianceType_2.ApplianceType_Factory; } });
var AppointmentAuditLog_2 = require("./AppointmentAuditLog");
Object.defineProperty(exports, "AppointmentAuditLog_Factory", { enumerable: true, get: function () { return AppointmentAuditLog_2.AppointmentAuditLog_Factory; } });
var AppointmentNote_2 = require("./AppointmentNote");
Object.defineProperty(exports, "AppointmentNote_Factory", { enumerable: true, get: function () { return AppointmentNote_2.AppointmentNote_Factory; } });
var AppointmentNoteType_2 = require("./AppointmentNoteType");
Object.defineProperty(exports, "AppointmentNoteType_Factory", { enumerable: true, get: function () { return AppointmentNoteType_2.AppointmentNoteType_Factory; } });
var Appointment_2 = require("./Appointment");
Object.defineProperty(exports, "Appointment_Factory", { enumerable: true, get: function () { return Appointment_2.Appointment_Factory; } });
var AppointmentStatusCode_2 = require("./AppointmentStatusCode");
Object.defineProperty(exports, "AppointmentStatusCode_Factory", { enumerable: true, get: function () { return AppointmentStatusCode_2.AppointmentStatusCode_Factory; } });
var AppointmentType_2 = require("./AppointmentType");
Object.defineProperty(exports, "AppointmentType_Factory", { enumerable: true, get: function () { return AppointmentType_2.AppointmentType_Factory; } });
var ArchiveAccessRequest_2 = require("./ArchiveAccessRequest");
Object.defineProperty(exports, "ArchiveAccessRequest_Factory", { enumerable: true, get: function () { return ArchiveAccessRequest_2.ArchiveAccessRequest_Factory; } });
var ArchivedDaysheet_2 = require("./ArchivedDaysheet");
Object.defineProperty(exports, "ArchivedDaysheet_Factory", { enumerable: true, get: function () { return ArchivedDaysheet_2.ArchivedDaysheet_Factory; } });
var ArchType_2 = require("./ArchType");
Object.defineProperty(exports, "ArchType_Factory", { enumerable: true, get: function () { return ArchType_2.ArchType_Factory; } });
var Archwire_2 = require("./Archwire");
Object.defineProperty(exports, "Archwire_Factory", { enumerable: true, get: function () { return Archwire_2.Archwire_Factory; } });
var ArchwireType_2 = require("./ArchwireType");
Object.defineProperty(exports, "ArchwireType_Factory", { enumerable: true, get: function () { return ArchwireType_2.ArchwireType_Factory; } });
var BalanceAdjustment_2 = require("./BalanceAdjustment");
Object.defineProperty(exports, "BalanceAdjustment_Factory", { enumerable: true, get: function () { return BalanceAdjustment_2.BalanceAdjustment_Factory; } });
var BlockBookingTemplateDate_2 = require("./BlockBookingTemplateDate");
Object.defineProperty(exports, "BlockBookingTemplateDate_Factory", { enumerable: true, get: function () { return BlockBookingTemplateDate_2.BlockBookingTemplateDate_Factory; } });
var BlockBookingTemplate_2 = require("./BlockBookingTemplate");
Object.defineProperty(exports, "BlockBookingTemplate_Factory", { enumerable: true, get: function () { return BlockBookingTemplate_2.BlockBookingTemplate_Factory; } });
var BookingTemplateBlock_2 = require("./BookingTemplateBlock");
Object.defineProperty(exports, "BookingTemplateBlock_Factory", { enumerable: true, get: function () { return BookingTemplateBlock_2.BookingTemplateBlock_Factory; } });
var BoxFileMigrationLog_2 = require("./BoxFileMigrationLog");
Object.defineProperty(exports, "BoxFileMigrationLog_Factory", { enumerable: true, get: function () { return BoxFileMigrationLog_2.BoxFileMigrationLog_Factory; } });
var BPEExam_2 = require("./BPEExam");
Object.defineProperty(exports, "BPEExam_Factory", { enumerable: true, get: function () { return BPEExam_2.BPEExam_Factory; } });
var Bracket_2 = require("./Bracket");
Object.defineProperty(exports, "Bracket_Factory", { enumerable: true, get: function () { return Bracket_2.Bracket_Factory; } });
var BracketType_2 = require("./BracketType");
Object.defineProperty(exports, "BracketType_Factory", { enumerable: true, get: function () { return BracketType_2.BracketType_Factory; } });
var BulkCorrespondenceBatch_2 = require("./BulkCorrespondenceBatch");
Object.defineProperty(exports, "BulkCorrespondenceBatch_Factory", { enumerable: true, get: function () { return BulkCorrespondenceBatch_2.BulkCorrespondenceBatch_Factory; } });
var BulkCorrespondencePatientInstance_2 = require("./BulkCorrespondencePatientInstance");
Object.defineProperty(exports, "BulkCorrespondencePatientInstance_Factory", { enumerable: true, get: function () { return BulkCorrespondencePatientInstance_2.BulkCorrespondencePatientInstance_Factory; } });
var BulkInsurancePaymentAdjustment_2 = require("./BulkInsurancePaymentAdjustment");
Object.defineProperty(exports, "BulkInsurancePaymentAdjustment_Factory", { enumerable: true, get: function () { return BulkInsurancePaymentAdjustment_2.BulkInsurancePaymentAdjustment_Factory; } });
var BulkInsurancePaymentClaim_2 = require("./BulkInsurancePaymentClaim");
Object.defineProperty(exports, "BulkInsurancePaymentClaim_Factory", { enumerable: true, get: function () { return BulkInsurancePaymentClaim_2.BulkInsurancePaymentClaim_Factory; } });
var BulkInsurancePayment_2 = require("./BulkInsurancePayment");
Object.defineProperty(exports, "BulkInsurancePayment_Factory", { enumerable: true, get: function () { return BulkInsurancePayment_2.BulkInsurancePayment_Factory; } });
var BulkStatementBatch_2 = require("./BulkStatementBatch");
Object.defineProperty(exports, "BulkStatementBatch_Factory", { enumerable: true, get: function () { return BulkStatementBatch_2.BulkStatementBatch_Factory; } });
var BulkStatementBatchGuarantorPatient_2 = require("./BulkStatementBatchGuarantorPatient");
Object.defineProperty(exports, "BulkStatementBatchGuarantorPatient_Factory", { enumerable: true, get: function () { return BulkStatementBatchGuarantorPatient_2.BulkStatementBatchGuarantorPatient_Factory; } });
var BulkStatementBatchGuarantor_2 = require("./BulkStatementBatchGuarantor");
Object.defineProperty(exports, "BulkStatementBatchGuarantor_Factory", { enumerable: true, get: function () { return BulkStatementBatchGuarantor_2.BulkStatementBatchGuarantor_Factory; } });
var BulkStatementBatchPatient_2 = require("./BulkStatementBatchPatient");
Object.defineProperty(exports, "BulkStatementBatchPatient_Factory", { enumerable: true, get: function () { return BulkStatementBatchPatient_2.BulkStatementBatchPatient_Factory; } });
var CDTCodeCategoryType_2 = require("./CDTCodeCategoryType");
Object.defineProperty(exports, "CDTCodeCategoryType_Factory", { enumerable: true, get: function () { return CDTCodeCategoryType_2.CDTCodeCategoryType_Factory; } });
var CDTCode_2 = require("./CDTCode");
Object.defineProperty(exports, "CDTCode_Factory", { enumerable: true, get: function () { return CDTCode_2.CDTCode_Factory; } });
var Chair_2 = require("./Chair");
Object.defineProperty(exports, "Chair_Factory", { enumerable: true, get: function () { return Chair_2.Chair_Factory; } });
var ClinicalEventConditionCode_2 = require("./ClinicalEventConditionCode");
Object.defineProperty(exports, "ClinicalEventConditionCode_Factory", { enumerable: true, get: function () { return ClinicalEventConditionCode_2.ClinicalEventConditionCode_Factory; } });
var ClinicalEventGroup_2 = require("./ClinicalEventGroup");
Object.defineProperty(exports, "ClinicalEventGroup_Factory", { enumerable: true, get: function () { return ClinicalEventGroup_2.ClinicalEventGroup_Factory; } });
var ClinicalEvent_2 = require("./ClinicalEvent");
Object.defineProperty(exports, "ClinicalEvent_Factory", { enumerable: true, get: function () { return ClinicalEvent_2.ClinicalEvent_Factory; } });
var ClinicalEventWatchDetail_2 = require("./ClinicalEventWatchDetail");
Object.defineProperty(exports, "ClinicalEventWatchDetail_Factory", { enumerable: true, get: function () { return ClinicalEventWatchDetail_2.ClinicalEventWatchDetail_Factory; } });
var ClinicalNote_2 = require("./ClinicalNote");
Object.defineProperty(exports, "ClinicalNote_Factory", { enumerable: true, get: function () { return ClinicalNote_2.ClinicalNote_Factory; } });
var ClinicalTreatmentMaterial_2 = require("./ClinicalTreatmentMaterial");
Object.defineProperty(exports, "ClinicalTreatmentMaterial_Factory", { enumerable: true, get: function () { return ClinicalTreatmentMaterial_2.ClinicalTreatmentMaterial_Factory; } });
var CollectionAllocation_2 = require("./CollectionAllocation");
Object.defineProperty(exports, "CollectionAllocation_Factory", { enumerable: true, get: function () { return CollectionAllocation_2.CollectionAllocation_Factory; } });
var CompletedMedicalQuestionnaireAnswer_2 = require("./CompletedMedicalQuestionnaireAnswer");
Object.defineProperty(exports, "CompletedMedicalQuestionnaireAnswer_Factory", { enumerable: true, get: function () { return CompletedMedicalQuestionnaireAnswer_2.CompletedMedicalQuestionnaireAnswer_Factory; } });
var CompletedMedicalQuestionnaire_2 = require("./CompletedMedicalQuestionnaire");
Object.defineProperty(exports, "CompletedMedicalQuestionnaire_Factory", { enumerable: true, get: function () { return CompletedMedicalQuestionnaire_2.CompletedMedicalQuestionnaire_Factory; } });
var ConcernType_2 = require("./ConcernType");
Object.defineProperty(exports, "ConcernType_Factory", { enumerable: true, get: function () { return ConcernType_2.ConcernType_Factory; } });
var ConnectedDevice_2 = require("./ConnectedDevice");
Object.defineProperty(exports, "ConnectedDevice_Factory", { enumerable: true, get: function () { return ConnectedDevice_2.ConnectedDevice_Factory; } });
var ConnectedMonitor_2 = require("./ConnectedMonitor");
Object.defineProperty(exports, "ConnectedMonitor_Factory", { enumerable: true, get: function () { return ConnectedMonitor_2.ConnectedMonitor_Factory; } });
var ContactInfoType_2 = require("./ContactInfoType");
Object.defineProperty(exports, "ContactInfoType_Factory", { enumerable: true, get: function () { return ContactInfoType_2.ContactInfoType_Factory; } });
var ControlNumber_2 = require("./ControlNumber");
Object.defineProperty(exports, "ControlNumber_Factory", { enumerable: true, get: function () { return ControlNumber_2.ControlNumber_Factory; } });
var ConversionNote_2 = require("./ConversionNote");
Object.defineProperty(exports, "ConversionNote_Factory", { enumerable: true, get: function () { return ConversionNote_2.ConversionNote_Factory; } });
var CorrespondenceLetterPurposeType_2 = require("./CorrespondenceLetterPurposeType");
Object.defineProperty(exports, "CorrespondenceLetterPurposeType_Factory", { enumerable: true, get: function () { return CorrespondenceLetterPurposeType_2.CorrespondenceLetterPurposeType_Factory; } });
var CorrespondenceLetter_2 = require("./CorrespondenceLetter");
Object.defineProperty(exports, "CorrespondenceLetter_Factory", { enumerable: true, get: function () { return CorrespondenceLetter_2.CorrespondenceLetter_Factory; } });
var CorrespondenceMergeFieldGroup_2 = require("./CorrespondenceMergeFieldGroup");
Object.defineProperty(exports, "CorrespondenceMergeFieldGroup_Factory", { enumerable: true, get: function () { return CorrespondenceMergeFieldGroup_2.CorrespondenceMergeFieldGroup_Factory; } });
var CorrespondenceMergeField_2 = require("./CorrespondenceMergeField");
Object.defineProperty(exports, "CorrespondenceMergeField_Factory", { enumerable: true, get: function () { return CorrespondenceMergeField_2.CorrespondenceMergeField_Factory; } });
var CorrespondenceTemplateAddresseeType_2 = require("./CorrespondenceTemplateAddresseeType");
Object.defineProperty(exports, "CorrespondenceTemplateAddresseeType_Factory", { enumerable: true, get: function () { return CorrespondenceTemplateAddresseeType_2.CorrespondenceTemplateAddresseeType_Factory; } });
var CorrespondenceTemplateCategory_2 = require("./CorrespondenceTemplateCategory");
Object.defineProperty(exports, "CorrespondenceTemplateCategory_Factory", { enumerable: true, get: function () { return CorrespondenceTemplateCategory_2.CorrespondenceTemplateCategory_Factory; } });
var CorrespondenceTemplateContent_2 = require("./CorrespondenceTemplateContent");
Object.defineProperty(exports, "CorrespondenceTemplateContent_Factory", { enumerable: true, get: function () { return CorrespondenceTemplateContent_2.CorrespondenceTemplateContent_Factory; } });
var CorrespondenceTemplateContentType_2 = require("./CorrespondenceTemplateContentType");
Object.defineProperty(exports, "CorrespondenceTemplateContentType_Factory", { enumerable: true, get: function () { return CorrespondenceTemplateContentType_2.CorrespondenceTemplateContentType_Factory; } });
var CorrespondenceTemplateFormatType_2 = require("./CorrespondenceTemplateFormatType");
Object.defineProperty(exports, "CorrespondenceTemplateFormatType_Factory", { enumerable: true, get: function () { return CorrespondenceTemplateFormatType_2.CorrespondenceTemplateFormatType_Factory; } });
var CorrespondenceTemplate_2 = require("./CorrespondenceTemplate");
Object.defineProperty(exports, "CorrespondenceTemplate_Factory", { enumerable: true, get: function () { return CorrespondenceTemplate_2.CorrespondenceTemplate_Factory; } });
var Country_2 = require("./Country");
Object.defineProperty(exports, "Country_Factory", { enumerable: true, get: function () { return Country_2.Country_Factory; } });
var CPTCode_2 = require("./CPTCode");
Object.defineProperty(exports, "CPTCode_Factory", { enumerable: true, get: function () { return CPTCode_2.CPTCode_Factory; } });
var CPTModifier_2 = require("./CPTModifier");
Object.defineProperty(exports, "CPTModifier_Factory", { enumerable: true, get: function () { return CPTModifier_2.CPTModifier_Factory; } });
var DataExportTemplateMergeField_2 = require("./DataExportTemplateMergeField");
Object.defineProperty(exports, "DataExportTemplateMergeField_Factory", { enumerable: true, get: function () { return DataExportTemplateMergeField_2.DataExportTemplateMergeField_Factory; } });
var DataExportTemplate_2 = require("./DataExportTemplate");
Object.defineProperty(exports, "DataExportTemplate_Factory", { enumerable: true, get: function () { return DataExportTemplate_2.DataExportTemplate_Factory; } });
var DaysheetTransaction_2 = require("./DaysheetTransaction");
Object.defineProperty(exports, "DaysheetTransaction_Factory", { enumerable: true, get: function () { return DaysheetTransaction_2.DaysheetTransaction_Factory; } });
var DBScriptsLog_2 = require("./DBScriptsLog");
Object.defineProperty(exports, "DBScriptsLog_Factory", { enumerable: true, get: function () { return DBScriptsLog_2.DBScriptsLog_Factory; } });
var DefaultRecallSetting_2 = require("./DefaultRecallSetting");
Object.defineProperty(exports, "DefaultRecallSetting_Factory", { enumerable: true, get: function () { return DefaultRecallSetting_2.DefaultRecallSetting_Factory; } });
var ECGSnapshot_2 = require("./ECGSnapshot");
Object.defineProperty(exports, "ECGSnapshot_Factory", { enumerable: true, get: function () { return ECGSnapshot_2.ECGSnapshot_Factory; } });
var EFormsAttachment_2 = require("./EFormsAttachment");
Object.defineProperty(exports, "EFormsAttachment_Factory", { enumerable: true, get: function () { return EFormsAttachment_2.EFormsAttachment_Factory; } });
var EFormsAttachmentType_2 = require("./EFormsAttachmentType");
Object.defineProperty(exports, "EFormsAttachmentType_Factory", { enumerable: true, get: function () { return EFormsAttachmentType_2.EFormsAttachmentType_Factory; } });
var EFormsInstance_2 = require("./EFormsInstance");
Object.defineProperty(exports, "EFormsInstance_Factory", { enumerable: true, get: function () { return EFormsInstance_2.EFormsInstance_Factory; } });
var EFormsBinaryElementDetail_2 = require("./EFormsBinaryElementDetail");
Object.defineProperty(exports, "EFormsBinaryElementDetail_Factory", { enumerable: true, get: function () { return EFormsBinaryElementDetail_2.EFormsBinaryElementDetail_Factory; } });
var EFormsDataElementCategory_2 = require("./EFormsDataElementCategory");
Object.defineProperty(exports, "EFormsDataElementCategory_Factory", { enumerable: true, get: function () { return EFormsDataElementCategory_2.EFormsDataElementCategory_Factory; } });
var EFormsDataElementOptionType_2 = require("./EFormsDataElementOptionType");
Object.defineProperty(exports, "EFormsDataElementOptionType_Factory", { enumerable: true, get: function () { return EFormsDataElementOptionType_2.EFormsDataElementOptionType_Factory; } });
var EFormsDataElement_2 = require("./EFormsDataElement");
Object.defineProperty(exports, "EFormsDataElement_Factory", { enumerable: true, get: function () { return EFormsDataElement_2.EFormsDataElement_Factory; } });
var EFormsDataElementType_2 = require("./EFormsDataElementType");
Object.defineProperty(exports, "EFormsDataElementType_Factory", { enumerable: true, get: function () { return EFormsDataElementType_2.EFormsDataElementType_Factory; } });
var EFormsInstanceDetail_2 = require("./EFormsInstanceDetail");
Object.defineProperty(exports, "EFormsInstanceDetail_Factory", { enumerable: true, get: function () { return EFormsInstanceDetail_2.EFormsInstanceDetail_Factory; } });
var EFormsInstanceHistoryLog_2 = require("./EFormsInstanceHistoryLog");
Object.defineProperty(exports, "EFormsInstanceHistoryLog_Factory", { enumerable: true, get: function () { return EFormsInstanceHistoryLog_2.EFormsInstanceHistoryLog_Factory; } });
var EFormsInstanceView_2 = require("./EFormsInstanceView");
Object.defineProperty(exports, "EFormsInstanceView_Factory", { enumerable: true, get: function () { return EFormsInstanceView_2.EFormsInstanceView_Factory; } });
var EFormsLocationSettings_2 = require("./EFormsLocationSettings");
Object.defineProperty(exports, "EFormsLocationSettings_Factory", { enumerable: true, get: function () { return EFormsLocationSettings_2.EFormsLocationSettings_Factory; } });
var EFormsNumericElementDetail_2 = require("./EFormsNumericElementDetail");
Object.defineProperty(exports, "EFormsNumericElementDetail_Factory", { enumerable: true, get: function () { return EFormsNumericElementDetail_2.EFormsNumericElementDetail_Factory; } });
var EFormsSelectElementOption_2 = require("./EFormsSelectElementOption");
Object.defineProperty(exports, "EFormsSelectElementOption_Factory", { enumerable: true, get: function () { return EFormsSelectElementOption_2.EFormsSelectElementOption_Factory; } });
var EFormsSourceType_2 = require("./EFormsSourceType");
Object.defineProperty(exports, "EFormsSourceType_Factory", { enumerable: true, get: function () { return EFormsSourceType_2.EFormsSourceType_Factory; } });
var EFormsStatusTracking_2 = require("./EFormsStatusTracking");
Object.defineProperty(exports, "EFormsStatusTracking_Factory", { enumerable: true, get: function () { return EFormsStatusTracking_2.EFormsStatusTracking_Factory; } });
var EFormsStatus_2 = require("./EFormsStatus");
Object.defineProperty(exports, "EFormsStatus_Factory", { enumerable: true, get: function () { return EFormsStatus_2.EFormsStatus_Factory; } });
var EFormsTemplateCategory_2 = require("./EFormsTemplateCategory");
Object.defineProperty(exports, "EFormsTemplateCategory_Factory", { enumerable: true, get: function () { return EFormsTemplateCategory_2.EFormsTemplateCategory_Factory; } });
var EFormsTemplateDataElement_2 = require("./EFormsTemplateDataElement");
Object.defineProperty(exports, "EFormsTemplateDataElement_Factory", { enumerable: true, get: function () { return EFormsTemplateDataElement_2.EFormsTemplateDataElement_Factory; } });
var EFormsTemplateDetail_2 = require("./EFormsTemplateDetail");
Object.defineProperty(exports, "EFormsTemplateDetail_Factory", { enumerable: true, get: function () { return EFormsTemplateDetail_2.EFormsTemplateDetail_Factory; } });
var EFormsToothChartElementDentitionType_2 = require("./EFormsToothChartElementDentitionType");
Object.defineProperty(exports, "EFormsToothChartElementDentitionType_Factory", { enumerable: true, get: function () { return EFormsToothChartElementDentitionType_2.EFormsToothChartElementDentitionType_Factory; } });
var EFormsToothChartElementDetail_2 = require("./EFormsToothChartElementDetail");
Object.defineProperty(exports, "EFormsToothChartElementDetail_Factory", { enumerable: true, get: function () { return EFormsToothChartElementDetail_2.EFormsToothChartElementDetail_Factory; } });
var EFormsToothChartElementTooth_2 = require("./EFormsToothChartElementTooth");
Object.defineProperty(exports, "EFormsToothChartElementTooth_Factory", { enumerable: true, get: function () { return EFormsToothChartElementTooth_2.EFormsToothChartElementTooth_Factory; } });
var EFormsTemplate_2 = require("./EFormsTemplate");
Object.defineProperty(exports, "EFormsTemplate_Factory", { enumerable: true, get: function () { return EFormsTemplate_2.EFormsTemplate_Factory; } });
var Elastic_2 = require("./Elastic");
Object.defineProperty(exports, "Elastic_Factory", { enumerable: true, get: function () { return Elastic_2.Elastic_Factory; } });
var ElasticType_2 = require("./ElasticType");
Object.defineProperty(exports, "ElasticType_Factory", { enumerable: true, get: function () { return ElasticType_2.ElasticType_Factory; } });
var ElasticUsageType_2 = require("./ElasticUsageType");
Object.defineProperty(exports, "ElasticUsageType_Factory", { enumerable: true, get: function () { return ElasticUsageType_2.ElasticUsageType_Factory; } });
var EmploymentStatus_2 = require("./EmploymentStatus");
Object.defineProperty(exports, "EmploymentStatus_Factory", { enumerable: true, get: function () { return EmploymentStatus_2.EmploymentStatus_Factory; } });
var EnterpriseConsultation_2 = require("./EnterpriseConsultation");
Object.defineProperty(exports, "EnterpriseConsultation_Factory", { enumerable: true, get: function () { return EnterpriseConsultation_2.EnterpriseConsultation_Factory; } });
var EnterpriseConsultationsAuditLog_2 = require("./EnterpriseConsultationsAuditLog");
Object.defineProperty(exports, "EnterpriseConsultationsAuditLog_Factory", { enumerable: true, get: function () { return EnterpriseConsultationsAuditLog_2.EnterpriseConsultationsAuditLog_Factory; } });
var EnterpriseConsultationSubjectLink_2 = require("./EnterpriseConsultationSubjectLink");
Object.defineProperty(exports, "EnterpriseConsultationSubjectLink_Factory", { enumerable: true, get: function () { return EnterpriseConsultationSubjectLink_2.EnterpriseConsultationSubjectLink_Factory; } });
var EnterpriseLocationDepartment_2 = require("./EnterpriseLocationDepartment");
Object.defineProperty(exports, "EnterpriseLocationDepartment_Factory", { enumerable: true, get: function () { return EnterpriseLocationDepartment_2.EnterpriseLocationDepartment_Factory; } });
var EnterpriseLocation_2 = require("./EnterpriseLocation");
Object.defineProperty(exports, "EnterpriseLocation_Factory", { enumerable: true, get: function () { return EnterpriseLocation_2.EnterpriseLocation_Factory; } });
var EnterpriseReportingColumnDefinition_2 = require("./EnterpriseReportingColumnDefinition");
Object.defineProperty(exports, "EnterpriseReportingColumnDefinition_Factory", { enumerable: true, get: function () { return EnterpriseReportingColumnDefinition_2.EnterpriseReportingColumnDefinition_Factory; } });
var EnterpriseReportingReport_2 = require("./EnterpriseReportingReport");
Object.defineProperty(exports, "EnterpriseReportingReport_Factory", { enumerable: true, get: function () { return EnterpriseReportingReport_2.EnterpriseReportingReport_Factory; } });
var EnterpriseUserLocation_2 = require("./EnterpriseUserLocation");
Object.defineProperty(exports, "EnterpriseUserLocation_Factory", { enumerable: true, get: function () { return EnterpriseUserLocation_2.EnterpriseUserLocation_Factory; } });
var EntityType_2 = require("./EntityType");
Object.defineProperty(exports, "EntityType_Factory", { enumerable: true, get: function () { return EntityType_2.EntityType_Factory; } });
var EPaymentDevice_2 = require("./EPaymentDevice");
Object.defineProperty(exports, "EPaymentDevice_Factory", { enumerable: true, get: function () { return EPaymentDevice_2.EPaymentDevice_Factory; } });
var EPaymentMerchant_2 = require("./EPaymentMerchant");
Object.defineProperty(exports, "EPaymentMerchant_Factory", { enumerable: true, get: function () { return EPaymentMerchant_2.EPaymentMerchant_Factory; } });
var EscortType_2 = require("./EscortType");
Object.defineProperty(exports, "EscortType_Factory", { enumerable: true, get: function () { return EscortType_2.EscortType_Factory; } });
var EServicesReport_2 = require("./EServicesReport");
Object.defineProperty(exports, "EServicesReport_Factory", { enumerable: true, get: function () { return EServicesReport_2.EServicesReport_Factory; } });
var EServicesSetting_2 = require("./EServicesSetting");
Object.defineProperty(exports, "EServicesSetting_Factory", { enumerable: true, get: function () { return EServicesSetting_2.EServicesSetting_Factory; } });
var EstateReferralAdditionalProcedure_2 = require("./EstateReferralAdditionalProcedure");
Object.defineProperty(exports, "EstateReferralAdditionalProcedure_Factory", { enumerable: true, get: function () { return EstateReferralAdditionalProcedure_2.EstateReferralAdditionalProcedure_Factory; } });
var EstateReferralAuditLog_2 = require("./EstateReferralAuditLog");
Object.defineProperty(exports, "EstateReferralAuditLog_Factory", { enumerable: true, get: function () { return EstateReferralAuditLog_2.EstateReferralAuditLog_Factory; } });
var EstateReferralRejectionReason_2 = require("./EstateReferralRejectionReason");
Object.defineProperty(exports, "EstateReferralRejectionReason_Factory", { enumerable: true, get: function () { return EstateReferralRejectionReason_2.EstateReferralRejectionReason_Factory; } });
var EstateReferral_2 = require("./EstateReferral");
Object.defineProperty(exports, "EstateReferral_Factory", { enumerable: true, get: function () { return EstateReferral_2.EstateReferral_Factory; } });
var EthnicityType_2 = require("./EthnicityType");
Object.defineProperty(exports, "EthnicityType_Factory", { enumerable: true, get: function () { return EthnicityType_2.EthnicityType_Factory; } });
var GenderType_2 = require("./GenderType");
Object.defineProperty(exports, "GenderType_Factory", { enumerable: true, get: function () { return GenderType_2.GenderType_Factory; } });
var HealthMeasurement_2 = require("./HealthMeasurement");
Object.defineProperty(exports, "HealthMeasurement_Factory", { enumerable: true, get: function () { return HealthMeasurement_2.HealthMeasurement_Factory; } });
var HealthMeasurementType_2 = require("./HealthMeasurementType");
Object.defineProperty(exports, "HealthMeasurementType_Factory", { enumerable: true, get: function () { return HealthMeasurementType_2.HealthMeasurementType_Factory; } });
var HISIntegrationQueryHistory_2 = require("./HISIntegrationQueryHistory");
Object.defineProperty(exports, "HISIntegrationQueryHistory_Factory", { enumerable: true, get: function () { return HISIntegrationQueryHistory_2.HISIntegrationQueryHistory_Factory; } });
var ImagingOrder_2 = require("./ImagingOrder");
Object.defineProperty(exports, "ImagingOrder_Factory", { enumerable: true, get: function () { return ImagingOrder_2.ImagingOrder_Factory; } });
var ImagingReferenceType_2 = require("./ImagingReferenceType");
Object.defineProperty(exports, "ImagingReferenceType_Factory", { enumerable: true, get: function () { return ImagingReferenceType_2.ImagingReferenceType_Factory; } });
var CreateImagingRequest_2 = require("./CreateImagingRequest");
Object.defineProperty(exports, "CreateImagingRequest_Factory", { enumerable: true, get: function () { return CreateImagingRequest_2.CreateImagingRequest_Factory; } });
var ImplantBrand_2 = require("./ImplantBrand");
Object.defineProperty(exports, "ImplantBrand_Factory", { enumerable: true, get: function () { return ImplantBrand_2.ImplantBrand_Factory; } });
var ImplantBrandSize_2 = require("./ImplantBrandSize");
Object.defineProperty(exports, "ImplantBrandSize_Factory", { enumerable: true, get: function () { return ImplantBrandSize_2.ImplantBrandSize_Factory; } });
var ImplantDetail_2 = require("./ImplantDetail");
Object.defineProperty(exports, "ImplantDetail_Factory", { enumerable: true, get: function () { return ImplantDetail_2.ImplantDetail_Factory; } });
var ImplantDetailVersion_2 = require("./ImplantDetailVersion");
Object.defineProperty(exports, "ImplantDetailVersion_Factory", { enumerable: true, get: function () { return ImplantDetailVersion_2.ImplantDetailVersion_Factory; } });
var ImplantManufacturer_2 = require("./ImplantManufacturer");
Object.defineProperty(exports, "ImplantManufacturer_Factory", { enumerable: true, get: function () { return ImplantManufacturer_2.ImplantManufacturer_Factory; } });
var ImplantRecord_2 = require("./ImplantRecord");
Object.defineProperty(exports, "ImplantRecord_Factory", { enumerable: true, get: function () { return ImplantRecord_2.ImplantRecord_Factory; } });
var InitialBalance_2 = require("./InitialBalance");
Object.defineProperty(exports, "InitialBalance_Factory", { enumerable: true, get: function () { return InitialBalance_2.InitialBalance_Factory; } });
var InsuranceClaimAttachmentFile_2 = require("./InsuranceClaimAttachmentFile");
Object.defineProperty(exports, "InsuranceClaimAttachmentFile_Factory", { enumerable: true, get: function () { return InsuranceClaimAttachmentFile_2.InsuranceClaimAttachmentFile_Factory; } });
var InsuranceClaimAttachment_2 = require("./InsuranceClaimAttachment");
Object.defineProperty(exports, "InsuranceClaimAttachment_Factory", { enumerable: true, get: function () { return InsuranceClaimAttachment_2.InsuranceClaimAttachment_Factory; } });
var InsuranceClaimBatchSubmission_2 = require("./InsuranceClaimBatchSubmission");
Object.defineProperty(exports, "InsuranceClaimBatchSubmission_Factory", { enumerable: true, get: function () { return InsuranceClaimBatchSubmission_2.InsuranceClaimBatchSubmission_Factory; } });
var InsuranceClaimContinuationItem_2 = require("./InsuranceClaimContinuationItem");
Object.defineProperty(exports, "InsuranceClaimContinuationItem_Factory", { enumerable: true, get: function () { return InsuranceClaimContinuationItem_2.InsuranceClaimContinuationItem_Factory; } });
var InsuranceClaimItemAttachmentRequirement_2 = require("./InsuranceClaimItemAttachmentRequirement");
Object.defineProperty(exports, "InsuranceClaimItemAttachmentRequirement_Factory", { enumerable: true, get: function () { return InsuranceClaimItemAttachmentRequirement_2.InsuranceClaimItemAttachmentRequirement_Factory; } });
var InsuranceClaimItem_2 = require("./InsuranceClaimItem");
Object.defineProperty(exports, "InsuranceClaimItem_Factory", { enumerable: true, get: function () { return InsuranceClaimItem_2.InsuranceClaimItem_Factory; } });
var InsuranceClaimNote_2 = require("./InsuranceClaimNote");
Object.defineProperty(exports, "InsuranceClaimNote_Factory", { enumerable: true, get: function () { return InsuranceClaimNote_2.InsuranceClaimNote_Factory; } });
var InsuranceClaimNoteType_2 = require("./InsuranceClaimNoteType");
Object.defineProperty(exports, "InsuranceClaimNoteType_Factory", { enumerable: true, get: function () { return InsuranceClaimNoteType_2.InsuranceClaimNoteType_Factory; } });
var InsuranceClaim_2 = require("./InsuranceClaim");
Object.defineProperty(exports, "InsuranceClaim_Factory", { enumerable: true, get: function () { return InsuranceClaim_2.InsuranceClaim_Factory; } });
var InsuranceClaimStatusCode_2 = require("./InsuranceClaimStatusCode");
Object.defineProperty(exports, "InsuranceClaimStatusCode_Factory", { enumerable: true, get: function () { return InsuranceClaimStatusCode_2.InsuranceClaimStatusCode_Factory; } });
var InsuranceClaimType_2 = require("./InsuranceClaimType");
Object.defineProperty(exports, "InsuranceClaimType_Factory", { enumerable: true, get: function () { return InsuranceClaimType_2.InsuranceClaimType_Factory; } });
var InsuranceEligibilityType_2 = require("./InsuranceEligibilityType");
Object.defineProperty(exports, "InsuranceEligibilityType_Factory", { enumerable: true, get: function () { return InsuranceEligibilityType_2.InsuranceEligibilityType_Factory; } });
var InsurancePaperClaimFormat_2 = require("./InsurancePaperClaimFormat");
Object.defineProperty(exports, "InsurancePaperClaimFormat_Factory", { enumerable: true, get: function () { return InsurancePaperClaimFormat_2.InsurancePaperClaimFormat_Factory; } });
var InsurancePayerDetail_2 = require("./InsurancePayerDetail");
Object.defineProperty(exports, "InsurancePayerDetail_Factory", { enumerable: true, get: function () { return InsurancePayerDetail_2.InsurancePayerDetail_Factory; } });
var InsurancePayer_2 = require("./InsurancePayer");
Object.defineProperty(exports, "InsurancePayer_Factory", { enumerable: true, get: function () { return InsurancePayer_2.InsurancePayer_Factory; } });
var InsurancePayerType_2 = require("./InsurancePayerType");
Object.defineProperty(exports, "InsurancePayerType_Factory", { enumerable: true, get: function () { return InsurancePayerType_2.InsurancePayerType_Factory; } });
var InsurancePlanDetail_2 = require("./InsurancePlanDetail");
Object.defineProperty(exports, "InsurancePlanDetail_Factory", { enumerable: true, get: function () { return InsurancePlanDetail_2.InsurancePlanDetail_Factory; } });
var InsurancePlan_2 = require("./InsurancePlan");
Object.defineProperty(exports, "InsurancePlan_Factory", { enumerable: true, get: function () { return InsurancePlan_2.InsurancePlan_Factory; } });
var InsurancePolicy_2 = require("./InsurancePolicy");
Object.defineProperty(exports, "InsurancePolicy_Factory", { enumerable: true, get: function () { return InsurancePolicy_2.InsurancePolicy_Factory; } });
var InsurancePolicyCoverageType_2 = require("./InsurancePolicyCoverageType");
Object.defineProperty(exports, "InsurancePolicyCoverageType_Factory", { enumerable: true, get: function () { return InsurancePolicyCoverageType_2.InsurancePolicyCoverageType_Factory; } });
var ITeroScan_2 = require("./ITeroScan");
Object.defineProperty(exports, "ITeroScan_Factory", { enumerable: true, get: function () { return ITeroScan_2.ITeroScan_Factory; } });
var IVLocation_2 = require("./IVLocation");
Object.defineProperty(exports, "IVLocation_Factory", { enumerable: true, get: function () { return IVLocation_2.IVLocation_Factory; } });
var IVType_2 = require("./IVType");
Object.defineProperty(exports, "IVType_Factory", { enumerable: true, get: function () { return IVType_2.IVType_Factory; } });
var JobStatus_2 = require("./JobStatus");
Object.defineProperty(exports, "JobStatus_Factory", { enumerable: true, get: function () { return JobStatus_2.JobStatus_Factory; } });
var LabCase_2 = require("./LabCase");
Object.defineProperty(exports, "LabCase_Factory", { enumerable: true, get: function () { return LabCase_2.LabCase_Factory; } });
var Lab_2 = require("./Lab");
Object.defineProperty(exports, "Lab_Factory", { enumerable: true, get: function () { return Lab_2.Lab_Factory; } });
var LabtracDoctor_2 = require("./LabtracDoctor");
Object.defineProperty(exports, "LabtracDoctor_Factory", { enumerable: true, get: function () { return LabtracDoctor_2.LabtracDoctor_Factory; } });
var LabtracEnclosure_2 = require("./LabtracEnclosure");
Object.defineProperty(exports, "LabtracEnclosure_Factory", { enumerable: true, get: function () { return LabtracEnclosure_2.LabtracEnclosure_Factory; } });
var LabtracItemOption_2 = require("./LabtracItemOption");
Object.defineProperty(exports, "LabtracItemOption_Factory", { enumerable: true, get: function () { return LabtracItemOption_2.LabtracItemOption_Factory; } });
var LabtracLab_2 = require("./LabtracLab");
Object.defineProperty(exports, "LabtracLab_Factory", { enumerable: true, get: function () { return LabtracLab_2.LabtracLab_Factory; } });
var LabtracShippingRoute_2 = require("./LabtracShippingRoute");
Object.defineProperty(exports, "LabtracShippingRoute_Factory", { enumerable: true, get: function () { return LabtracShippingRoute_2.LabtracShippingRoute_Factory; } });
var Language_2 = require("./Language");
Object.defineProperty(exports, "Language_Factory", { enumerable: true, get: function () { return Language_2.Language_Factory; } });
var LegacyIdentifier_2 = require("./LegacyIdentifier");
Object.defineProperty(exports, "LegacyIdentifier_Factory", { enumerable: true, get: function () { return LegacyIdentifier_2.LegacyIdentifier_Factory; } });
var LocationAccountsReceivable_2 = require("./LocationAccountsReceivable");
Object.defineProperty(exports, "LocationAccountsReceivable_Factory", { enumerable: true, get: function () { return LocationAccountsReceivable_2.LocationAccountsReceivable_Factory; } });
var LocationBulkStatementSetting_2 = require("./LocationBulkStatementSetting");
Object.defineProperty(exports, "LocationBulkStatementSetting_Factory", { enumerable: true, get: function () { return LocationBulkStatementSetting_2.LocationBulkStatementSetting_Factory; } });
var LocationClaimsActivity_2 = require("./LocationClaimsActivity");
Object.defineProperty(exports, "LocationClaimsActivity_Factory", { enumerable: true, get: function () { return LocationClaimsActivity_2.LocationClaimsActivity_Factory; } });
var LocationDefaultWorkingDay_2 = require("./LocationDefaultWorkingDay");
Object.defineProperty(exports, "LocationDefaultWorkingDay_Factory", { enumerable: true, get: function () { return LocationDefaultWorkingDay_2.LocationDefaultWorkingDay_Factory; } });
var LocationFinancialActivity_2 = require("./LocationFinancialActivity");
Object.defineProperty(exports, "LocationFinancialActivity_Factory", { enumerable: true, get: function () { return LocationFinancialActivity_2.LocationFinancialActivity_Factory; } });
var Location_2 = require("./Location");
Object.defineProperty(exports, "Location_Factory", { enumerable: true, get: function () { return Location_2.Location_Factory; } });
var LocationSetting_2 = require("./LocationSetting");
Object.defineProperty(exports, "LocationSetting_Factory", { enumerable: true, get: function () { return LocationSetting_2.LocationSetting_Factory; } });
var LocationWorkingDay_2 = require("./LocationWorkingDay");
Object.defineProperty(exports, "LocationWorkingDay_Factory", { enumerable: true, get: function () { return LocationWorkingDay_2.LocationWorkingDay_Factory; } });
var LocationWorkingDayTime_2 = require("./LocationWorkingDayTime");
Object.defineProperty(exports, "LocationWorkingDayTime_Factory", { enumerable: true, get: function () { return LocationWorkingDayTime_2.LocationWorkingDayTime_Factory; } });
var MasterDataDistributionEvent_2 = require("./MasterDataDistributionEvent");
Object.defineProperty(exports, "MasterDataDistributionEvent_Factory", { enumerable: true, get: function () { return MasterDataDistributionEvent_2.MasterDataDistributionEvent_Factory; } });
var MasterDataDistributionEventStats_2 = require("./MasterDataDistributionEventStats");
Object.defineProperty(exports, "MasterDataDistributionEventStats_Factory", { enumerable: true, get: function () { return MasterDataDistributionEventStats_2.MasterDataDistributionEventStats_Factory; } });
var MawidDeltaExtractProblem_2 = require("./MawidDeltaExtractProblem");
Object.defineProperty(exports, "MawidDeltaExtractProblem_Factory", { enumerable: true, get: function () { return MawidDeltaExtractProblem_2.MawidDeltaExtractProblem_Factory; } });
var MawidDeltaExtract_2 = require("./MawidDeltaExtract");
Object.defineProperty(exports, "MawidDeltaExtract_Factory", { enumerable: true, get: function () { return MawidDeltaExtract_2.MawidDeltaExtract_Factory; } });
var MawidDeltaResponse_2 = require("./MawidDeltaResponse");
Object.defineProperty(exports, "MawidDeltaResponse_Factory", { enumerable: true, get: function () { return MawidDeltaResponse_2.MawidDeltaResponse_Factory; } });
var MawidFirstExtractProblem_2 = require("./MawidFirstExtractProblem");
Object.defineProperty(exports, "MawidFirstExtractProblem_Factory", { enumerable: true, get: function () { return MawidFirstExtractProblem_2.MawidFirstExtractProblem_Factory; } });
var MawidFirstExtract_2 = require("./MawidFirstExtract");
Object.defineProperty(exports, "MawidFirstExtract_Factory", { enumerable: true, get: function () { return MawidFirstExtract_2.MawidFirstExtract_Factory; } });
var MawidFirstResponse_2 = require("./MawidFirstResponse");
Object.defineProperty(exports, "MawidFirstResponse_Factory", { enumerable: true, get: function () { return MawidFirstResponse_2.MawidFirstResponse_Factory; } });
var MawidRequest_2 = require("./MawidRequest");
Object.defineProperty(exports, "MawidRequest_Factory", { enumerable: true, get: function () { return MawidRequest_2.MawidRequest_Factory; } });
var MedicalAlertType_2 = require("./MedicalAlertType");
Object.defineProperty(exports, "MedicalAlertType_Factory", { enumerable: true, get: function () { return MedicalAlertType_2.MedicalAlertType_Factory; } });
var MedicalClaimAdditionalInfo_2 = require("./MedicalClaimAdditionalInfo");
Object.defineProperty(exports, "MedicalClaimAdditionalInfo_Factory", { enumerable: true, get: function () { return MedicalClaimAdditionalInfo_2.MedicalClaimAdditionalInfo_Factory; } });
var MedicalClaimItemAdditionalInfo_2 = require("./MedicalClaimItemAdditionalInfo");
Object.defineProperty(exports, "MedicalClaimItemAdditionalInfo_Factory", { enumerable: true, get: function () { return MedicalClaimItemAdditionalInfo_2.MedicalClaimItemAdditionalInfo_Factory; } });
var MedicalInsuranceType_2 = require("./MedicalInsuranceType");
Object.defineProperty(exports, "MedicalInsuranceType_Factory", { enumerable: true, get: function () { return MedicalInsuranceType_2.MedicalInsuranceType_Factory; } });
var MedicalQuestionnaireQuestionGroup_2 = require("./MedicalQuestionnaireQuestionGroup");
Object.defineProperty(exports, "MedicalQuestionnaireQuestionGroup_Factory", { enumerable: true, get: function () { return MedicalQuestionnaireQuestionGroup_2.MedicalQuestionnaireQuestionGroup_Factory; } });
var MedicalQuestionnaireQuestion_2 = require("./MedicalQuestionnaireQuestion");
Object.defineProperty(exports, "MedicalQuestionnaireQuestion_Factory", { enumerable: true, get: function () { return MedicalQuestionnaireQuestion_2.MedicalQuestionnaireQuestion_Factory; } });
var MedicalQuestionnaire_2 = require("./MedicalQuestionnaire");
Object.defineProperty(exports, "MedicalQuestionnaire_Factory", { enumerable: true, get: function () { return MedicalQuestionnaire_2.MedicalQuestionnaire_Factory; } });
var MedicationType_2 = require("./MedicationType");
Object.defineProperty(exports, "MedicationType_Factory", { enumerable: true, get: function () { return MedicationType_2.MedicationType_Factory; } });
var MouthRegionGroup_2 = require("./MouthRegionGroup");
Object.defineProperty(exports, "MouthRegionGroup_Factory", { enumerable: true, get: function () { return MouthRegionGroup_2.MouthRegionGroup_Factory; } });
var NewChargeProcedure_2 = require("./NewChargeProcedure");
Object.defineProperty(exports, "NewChargeProcedure_Factory", { enumerable: true, get: function () { return NewChargeProcedure_2.NewChargeProcedure_Factory; } });
var NewCharge_2 = require("./NewCharge");
Object.defineProperty(exports, "NewCharge_Factory", { enumerable: true, get: function () { return NewCharge_2.NewCharge_Factory; } });
var NewPaymentResult_2 = require("./NewPaymentResult");
Object.defineProperty(exports, "NewPaymentResult_Factory", { enumerable: true, get: function () { return NewPaymentResult_2.NewPaymentResult_Factory; } });
var NewPayment_2 = require("./NewPayment");
Object.defineProperty(exports, "NewPayment_Factory", { enumerable: true, get: function () { return NewPayment_2.NewPayment_Factory; } });
var NewPaymentSplit_2 = require("./NewPaymentSplit");
Object.defineProperty(exports, "NewPaymentSplit_Factory", { enumerable: true, get: function () { return NewPaymentSplit_2.NewPaymentSplit_Factory; } });
var NHSAcornAssessment_2 = require("./NHSAcornAssessment");
Object.defineProperty(exports, "NHSAcornAssessment_Factory", { enumerable: true, get: function () { return NHSAcornAssessment_2.NHSAcornAssessment_Factory; } });
var NHSActivityBand_2 = require("./NHSActivityBand");
Object.defineProperty(exports, "NHSActivityBand_Factory", { enumerable: true, get: function () { return NHSActivityBand_2.NHSActivityBand_Factory; } });
var NHSActivityBandValue_2 = require("./NHSActivityBandValue");
Object.defineProperty(exports, "NHSActivityBandValue_Factory", { enumerable: true, get: function () { return NHSActivityBandValue_2.NHSActivityBandValue_Factory; } });
var NHSActivityValueSet_2 = require("./NHSActivityValueSet");
Object.defineProperty(exports, "NHSActivityValueSet_Factory", { enumerable: true, get: function () { return NHSActivityValueSet_2.NHSActivityValueSet_Factory; } });
var NHSCaseMixRecordedCriteria_2 = require("./NHSCaseMixRecordedCriteria");
Object.defineProperty(exports, "NHSCaseMixRecordedCriteria_Factory", { enumerable: true, get: function () { return NHSCaseMixRecordedCriteria_2.NHSCaseMixRecordedCriteria_Factory; } });
var NHSCaseMixRecording_2 = require("./NHSCaseMixRecording");
Object.defineProperty(exports, "NHSCaseMixRecording_Factory", { enumerable: true, get: function () { return NHSCaseMixRecording_2.NHSCaseMixRecording_Factory; } });
var NHSCaseMixScoreWeight_2 = require("./NHSCaseMixScoreWeight");
Object.defineProperty(exports, "NHSCaseMixScoreWeight_Factory", { enumerable: true, get: function () { return NHSCaseMixScoreWeight_2.NHSCaseMixScoreWeight_Factory; } });
var NHSCategory_2 = require("./NHSCategory");
Object.defineProperty(exports, "NHSCategory_Factory", { enumerable: true, get: function () { return NHSCategory_2.NHSCategory_Factory; } });
var NHSCategoryBand_2 = require("./NHSCategoryBand");
Object.defineProperty(exports, "NHSCategoryBand_Factory", { enumerable: true, get: function () { return NHSCategoryBand_2.NHSCategoryBand_Factory; } });
var NHSCategoryDate_2 = require("./NHSCategoryDate");
Object.defineProperty(exports, "NHSCategoryDate_Factory", { enumerable: true, get: function () { return NHSCategoryDate_2.NHSCategoryDate_Factory; } });
var NHSCDSBand_2 = require("./NHSCDSBand");
Object.defineProperty(exports, "NHSCDSBand_Factory", { enumerable: true, get: function () { return NHSCDSBand_2.NHSCDSBand_Factory; } });
var NHSChargeBand_2 = require("./NHSChargeBand");
Object.defineProperty(exports, "NHSChargeBand_Factory", { enumerable: true, get: function () { return NHSChargeBand_2.NHSChargeBand_Factory; } });
var NHSChargeBandValue_2 = require("./NHSChargeBandValue");
Object.defineProperty(exports, "NHSChargeBandValue_Factory", { enumerable: true, get: function () { return NHSChargeBandValue_2.NHSChargeBandValue_Factory; } });
var NHSChargeValueSet_2 = require("./NHSChargeValueSet");
Object.defineProperty(exports, "NHSChargeValueSet_Factory", { enumerable: true, get: function () { return NHSChargeValueSet_2.NHSChargeValueSet_Factory; } });
var NHSClaimComment_2 = require("./NHSClaimComment");
Object.defineProperty(exports, "NHSClaimComment_Factory", { enumerable: true, get: function () { return NHSClaimComment_2.NHSClaimComment_Factory; } });
var NHSClaimMessageType_2 = require("./NHSClaimMessageType");
Object.defineProperty(exports, "NHSClaimMessageType_Factory", { enumerable: true, get: function () { return NHSClaimMessageType_2.NHSClaimMessageType_Factory; } });
var NHSClaim_2 = require("./NHSClaim");
Object.defineProperty(exports, "NHSClaim_Factory", { enumerable: true, get: function () { return NHSClaim_2.NHSClaim_Factory; } });
var NHSClaimStatusChange_2 = require("./NHSClaimStatusChange");
Object.defineProperty(exports, "NHSClaimStatusChange_Factory", { enumerable: true, get: function () { return NHSClaimStatusChange_2.NHSClaimStatusChange_Factory; } });
var NHSClaimSubmission_2 = require("./NHSClaimSubmission");
Object.defineProperty(exports, "NHSClaimSubmission_Factory", { enumerable: true, get: function () { return NHSClaimSubmission_2.NHSClaimSubmission_Factory; } });
var NHSClinicalDataSet_2 = require("./NHSClinicalDataSet");
Object.defineProperty(exports, "NHSClinicalDataSet_Factory", { enumerable: true, get: function () { return NHSClinicalDataSet_2.NHSClinicalDataSet_Factory; } });
var NHSCLRN_2 = require("./NHSCLRN");
Object.defineProperty(exports, "NHSCLRN_Factory", { enumerable: true, get: function () { return NHSCLRN_2.NHSCLRN_Factory; } });
var NHSContractAdjustment_2 = require("./NHSContractAdjustment");
Object.defineProperty(exports, "NHSContractAdjustment_Factory", { enumerable: true, get: function () { return NHSContractAdjustment_2.NHSContractAdjustment_Factory; } });
var NHSContractMembership_2 = require("./NHSContractMembership");
Object.defineProperty(exports, "NHSContractMembership_Factory", { enumerable: true, get: function () { return NHSContractMembership_2.NHSContractMembership_Factory; } });
var NHSContractPeriodMonth_2 = require("./NHSContractPeriodMonth");
Object.defineProperty(exports, "NHSContractPeriodMonth_Factory", { enumerable: true, get: function () { return NHSContractPeriodMonth_2.NHSContractPeriodMonth_Factory; } });
var NHSContractPeriod_2 = require("./NHSContractPeriod");
Object.defineProperty(exports, "NHSContractPeriod_Factory", { enumerable: true, get: function () { return NHSContractPeriod_2.NHSContractPeriod_Factory; } });
var NHSContract_2 = require("./NHSContract");
Object.defineProperty(exports, "NHSContract_Factory", { enumerable: true, get: function () { return NHSContract_2.NHSContract_Factory; } });
var NHSContractTarget_2 = require("./NHSContractTarget");
Object.defineProperty(exports, "NHSContractTarget_Factory", { enumerable: true, get: function () { return NHSContractTarget_2.NHSContractTarget_Factory; } });
var NHSCourseClaimMessageInfo_2 = require("./NHSCourseClaimMessageInfo");
Object.defineProperty(exports, "NHSCourseClaimMessageInfo_Factory", { enumerable: true, get: function () { return NHSCourseClaimMessageInfo_2.NHSCourseClaimMessageInfo_Factory; } });
var NHSCourseDetail_2 = require("./NHSCourseDetail");
Object.defineProperty(exports, "NHSCourseDetail_Factory", { enumerable: true, get: function () { return NHSCourseDetail_2.NHSCourseDetail_Factory; } });
var NHSCourseDetailOrtho_2 = require("./NHSCourseDetailOrtho");
Object.defineProperty(exports, "NHSCourseDetailOrtho_Factory", { enumerable: true, get: function () { return NHSCourseDetailOrtho_2.NHSCourseDetailOrtho_Factory; } });
var NHSCourseSnapshot_2 = require("./NHSCourseSnapshot");
Object.defineProperty(exports, "NHSCourseSnapshot_Factory", { enumerable: true, get: function () { return NHSCourseSnapshot_2.NHSCourseSnapshot_Factory; } });
var NHSExemptCode_2 = require("./NHSExemptCode");
Object.defineProperty(exports, "NHSExemptCode_Factory", { enumerable: true, get: function () { return NHSExemptCode_2.NHSExemptCode_Factory; } });
var NHSExemptionCheck_2 = require("./NHSExemptionCheck");
Object.defineProperty(exports, "NHSExemptionCheck_Factory", { enumerable: true, get: function () { return NHSExemptionCheck_2.NHSExemptionCheck_Factory; } });
var NHSFP17TriageDetail_2 = require("./NHSFP17TriageDetail");
Object.defineProperty(exports, "NHSFP17TriageDetail_Factory", { enumerable: true, get: function () { return NHSFP17TriageDetail_2.NHSFP17TriageDetail_Factory; } });
var NHSInterchange_2 = require("./NHSInterchange");
Object.defineProperty(exports, "NHSInterchange_Factory", { enumerable: true, get: function () { return NHSInterchange_2.NHSInterchange_Factory; } });
var NHSLocation_2 = require("./NHSLocation");
Object.defineProperty(exports, "NHSLocation_Factory", { enumerable: true, get: function () { return NHSLocation_2.NHSLocation_Factory; } });
var NHSMailbox_2 = require("./NHSMailbox");
Object.defineProperty(exports, "NHSMailbox_Factory", { enumerable: true, get: function () { return NHSMailbox_2.NHSMailbox_Factory; } });
var NHSPatientDeclaration_2 = require("./NHSPatientDeclaration");
Object.defineProperty(exports, "NHSPatientDeclaration_Factory", { enumerable: true, get: function () { return NHSPatientDeclaration_2.NHSPatientDeclaration_Factory; } });
var NHSPatientDetail_2 = require("./NHSPatientDetail");
Object.defineProperty(exports, "NHSPatientDetail_Factory", { enumerable: true, get: function () { return NHSPatientDetail_2.NHSPatientDetail_Factory; } });
var NHSProcessingError_2 = require("./NHSProcessingError");
Object.defineProperty(exports, "NHSProcessingError_Factory", { enumerable: true, get: function () { return NHSProcessingError_2.NHSProcessingError_Factory; } });
var NHSRegion_2 = require("./NHSRegion");
Object.defineProperty(exports, "NHSRegion_Factory", { enumerable: true, get: function () { return NHSRegion_2.NHSRegion_Factory; } });
var NHSRegistrationType_2 = require("./NHSRegistrationType");
Object.defineProperty(exports, "NHSRegistrationType_Factory", { enumerable: true, get: function () { return NHSRegistrationType_2.NHSRegistrationType_Factory; } });
var NHSResponseHistory_2 = require("./NHSResponseHistory");
Object.defineProperty(exports, "NHSResponseHistory_Factory", { enumerable: true, get: function () { return NHSResponseHistory_2.NHSResponseHistory_Factory; } });
var NHSSchedulePayment_2 = require("./NHSSchedulePayment");
Object.defineProperty(exports, "NHSSchedulePayment_Factory", { enumerable: true, get: function () { return NHSSchedulePayment_2.NHSSchedulePayment_Factory; } });
var NHSSchedule_2 = require("./NHSSchedule");
Object.defineProperty(exports, "NHSSchedule_Factory", { enumerable: true, get: function () { return NHSSchedule_2.NHSSchedule_Factory; } });
var NHSTransmissionFile_2 = require("./NHSTransmissionFile");
Object.defineProperty(exports, "NHSTransmissionFile_Factory", { enumerable: true, get: function () { return NHSTransmissionFile_2.NHSTransmissionFile_Factory; } });
var NHSTreatmentArrangement_2 = require("./NHSTreatmentArrangement");
Object.defineProperty(exports, "NHSTreatmentArrangement_Factory", { enumerable: true, get: function () { return NHSTreatmentArrangement_2.NHSTreatmentArrangement_Factory; } });
var NHSTriageDetail_2 = require("./NHSTriageDetail");
Object.defineProperty(exports, "NHSTriageDetail_Factory", { enumerable: true, get: function () { return NHSTriageDetail_2.NHSTriageDetail_Factory; } });
var NHSUserDetail_2 = require("./NHSUserDetail");
Object.defineProperty(exports, "NHSUserDetail_Factory", { enumerable: true, get: function () { return NHSUserDetail_2.NHSUserDetail_Factory; } });
var NoteContentType_2 = require("./NoteContentType");
Object.defineProperty(exports, "NoteContentType_Factory", { enumerable: true, get: function () { return NoteContentType_2.NoteContentType_Factory; } });
var Note_2 = require("./Note");
Object.defineProperty(exports, "Note_Factory", { enumerable: true, get: function () { return Note_2.Note_Factory; } });
var NoteTemplateCategory_2 = require("./NoteTemplateCategory");
Object.defineProperty(exports, "NoteTemplateCategory_Factory", { enumerable: true, get: function () { return NoteTemplateCategory_2.NoteTemplateCategory_Factory; } });
var NoteTemplateFavourite_2 = require("./NoteTemplateFavourite");
Object.defineProperty(exports, "NoteTemplateFavourite_Factory", { enumerable: true, get: function () { return NoteTemplateFavourite_2.NoteTemplateFavourite_Factory; } });
var NoteTemplate_2 = require("./NoteTemplate");
Object.defineProperty(exports, "NoteTemplate_Factory", { enumerable: true, get: function () { return NoteTemplate_2.NoteTemplate_Factory; } });
var NoteVersion_2 = require("./NoteVersion");
Object.defineProperty(exports, "NoteVersion_Factory", { enumerable: true, get: function () { return NoteVersion_2.NoteVersion_Factory; } });
var OrthoContractChargeSchedule_2 = require("./OrthoContractChargeSchedule");
Object.defineProperty(exports, "OrthoContractChargeSchedule_Factory", { enumerable: true, get: function () { return OrthoContractChargeSchedule_2.OrthoContractChargeSchedule_Factory; } });
var OrthoContractCorrespondenceSetting_2 = require("./OrthoContractCorrespondenceSetting");
Object.defineProperty(exports, "OrthoContractCorrespondenceSetting_Factory", { enumerable: true, get: function () { return OrthoContractCorrespondenceSetting_2.OrthoContractCorrespondenceSetting_Factory; } });
var OrthoContractFrequency_2 = require("./OrthoContractFrequency");
Object.defineProperty(exports, "OrthoContractFrequency_Factory", { enumerable: true, get: function () { return OrthoContractFrequency_2.OrthoContractFrequency_Factory; } });
var OrthoContract_2 = require("./OrthoContract");
Object.defineProperty(exports, "OrthoContract_Factory", { enumerable: true, get: function () { return OrthoContract_2.OrthoContract_Factory; } });
var OrthoContractStatus_2 = require("./OrthoContractStatus");
Object.defineProperty(exports, "OrthoContractStatus_Factory", { enumerable: true, get: function () { return OrthoContractStatus_2.OrthoContractStatus_Factory; } });
var OrthoPatientTreatmentStatus_2 = require("./OrthoPatientTreatmentStatus");
Object.defineProperty(exports, "OrthoPatientTreatmentStatus_Factory", { enumerable: true, get: function () { return OrthoPatientTreatmentStatus_2.OrthoPatientTreatmentStatus_Factory; } });
var OrthoTreatmentStatus_2 = require("./OrthoTreatmentStatus");
Object.defineProperty(exports, "OrthoTreatmentStatus_Factory", { enumerable: true, get: function () { return OrthoTreatmentStatus_2.OrthoTreatmentStatus_Factory; } });
var PatientAllergy_2 = require("./PatientAllergy");
Object.defineProperty(exports, "PatientAllergy_Factory", { enumerable: true, get: function () { return PatientAllergy_2.PatientAllergy_Factory; } });
var PatientAllergyVersion_2 = require("./PatientAllergyVersion");
Object.defineProperty(exports, "PatientAllergyVersion_Factory", { enumerable: true, get: function () { return PatientAllergyVersion_2.PatientAllergyVersion_Factory; } });
var PatientArchiveRecord_2 = require("./PatientArchiveRecord");
Object.defineProperty(exports, "PatientArchiveRecord_Factory", { enumerable: true, get: function () { return PatientArchiveRecord_2.PatientArchiveRecord_Factory; } });
var PatientConcern_2 = require("./PatientConcern");
Object.defineProperty(exports, "PatientConcern_Factory", { enumerable: true, get: function () { return PatientConcern_2.PatientConcern_Factory; } });
var PatientConnection_2 = require("./PatientConnection");
Object.defineProperty(exports, "PatientConnection_Factory", { enumerable: true, get: function () { return PatientConnection_2.PatientConnection_Factory; } });
var PatientConnectionType_2 = require("./PatientConnectionType");
Object.defineProperty(exports, "PatientConnectionType_Factory", { enumerable: true, get: function () { return PatientConnectionType_2.PatientConnectionType_Factory; } });
var PatientContactNote_2 = require("./PatientContactNote");
Object.defineProperty(exports, "PatientContactNote_Factory", { enumerable: true, get: function () { return PatientContactNote_2.PatientContactNote_Factory; } });
var PatientERxDetail_2 = require("./PatientERxDetail");
Object.defineProperty(exports, "PatientERxDetail_Factory", { enumerable: true, get: function () { return PatientERxDetail_2.PatientERxDetail_Factory; } });
var PatientHistory_2 = require("./PatientHistory");
Object.defineProperty(exports, "PatientHistory_Factory", { enumerable: true, get: function () { return PatientHistory_2.PatientHistory_Factory; } });
var PatientImageFavourite_2 = require("./PatientImageFavourite");
Object.defineProperty(exports, "PatientImageFavourite_Factory", { enumerable: true, get: function () { return PatientImageFavourite_2.PatientImageFavourite_Factory; } });
var PatientImagingIdentifier_2 = require("./PatientImagingIdentifier");
Object.defineProperty(exports, "PatientImagingIdentifier_Factory", { enumerable: true, get: function () { return PatientImagingIdentifier_2.PatientImagingIdentifier_Factory; } });
var PatientLedgerBalance_2 = require("./PatientLedgerBalance");
Object.defineProperty(exports, "PatientLedgerBalance_Factory", { enumerable: true, get: function () { return PatientLedgerBalance_2.PatientLedgerBalance_Factory; } });
var PatientMedication_2 = require("./PatientMedication");
Object.defineProperty(exports, "PatientMedication_Factory", { enumerable: true, get: function () { return PatientMedication_2.PatientMedication_Factory; } });
var PatientMedicationVersion_2 = require("./PatientMedicationVersion");
Object.defineProperty(exports, "PatientMedicationVersion_Factory", { enumerable: true, get: function () { return PatientMedicationVersion_2.PatientMedicationVersion_Factory; } });
var PatientNoteType_2 = require("./PatientNoteType");
Object.defineProperty(exports, "PatientNoteType_Factory", { enumerable: true, get: function () { return PatientNoteType_2.PatientNoteType_Factory; } });
var PatientNotificationAlertDismissal_2 = require("./PatientNotificationAlertDismissal");
Object.defineProperty(exports, "PatientNotificationAlertDismissal_Factory", { enumerable: true, get: function () { return PatientNotificationAlertDismissal_2.PatientNotificationAlertDismissal_Factory; } });
var PatientNotification_2 = require("./PatientNotification");
Object.defineProperty(exports, "PatientNotification_Factory", { enumerable: true, get: function () { return PatientNotification_2.PatientNotification_Factory; } });
var PatientPaymentMethod_2 = require("./PatientPaymentMethod");
Object.defineProperty(exports, "PatientPaymentMethod_Factory", { enumerable: true, get: function () { return PatientPaymentMethod_2.PatientPaymentMethod_Factory; } });
var PatientProcedureCPTModifiers_2 = require("./PatientProcedureCPTModifiers");
Object.defineProperty(exports, "PatientProcedureCPTModifiers_Factory", { enumerable: true, get: function () { return PatientProcedureCPTModifiers_2.PatientProcedureCPTModifiers_Factory; } });
var PatientProcedureGroup_2 = require("./PatientProcedureGroup");
Object.defineProperty(exports, "PatientProcedureGroup_Factory", { enumerable: true, get: function () { return PatientProcedureGroup_2.PatientProcedureGroup_Factory; } });
var PatientProcedureNote_2 = require("./PatientProcedureNote");
Object.defineProperty(exports, "PatientProcedureNote_Factory", { enumerable: true, get: function () { return PatientProcedureNote_2.PatientProcedureNote_Factory; } });
var PatientProcedureNoteType_2 = require("./PatientProcedureNoteType");
Object.defineProperty(exports, "PatientProcedureNoteType_Factory", { enumerable: true, get: function () { return PatientProcedureNoteType_2.PatientProcedureNoteType_Factory; } });
var PatientProcedure_2 = require("./PatientProcedure");
Object.defineProperty(exports, "PatientProcedure_Factory", { enumerable: true, get: function () { return PatientProcedure_2.PatientProcedure_Factory; } });
var PatientRecallCycleUnitType_2 = require("./PatientRecallCycleUnitType");
Object.defineProperty(exports, "PatientRecallCycleUnitType_Factory", { enumerable: true, get: function () { return PatientRecallCycleUnitType_2.PatientRecallCycleUnitType_Factory; } });
var PatientRecallInfo_2 = require("./PatientRecallInfo");
Object.defineProperty(exports, "PatientRecallInfo_Factory", { enumerable: true, get: function () { return PatientRecallInfo_2.PatientRecallInfo_Factory; } });
var PatientRecallPreference_2 = require("./PatientRecallPreference");
Object.defineProperty(exports, "PatientRecallPreference_Factory", { enumerable: true, get: function () { return PatientRecallPreference_2.PatientRecallPreference_Factory; } });
var PatientRecall_2 = require("./PatientRecall");
Object.defineProperty(exports, "PatientRecall_Factory", { enumerable: true, get: function () { return PatientRecall_2.PatientRecall_Factory; } });
var PatientResponsibleParty_2 = require("./PatientResponsibleParty");
Object.defineProperty(exports, "PatientResponsibleParty_Factory", { enumerable: true, get: function () { return PatientResponsibleParty_2.PatientResponsibleParty_Factory; } });
var PatientRxAuditLog_2 = require("./PatientRxAuditLog");
Object.defineProperty(exports, "PatientRxAuditLog_Factory", { enumerable: true, get: function () { return PatientRxAuditLog_2.PatientRxAuditLog_Factory; } });
var PatientRx_2 = require("./PatientRx");
Object.defineProperty(exports, "PatientRx_Factory", { enumerable: true, get: function () { return PatientRx_2.PatientRx_Factory; } });
var Patient_2 = require("./Patient");
Object.defineProperty(exports, "Patient_Factory", { enumerable: true, get: function () { return Patient_2.Patient_Factory; } });
var PatientsMedicalAlert_2 = require("./PatientsMedicalAlert");
Object.defineProperty(exports, "PatientsMedicalAlert_Factory", { enumerable: true, get: function () { return PatientsMedicalAlert_2.PatientsMedicalAlert_Factory; } });
var PatientSnapshot_2 = require("./PatientSnapshot");
Object.defineProperty(exports, "PatientSnapshot_Factory", { enumerable: true, get: function () { return PatientSnapshot_2.PatientSnapshot_Factory; } });
var PatientStatement_2 = require("./PatientStatement");
Object.defineProperty(exports, "PatientStatement_Factory", { enumerable: true, get: function () { return PatientStatement_2.PatientStatement_Factory; } });
var PatientStatus_2 = require("./PatientStatus");
Object.defineProperty(exports, "PatientStatus_Factory", { enumerable: true, get: function () { return PatientStatus_2.PatientStatus_Factory; } });
var PatientTreatmentStatus_2 = require("./PatientTreatmentStatus");
Object.defineProperty(exports, "PatientTreatmentStatus_Factory", { enumerable: true, get: function () { return PatientTreatmentStatus_2.PatientTreatmentStatus_Factory; } });
var PatientWaitingListNote_2 = require("./PatientWaitingListNote");
Object.defineProperty(exports, "PatientWaitingListNote_Factory", { enumerable: true, get: function () { return PatientWaitingListNote_2.PatientWaitingListNote_Factory; } });
var PatientWaitingListNoteType_2 = require("./PatientWaitingListNoteType");
Object.defineProperty(exports, "PatientWaitingListNoteType_Factory", { enumerable: true, get: function () { return PatientWaitingListNoteType_2.PatientWaitingListNoteType_Factory; } });
var PatientWaitingList_2 = require("./PatientWaitingList");
Object.defineProperty(exports, "PatientWaitingList_Factory", { enumerable: true, get: function () { return PatientWaitingList_2.PatientWaitingList_Factory; } });
var PatientWaitingListStatusHistory_2 = require("./PatientWaitingListStatusHistory");
Object.defineProperty(exports, "PatientWaitingListStatusHistory_Factory", { enumerable: true, get: function () { return PatientWaitingListStatusHistory_2.PatientWaitingListStatusHistory_Factory; } });
var PatientWatch_2 = require("./PatientWatch");
Object.defineProperty(exports, "PatientWatch_Factory", { enumerable: true, get: function () { return PatientWatch_2.PatientWatch_Factory; } });
var Person_2 = require("./Person");
Object.defineProperty(exports, "Person_Factory", { enumerable: true, get: function () { return Person_2.Person_Factory; } });
var PeopleAdditionalInfoKSA_2 = require("./PeopleAdditionalInfoKSA");
Object.defineProperty(exports, "PeopleAdditionalInfoKSA_Factory", { enumerable: true, get: function () { return PeopleAdditionalInfoKSA_2.PeopleAdditionalInfoKSA_Factory; } });
var PerioExam_2 = require("./PerioExam");
Object.defineProperty(exports, "PerioExam_Factory", { enumerable: true, get: function () { return PerioExam_2.PerioExam_Factory; } });
var PerioExamSettings_2 = require("./PerioExamSettings");
Object.defineProperty(exports, "PerioExamSettings_Factory", { enumerable: true, get: function () { return PerioExamSettings_2.PerioExamSettings_Factory; } });
var PerioProblemThresholds_2 = require("./PerioProblemThresholds");
Object.defineProperty(exports, "PerioProblemThresholds_Factory", { enumerable: true, get: function () { return PerioProblemThresholds_2.PerioProblemThresholds_Factory; } });
var PerioRecord_2 = require("./PerioRecord");
Object.defineProperty(exports, "PerioRecord_Factory", { enumerable: true, get: function () { return PerioRecord_2.PerioRecord_Factory; } });
var PermissionGroup_2 = require("./PermissionGroup");
Object.defineProperty(exports, "PermissionGroup_Factory", { enumerable: true, get: function () { return PermissionGroup_2.PermissionGroup_Factory; } });
var Pharmacy_2 = require("./Pharmacy");
Object.defineProperty(exports, "Pharmacy_Factory", { enumerable: true, get: function () { return Pharmacy_2.Pharmacy_Factory; } });
var PhoneNumber_2 = require("./PhoneNumber");
Object.defineProperty(exports, "PhoneNumber_Factory", { enumerable: true, get: function () { return PhoneNumber_2.PhoneNumber_Factory; } });
var PhoneNumberType_2 = require("./PhoneNumberType");
Object.defineProperty(exports, "PhoneNumberType_Factory", { enumerable: true, get: function () { return PhoneNumberType_2.PhoneNumberType_Factory; } });
var PostOpEvaluation_2 = require("./PostOpEvaluation");
Object.defineProperty(exports, "PostOpEvaluation_Factory", { enumerable: true, get: function () { return PostOpEvaluation_2.PostOpEvaluation_Factory; } });
var PracticeEmailAccountType_2 = require("./PracticeEmailAccountType");
Object.defineProperty(exports, "PracticeEmailAccountType_Factory", { enumerable: true, get: function () { return PracticeEmailAccountType_2.PracticeEmailAccountType_Factory; } });
var PreferredContactMethodType_2 = require("./PreferredContactMethodType");
Object.defineProperty(exports, "PreferredContactMethodType_Factory", { enumerable: true, get: function () { return PreferredContactMethodType_2.PreferredContactMethodType_Factory; } });
var PreOpEvaluation_2 = require("./PreOpEvaluation");
Object.defineProperty(exports, "PreOpEvaluation_Factory", { enumerable: true, get: function () { return PreOpEvaluation_2.PreOpEvaluation_Factory; } });
var PreScreenResponse_2 = require("./PreScreenResponse");
Object.defineProperty(exports, "PreScreenResponse_Factory", { enumerable: true, get: function () { return PreScreenResponse_2.PreScreenResponse_Factory; } });
var ProcedureCategoryType_2 = require("./ProcedureCategoryType");
Object.defineProperty(exports, "ProcedureCategoryType_Factory", { enumerable: true, get: function () { return ProcedureCategoryType_2.ProcedureCategoryType_Factory; } });
var ProcedureDiagnosisCode_2 = require("./ProcedureDiagnosisCode");
Object.defineProperty(exports, "ProcedureDiagnosisCode_Factory", { enumerable: true, get: function () { return ProcedureDiagnosisCode_2.ProcedureDiagnosisCode_Factory; } });
var ProcedureGrouping_2 = require("./ProcedureGrouping");
Object.defineProperty(exports, "ProcedureGrouping_Factory", { enumerable: true, get: function () { return ProcedureGrouping_2.ProcedureGrouping_Factory; } });
var ProcedureList_2 = require("./ProcedureList");
Object.defineProperty(exports, "ProcedureList_Factory", { enumerable: true, get: function () { return ProcedureList_2.ProcedureList_Factory; } });
var Procedure_2 = require("./Procedure");
Object.defineProperty(exports, "Procedure_Factory", { enumerable: true, get: function () { return Procedure_2.Procedure_Factory; } });
var ProviderFinancialActivity_2 = require("./ProviderFinancialActivity");
Object.defineProperty(exports, "ProviderFinancialActivity_Factory", { enumerable: true, get: function () { return ProviderFinancialActivity_2.ProviderFinancialActivity_Factory; } });
var ProviderTaxonomyCode_2 = require("./ProviderTaxonomyCode");
Object.defineProperty(exports, "ProviderTaxonomyCode_Factory", { enumerable: true, get: function () { return ProviderTaxonomyCode_2.ProviderTaxonomyCode_Factory; } });
var QuickbooksDefaultMapping_2 = require("./QuickbooksDefaultMapping");
Object.defineProperty(exports, "QuickbooksDefaultMapping_Factory", { enumerable: true, get: function () { return QuickbooksDefaultMapping_2.QuickbooksDefaultMapping_Factory; } });
var QuickbooksLocationDefaultMapping_2 = require("./QuickbooksLocationDefaultMapping");
Object.defineProperty(exports, "QuickbooksLocationDefaultMapping_Factory", { enumerable: true, get: function () { return QuickbooksLocationDefaultMapping_2.QuickbooksLocationDefaultMapping_Factory; } });
var QuickbooksLocationPaymentMapping_2 = require("./QuickbooksLocationPaymentMapping");
Object.defineProperty(exports, "QuickbooksLocationPaymentMapping_Factory", { enumerable: true, get: function () { return QuickbooksLocationPaymentMapping_2.QuickbooksLocationPaymentMapping_Factory; } });
var QuickbooksPaymentMapping_2 = require("./QuickbooksPaymentMapping");
Object.defineProperty(exports, "QuickbooksPaymentMapping_Factory", { enumerable: true, get: function () { return QuickbooksPaymentMapping_2.QuickbooksPaymentMapping_Factory; } });
var QuickbooksSetting_2 = require("./QuickbooksSetting");
Object.defineProperty(exports, "QuickbooksSetting_Factory", { enumerable: true, get: function () { return QuickbooksSetting_2.QuickbooksSetting_Factory; } });
var QuickbooksTransmissionHistory_2 = require("./QuickbooksTransmissionHistory");
Object.defineProperty(exports, "QuickbooksTransmissionHistory_Factory", { enumerable: true, get: function () { return QuickbooksTransmissionHistory_2.QuickbooksTransmissionHistory_Factory; } });
var QuickbooksTransmission_2 = require("./QuickbooksTransmission");
Object.defineProperty(exports, "QuickbooksTransmission_Factory", { enumerable: true, get: function () { return QuickbooksTransmission_2.QuickbooksTransmission_Factory; } });
var RecallDateGroupingsType_2 = require("./RecallDateGroupingsType");
Object.defineProperty(exports, "RecallDateGroupingsType_Factory", { enumerable: true, get: function () { return RecallDateGroupingsType_2.RecallDateGroupingsType_Factory; } });
var RecipientType_2 = require("./RecipientType");
Object.defineProperty(exports, "RecipientType_Factory", { enumerable: true, get: function () { return RecipientType_2.RecipientType_Factory; } });
var RecordStatus_2 = require("./RecordStatus");
Object.defineProperty(exports, "RecordStatus_Factory", { enumerable: true, get: function () { return RecordStatus_2.RecordStatus_Factory; } });
var ReferralFollowUp_2 = require("./ReferralFollowUp");
Object.defineProperty(exports, "ReferralFollowUp_Factory", { enumerable: true, get: function () { return ReferralFollowUp_2.ReferralFollowUp_Factory; } });
var Referral_2 = require("./Referral");
Object.defineProperty(exports, "Referral_Factory", { enumerable: true, get: function () { return Referral_2.Referral_Factory; } });
var ReferralSourceImplantBrand_2 = require("./ReferralSourceImplantBrand");
Object.defineProperty(exports, "ReferralSourceImplantBrand_Factory", { enumerable: true, get: function () { return ReferralSourceImplantBrand_2.ReferralSourceImplantBrand_Factory; } });
var ReferralSourcePracticeName_2 = require("./ReferralSourcePracticeName");
Object.defineProperty(exports, "ReferralSourcePracticeName_Factory", { enumerable: true, get: function () { return ReferralSourcePracticeName_2.ReferralSourcePracticeName_Factory; } });
var ReferralSource_2 = require("./ReferralSource");
Object.defineProperty(exports, "ReferralSource_Factory", { enumerable: true, get: function () { return ReferralSource_2.ReferralSource_Factory; } });
var ReferralSourceSpecialty_2 = require("./ReferralSourceSpecialty");
Object.defineProperty(exports, "ReferralSourceSpecialty_Factory", { enumerable: true, get: function () { return ReferralSourceSpecialty_2.ReferralSourceSpecialty_Factory; } });
var ReferralSourceTransactions_2 = require("./ReferralSourceTransactions");
Object.defineProperty(exports, "ReferralSourceTransactions_Factory", { enumerable: true, get: function () { return ReferralSourceTransactions_2.ReferralSourceTransactions_Factory; } });
var ReferralSourceTransactionType_2 = require("./ReferralSourceTransactionType");
Object.defineProperty(exports, "ReferralSourceTransactionType_Factory", { enumerable: true, get: function () { return ReferralSourceTransactionType_2.ReferralSourceTransactionType_Factory; } });
var ReferralType_2 = require("./ReferralType");
Object.defineProperty(exports, "ReferralType_Factory", { enumerable: true, get: function () { return ReferralType_2.ReferralType_Factory; } });
var RelationshipToPolicyHolderType_2 = require("./RelationshipToPolicyHolderType");
Object.defineProperty(exports, "RelationshipToPolicyHolderType_Factory", { enumerable: true, get: function () { return RelationshipToPolicyHolderType_2.RelationshipToPolicyHolderType_Factory; } });
var ReminderPatientDetail_2 = require("./ReminderPatientDetail");
Object.defineProperty(exports, "ReminderPatientDetail_Factory", { enumerable: true, get: function () { return ReminderPatientDetail_2.ReminderPatientDetail_Factory; } });
var Reminder_2 = require("./Reminder");
Object.defineProperty(exports, "Reminder_Factory", { enumerable: true, get: function () { return Reminder_2.Reminder_Factory; } });
var ReminderUser_2 = require("./ReminderUser");
Object.defineProperty(exports, "ReminderUser_Factory", { enumerable: true, get: function () { return ReminderUser_2.ReminderUser_Factory; } });
var ReportCategory_2 = require("./ReportCategory");
Object.defineProperty(exports, "ReportCategory_Factory", { enumerable: true, get: function () { return ReportCategory_2.ReportCategory_Factory; } });
var ReportColumn_2 = require("./ReportColumn");
Object.defineProperty(exports, "ReportColumn_Factory", { enumerable: true, get: function () { return ReportColumn_2.ReportColumn_Factory; } });
var ReportGeneratorReportCategory_2 = require("./ReportGeneratorReportCategory");
Object.defineProperty(exports, "ReportGeneratorReportCategory_Factory", { enumerable: true, get: function () { return ReportGeneratorReportCategory_2.ReportGeneratorReportCategory_Factory; } });
var ReportGeneratorReportType_2 = require("./ReportGeneratorReportType");
Object.defineProperty(exports, "ReportGeneratorReportType_Factory", { enumerable: true, get: function () { return ReportGeneratorReportType_2.ReportGeneratorReportType_Factory; } });
var ReportGeneratorColumn_2 = require("./ReportGeneratorColumn");
Object.defineProperty(exports, "ReportGeneratorColumn_Factory", { enumerable: true, get: function () { return ReportGeneratorColumn_2.ReportGeneratorColumn_Factory; } });
var ReportGeneratorFilter_2 = require("./ReportGeneratorFilter");
Object.defineProperty(exports, "ReportGeneratorFilter_Factory", { enumerable: true, get: function () { return ReportGeneratorFilter_2.ReportGeneratorFilter_Factory; } });
var ReportGroupBy_2 = require("./ReportGroupBy");
Object.defineProperty(exports, "ReportGroupBy_Factory", { enumerable: true, get: function () { return ReportGroupBy_2.ReportGroupBy_Factory; } });
var ReportTypeCategoryColumn_2 = require("./ReportTypeCategoryColumn");
Object.defineProperty(exports, "ReportTypeCategoryColumn_Factory", { enumerable: true, get: function () { return ReportTypeCategoryColumn_2.ReportTypeCategoryColumn_Factory; } });
var ReportTypeFilter_2 = require("./ReportTypeFilter");
Object.defineProperty(exports, "ReportTypeFilter_Factory", { enumerable: true, get: function () { return ReportTypeFilter_2.ReportTypeFilter_Factory; } });
var ReportType_2 = require("./ReportType");
Object.defineProperty(exports, "ReportType_Factory", { enumerable: true, get: function () { return ReportType_2.ReportType_Factory; } });
var RequestedReport_2 = require("./RequestedReport");
Object.defineProperty(exports, "RequestedReport_Factory", { enumerable: true, get: function () { return RequestedReport_2.RequestedReport_Factory; } });
var RequestedReportStatus_2 = require("./RequestedReportStatus");
Object.defineProperty(exports, "RequestedReportStatus_Factory", { enumerable: true, get: function () { return RequestedReportStatus_2.RequestedReportStatus_Factory; } });
var RxDosage_2 = require("./RxDosage");
Object.defineProperty(exports, "RxDosage_Factory", { enumerable: true, get: function () { return RxDosage_2.RxDosage_Factory; } });
var RxDrug_2 = require("./RxDrug");
Object.defineProperty(exports, "RxDrug_Factory", { enumerable: true, get: function () { return RxDrug_2.RxDrug_Factory; } });
var RxFormulation_2 = require("./RxFormulation");
Object.defineProperty(exports, "RxFormulation_Factory", { enumerable: true, get: function () { return RxFormulation_2.RxFormulation_Factory; } });
var RxSig_2 = require("./RxSig");
Object.defineProperty(exports, "RxSig_Factory", { enumerable: true, get: function () { return RxSig_2.RxSig_Factory; } });
var RxTemplate_2 = require("./RxTemplate");
Object.defineProperty(exports, "RxTemplate_Factory", { enumerable: true, get: function () { return RxTemplate_2.RxTemplate_Factory; } });
var SalutationType_2 = require("./SalutationType");
Object.defineProperty(exports, "SalutationType_Factory", { enumerable: true, get: function () { return SalutationType_2.SalutationType_Factory; } });
var SavedReport_2 = require("./SavedReport");
Object.defineProperty(exports, "SavedReport_Factory", { enumerable: true, get: function () { return SavedReport_2.SavedReport_Factory; } });
var ScheduledPaymentResult_2 = require("./ScheduledPaymentResult");
Object.defineProperty(exports, "ScheduledPaymentResult_Factory", { enumerable: true, get: function () { return ScheduledPaymentResult_2.ScheduledPaymentResult_Factory; } });
var ScheduledPayment_2 = require("./ScheduledPayment");
Object.defineProperty(exports, "ScheduledPayment_Factory", { enumerable: true, get: function () { return ScheduledPayment_2.ScheduledPayment_Factory; } });
var ScheduleNote_2 = require("./ScheduleNote");
Object.defineProperty(exports, "ScheduleNote_Factory", { enumerable: true, get: function () { return ScheduleNote_2.ScheduleNote_Factory; } });
var ScheduleViewChair_2 = require("./ScheduleViewChair");
Object.defineProperty(exports, "ScheduleViewChair_Factory", { enumerable: true, get: function () { return ScheduleViewChair_2.ScheduleViewChair_Factory; } });
var ScheduleView_2 = require("./ScheduleView");
Object.defineProperty(exports, "ScheduleView_Factory", { enumerable: true, get: function () { return ScheduleView_2.ScheduleView_Factory; } });
var School_2 = require("./School");
Object.defineProperty(exports, "School_Factory", { enumerable: true, get: function () { return School_2.School_Factory; } });
var ServiceLocationInsuranceClaim_2 = require("./ServiceLocationInsuranceClaim");
Object.defineProperty(exports, "ServiceLocationInsuranceClaim_Factory", { enumerable: true, get: function () { return ServiceLocationInsuranceClaim_2.ServiceLocationInsuranceClaim_Factory; } });
var ServiceLocation_2 = require("./ServiceLocation");
Object.defineProperty(exports, "ServiceLocation_Factory", { enumerable: true, get: function () { return ServiceLocation_2.ServiceLocation_Factory; } });
var ServiceLocationTransaction_2 = require("./ServiceLocationTransaction");
Object.defineProperty(exports, "ServiceLocationTransaction_Factory", { enumerable: true, get: function () { return ServiceLocationTransaction_2.ServiceLocationTransaction_Factory; } });
var ServiceScheme_2 = require("./ServiceScheme");
Object.defineProperty(exports, "ServiceScheme_Factory", { enumerable: true, get: function () { return ServiceScheme_2.ServiceScheme_Factory; } });
var ServiceSchemeType_2 = require("./ServiceSchemeType");
Object.defineProperty(exports, "ServiceSchemeType_Factory", { enumerable: true, get: function () { return ServiceSchemeType_2.ServiceSchemeType_Factory; } });
var SimpleStringContainer_2 = require("./SimpleStringContainer");
Object.defineProperty(exports, "SimpleStringContainer_Factory", { enumerable: true, get: function () { return SimpleStringContainer_2.SimpleStringContainer_Factory; } });
var SmsApptConfirmationCode_2 = require("./SmsApptConfirmationCode");
Object.defineProperty(exports, "SmsApptConfirmationCode_Factory", { enumerable: true, get: function () { return SmsApptConfirmationCode_2.SmsApptConfirmationCode_Factory; } });
var SmsApptReminder_2 = require("./SmsApptReminder");
Object.defineProperty(exports, "SmsApptReminder_Factory", { enumerable: true, get: function () { return SmsApptReminder_2.SmsApptReminder_Factory; } });
var SmsMessage_2 = require("./SmsMessage");
Object.defineProperty(exports, "SmsMessage_Factory", { enumerable: true, get: function () { return SmsMessage_2.SmsMessage_Factory; } });
var SmsOptOut_2 = require("./SmsOptOut");
Object.defineProperty(exports, "SmsOptOut_Factory", { enumerable: true, get: function () { return SmsOptOut_2.SmsOptOut_Factory; } });
var SmsSetting_2 = require("./SmsSetting");
Object.defineProperty(exports, "SmsSetting_Factory", { enumerable: true, get: function () { return SmsSetting_2.SmsSetting_Factory; } });
var SoftTissueCondition_2 = require("./SoftTissueCondition");
Object.defineProperty(exports, "SoftTissueCondition_Factory", { enumerable: true, get: function () { return SoftTissueCondition_2.SoftTissueCondition_Factory; } });
var SoftTissueConditionType_2 = require("./SoftTissueConditionType");
Object.defineProperty(exports, "SoftTissueConditionType_Factory", { enumerable: true, get: function () { return SoftTissueConditionType_2.SoftTissueConditionType_Factory; } });
var SoftTissueExamFinding_2 = require("./SoftTissueExamFinding");
Object.defineProperty(exports, "SoftTissueExamFinding_Factory", { enumerable: true, get: function () { return SoftTissueExamFinding_2.SoftTissueExamFinding_Factory; } });
var SoftTissueExam_2 = require("./SoftTissueExam");
Object.defineProperty(exports, "SoftTissueExam_Factory", { enumerable: true, get: function () { return SoftTissueExam_2.SoftTissueExam_Factory; } });
var SoftTissueRegion_2 = require("./SoftTissueRegion");
Object.defineProperty(exports, "SoftTissueRegion_Factory", { enumerable: true, get: function () { return SoftTissueRegion_2.SoftTissueRegion_Factory; } });
var SplitTransaction_2 = require("./SplitTransaction");
Object.defineProperty(exports, "SplitTransaction_Factory", { enumerable: true, get: function () { return SplitTransaction_2.SplitTransaction_Factory; } });
var StatesProvince_2 = require("./StatesProvince");
Object.defineProperty(exports, "StatesProvince_Factory", { enumerable: true, get: function () { return StatesProvince_2.StatesProvince_Factory; } });
var SurgeryDrugDosageType_2 = require("./SurgeryDrugDosageType");
Object.defineProperty(exports, "SurgeryDrugDosageType_Factory", { enumerable: true, get: function () { return SurgeryDrugDosageType_2.SurgeryDrugDosageType_Factory; } });
var SurgeryDrugRecording_2 = require("./SurgeryDrugRecording");
Object.defineProperty(exports, "SurgeryDrugRecording_Factory", { enumerable: true, get: function () { return SurgeryDrugRecording_2.SurgeryDrugRecording_Factory; } });
var SurgeryDrug_2 = require("./SurgeryDrug");
Object.defineProperty(exports, "SurgeryDrug_Factory", { enumerable: true, get: function () { return SurgeryDrug_2.SurgeryDrug_Factory; } });
var SurgeryEventRecording_2 = require("./SurgeryEventRecording");
Object.defineProperty(exports, "SurgeryEventRecording_Factory", { enumerable: true, get: function () { return SurgeryEventRecording_2.SurgeryEventRecording_Factory; } });
var SurgeryEventType_2 = require("./SurgeryEventType");
Object.defineProperty(exports, "SurgeryEventType_Factory", { enumerable: true, get: function () { return SurgeryEventType_2.SurgeryEventType_Factory; } });
var SurgeryGas_2 = require("./SurgeryGas");
Object.defineProperty(exports, "SurgeryGas_Factory", { enumerable: true, get: function () { return SurgeryGas_2.SurgeryGas_Factory; } });
var SurgeryGasRecording_2 = require("./SurgeryGasRecording");
Object.defineProperty(exports, "SurgeryGasRecording_Factory", { enumerable: true, get: function () { return SurgeryGasRecording_2.SurgeryGasRecording_Factory; } });
var SurgeryInfo_2 = require("./SurgeryInfo");
Object.defineProperty(exports, "SurgeryInfo_Factory", { enumerable: true, get: function () { return SurgeryInfo_2.SurgeryInfo_Factory; } });
var SurgeryRecording_2 = require("./SurgeryRecording");
Object.defineProperty(exports, "SurgeryRecording_Factory", { enumerable: true, get: function () { return SurgeryRecording_2.SurgeryRecording_Factory; } });
var SurgeryVitals_2 = require("./SurgeryVitals");
Object.defineProperty(exports, "SurgeryVitals_Factory", { enumerable: true, get: function () { return SurgeryVitals_2.SurgeryVitals_Factory; } });
var SurgeryVitalsRecordingTime_2 = require("./SurgeryVitalsRecordingTime");
Object.defineProperty(exports, "SurgeryVitalsRecordingTime_Factory", { enumerable: true, get: function () { return SurgeryVitalsRecordingTime_2.SurgeryVitalsRecordingTime_Factory; } });
var Tag_2 = require("./Tag");
Object.defineProperty(exports, "Tag_Factory", { enumerable: true, get: function () { return Tag_2.Tag_Factory; } });
var Tooth_2 = require("./Tooth");
Object.defineProperty(exports, "Tooth_Factory", { enumerable: true, get: function () { return Tooth_2.Tooth_Factory; } });
var TenantSetting_2 = require("./TenantSetting");
Object.defineProperty(exports, "TenantSetting_Factory", { enumerable: true, get: function () { return TenantSetting_2.TenantSetting_Factory; } });
var TimeLock_2 = require("./TimeLock");
Object.defineProperty(exports, "TimeLock_Factory", { enumerable: true, get: function () { return TimeLock_2.TimeLock_Factory; } });
var TimeLocksChair_2 = require("./TimeLocksChair");
Object.defineProperty(exports, "TimeLocksChair_Factory", { enumerable: true, get: function () { return TimeLocksChair_2.TimeLocksChair_Factory; } });
var TimeZone_2 = require("./TimeZone");
Object.defineProperty(exports, "TimeZone_Factory", { enumerable: true, get: function () { return TimeZone_2.TimeZone_Factory; } });
var ToothNumberingType_2 = require("./ToothNumberingType");
Object.defineProperty(exports, "ToothNumberingType_Factory", { enumerable: true, get: function () { return ToothNumberingType_2.ToothNumberingType_Factory; } });
var ToothRootMaskValue_2 = require("./ToothRootMaskValue");
Object.defineProperty(exports, "ToothRootMaskValue_Factory", { enumerable: true, get: function () { return ToothRootMaskValue_2.ToothRootMaskValue_Factory; } });
var ToothSurfaceMaskValue_2 = require("./ToothSurfaceMaskValue");
Object.defineProperty(exports, "ToothSurfaceMaskValue_Factory", { enumerable: true, get: function () { return ToothSurfaceMaskValue_2.ToothSurfaceMaskValue_Factory; } });
var ToothSurfaceNumberType_2 = require("./ToothSurfaceNumberType");
Object.defineProperty(exports, "ToothSurfaceNumberType_Factory", { enumerable: true, get: function () { return ToothSurfaceNumberType_2.ToothSurfaceNumberType_Factory; } });
var ToothSurfaceType_2 = require("./ToothSurfaceType");
Object.defineProperty(exports, "ToothSurfaceType_Factory", { enumerable: true, get: function () { return ToothSurfaceType_2.ToothSurfaceType_Factory; } });
var TransactionAdjustment_2 = require("./TransactionAdjustment");
Object.defineProperty(exports, "TransactionAdjustment_Factory", { enumerable: true, get: function () { return TransactionAdjustment_2.TransactionAdjustment_Factory; } });
var TransactionPendingSplit_2 = require("./TransactionPendingSplit");
Object.defineProperty(exports, "TransactionPendingSplit_Factory", { enumerable: true, get: function () { return TransactionPendingSplit_2.TransactionPendingSplit_Factory; } });
var Transaction_2 = require("./Transaction");
Object.defineProperty(exports, "Transaction_Factory", { enumerable: true, get: function () { return Transaction_2.Transaction_Factory; } });
var TransactionSubType_2 = require("./TransactionSubType");
Object.defineProperty(exports, "TransactionSubType_Factory", { enumerable: true, get: function () { return TransactionSubType_2.TransactionSubType_Factory; } });
var TransactionType_2 = require("./TransactionType");
Object.defineProperty(exports, "TransactionType_Factory", { enumerable: true, get: function () { return TransactionType_2.TransactionType_Factory; } });
var TreatmentModalityType_2 = require("./TreatmentModalityType");
Object.defineProperty(exports, "TreatmentModalityType_Factory", { enumerable: true, get: function () { return TreatmentModalityType_2.TreatmentModalityType_Factory; } });
var TreatmentPlanNote_2 = require("./TreatmentPlanNote");
Object.defineProperty(exports, "TreatmentPlanNote_Factory", { enumerable: true, get: function () { return TreatmentPlanNote_2.TreatmentPlanNote_Factory; } });
var TreatmentPlanNoteType_2 = require("./TreatmentPlanNoteType");
Object.defineProperty(exports, "TreatmentPlanNoteType_Factory", { enumerable: true, get: function () { return TreatmentPlanNoteType_2.TreatmentPlanNoteType_Factory; } });
var TreatmentPlanProcedureGroup_2 = require("./TreatmentPlanProcedureGroup");
Object.defineProperty(exports, "TreatmentPlanProcedureGroup_Factory", { enumerable: true, get: function () { return TreatmentPlanProcedureGroup_2.TreatmentPlanProcedureGroup_Factory; } });
var TreatmentPlan_2 = require("./TreatmentPlan");
Object.defineProperty(exports, "TreatmentPlan_Factory", { enumerable: true, get: function () { return TreatmentPlan_2.TreatmentPlan_Factory; } });
var TreatmentPlanStatus_2 = require("./TreatmentPlanStatus");
Object.defineProperty(exports, "TreatmentPlanStatus_Factory", { enumerable: true, get: function () { return TreatmentPlanStatus_2.TreatmentPlanStatus_Factory; } });
var TreatmentStatus_2 = require("./TreatmentStatus");
Object.defineProperty(exports, "TreatmentStatus_Factory", { enumerable: true, get: function () { return TreatmentStatus_2.TreatmentStatus_Factory; } });
var TreatmentSubType_2 = require("./TreatmentSubType");
Object.defineProperty(exports, "TreatmentSubType_Factory", { enumerable: true, get: function () { return TreatmentSubType_2.TreatmentSubType_Factory; } });
var UserAdditionalInfoKSA_2 = require("./UserAdditionalInfoKSA");
Object.defineProperty(exports, "UserAdditionalInfoKSA_Factory", { enumerable: true, get: function () { return UserAdditionalInfoKSA_2.UserAdditionalInfoKSA_Factory; } });
var UserChairMapping_2 = require("./UserChairMapping");
Object.defineProperty(exports, "UserChairMapping_Factory", { enumerable: true, get: function () { return UserChairMapping_2.UserChairMapping_Factory; } });
var UserCredentials_2 = require("./UserCredentials");
Object.defineProperty(exports, "UserCredentials_Factory", { enumerable: true, get: function () { return UserCredentials_2.UserCredentials_Factory; } });
var UserCredentialType_2 = require("./UserCredentialType");
Object.defineProperty(exports, "UserCredentialType_Factory", { enumerable: true, get: function () { return UserCredentialType_2.UserCredentialType_Factory; } });
var UserDefaultWorkingDay_2 = require("./UserDefaultWorkingDay");
Object.defineProperty(exports, "UserDefaultWorkingDay_Factory", { enumerable: true, get: function () { return UserDefaultWorkingDay_2.UserDefaultWorkingDay_Factory; } });
var UserLoginHistory_2 = require("./UserLoginHistory");
Object.defineProperty(exports, "UserLoginHistory_Factory", { enumerable: true, get: function () { return UserLoginHistory_2.UserLoginHistory_Factory; } });
var UserNotification_2 = require("./UserNotification");
Object.defineProperty(exports, "UserNotification_Factory", { enumerable: true, get: function () { return UserNotification_2.UserNotification_Factory; } });
var UserPermission_2 = require("./UserPermission");
Object.defineProperty(exports, "UserPermission_Factory", { enumerable: true, get: function () { return UserPermission_2.UserPermission_Factory; } });
var UserRegistration_2 = require("./UserRegistration");
Object.defineProperty(exports, "UserRegistration_Factory", { enumerable: true, get: function () { return UserRegistration_2.UserRegistration_Factory; } });
var UserRegistrationType_2 = require("./UserRegistrationType");
Object.defineProperty(exports, "UserRegistrationType_Factory", { enumerable: true, get: function () { return UserRegistrationType_2.UserRegistrationType_Factory; } });
var UserReportFavourite_2 = require("./UserReportFavourite");
Object.defineProperty(exports, "UserReportFavourite_Factory", { enumerable: true, get: function () { return UserReportFavourite_2.UserReportFavourite_Factory; } });
var UserRoleType_2 = require("./UserRoleType");
Object.defineProperty(exports, "UserRoleType_Factory", { enumerable: true, get: function () { return UserRoleType_2.UserRoleType_Factory; } });
var User_2 = require("./User");
Object.defineProperty(exports, "User_Factory", { enumerable: true, get: function () { return User_2.User_Factory; } });
var UserSetting_2 = require("./UserSetting");
Object.defineProperty(exports, "UserSetting_Factory", { enumerable: true, get: function () { return UserSetting_2.UserSetting_Factory; } });
var UserTimecardOverride_2 = require("./UserTimecardOverride");
Object.defineProperty(exports, "UserTimecardOverride_Factory", { enumerable: true, get: function () { return UserTimecardOverride_2.UserTimecardOverride_Factory; } });
var UserTimecard_2 = require("./UserTimecard");
Object.defineProperty(exports, "UserTimecard_Factory", { enumerable: true, get: function () { return UserTimecard_2.UserTimecard_Factory; } });
var UserWorkingDayChair_2 = require("./UserWorkingDayChair");
Object.defineProperty(exports, "UserWorkingDayChair_Factory", { enumerable: true, get: function () { return UserWorkingDayChair_2.UserWorkingDayChair_Factory; } });
var UserWorkingDayChairTime_2 = require("./UserWorkingDayChairTime");
Object.defineProperty(exports, "UserWorkingDayChairTime_Factory", { enumerable: true, get: function () { return UserWorkingDayChairTime_2.UserWorkingDayChairTime_Factory; } });
var UserWorkingDay_2 = require("./UserWorkingDay");
Object.defineProperty(exports, "UserWorkingDay_Factory", { enumerable: true, get: function () { return UserWorkingDay_2.UserWorkingDay_Factory; } });
var UserWorkingDayTime_2 = require("./UserWorkingDayTime");
Object.defineProperty(exports, "UserWorkingDayTime_Factory", { enumerable: true, get: function () { return UserWorkingDayTime_2.UserWorkingDayTime_Factory; } });
var VitalsRecord_2 = require("./VitalsRecord");
Object.defineProperty(exports, "VitalsRecord_Factory", { enumerable: true, get: function () { return VitalsRecord_2.VitalsRecord_Factory; } });
var VitalsRecordType_2 = require("./VitalsRecordType");
Object.defineProperty(exports, "VitalsRecordType_Factory", { enumerable: true, get: function () { return VitalsRecordType_2.VitalsRecordType_Factory; } });
var VitalsRecordVersion_2 = require("./VitalsRecordVersion");
Object.defineProperty(exports, "VitalsRecordVersion_Factory", { enumerable: true, get: function () { return VitalsRecordVersion_2.VitalsRecordVersion_Factory; } });
var WaitingList_2 = require("./WaitingList");
Object.defineProperty(exports, "WaitingList_Factory", { enumerable: true, get: function () { return WaitingList_2.WaitingList_Factory; } });
var EFormsStatusCodes_1 = require("./EFormsStatusCodes");
Object.defineProperty(exports, "EFormsStatusCodes", { enumerable: true, get: function () { return EFormsStatusCodes_1.EFormsStatusCodes; } });
var RecordStatusCodes_1 = require("./RecordStatusCodes");
Object.defineProperty(exports, "RecordStatusCodes", { enumerable: true, get: function () { return RecordStatusCodes_1.RecordStatusCodes; } });
var EServicesReportStatuses_1 = require("./EServicesReportStatuses");
Object.defineProperty(exports, "EServicesReportStatuses", { enumerable: true, get: function () { return EServicesReportStatuses_1.EServicesReportStatuses; } });
var EstateReferralStatus_1 = require("./EstateReferralStatus");
Object.defineProperty(exports, "EstateReferralStatus", { enumerable: true, get: function () { return EstateReferralStatus_1.EstateReferralStatus; } });
var EstateReferralDateRange_1 = require("./EstateReferralDateRange");
Object.defineProperty(exports, "EstateReferralDateRange", { enumerable: true, get: function () { return EstateReferralDateRange_1.EstateReferralDateRange; } });
var EstateReferralCustomDateUnit_1 = require("./EstateReferralCustomDateUnit");
Object.defineProperty(exports, "EstateReferralCustomDateUnit", { enumerable: true, get: function () { return EstateReferralCustomDateUnit_1.EstateReferralCustomDateUnit; } });
var PatientContactTypeCodes_1 = require("./PatientContactTypeCodes");
Object.defineProperty(exports, "PatientContactTypeCodes", { enumerable: true, get: function () { return PatientContactTypeCodes_1.PatientContactTypeCodes; } });
var SmsApptReminderCreationReason_1 = require("./SmsApptReminderCreationReason");
Object.defineProperty(exports, "SmsApptReminderCreationReason", { enumerable: true, get: function () { return SmsApptReminderCreationReason_1.SmsApptReminderCreationReason; } });
var PhoenixModel_Factory = /** @class */ (function () {
    function PhoenixModel_Factory() {
    }
    PhoenixModel_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Addresses: model.Addresses && model.Addresses.map(function (i) { return Address_1.Address_Factory.CreateIncoming(i); }),
            AddressTypes: model.AddressTypes && model.AddressTypes.map(function (i) { return AddressType_1.AddressType_Factory.CreateIncoming(i); }),
            AlignerBrands: model.AlignerBrands && model.AlignerBrands.map(function (i) { return AlignerBrand_1.AlignerBrand_Factory.CreateIncoming(i); }),
            AlignerTrackingPlans: model.AlignerTrackingPlans && model.AlignerTrackingPlans.map(function (i) { return AlignerTrackingPlan_1.AlignerTrackingPlan_Factory.CreateIncoming(i); }),
            AlignerTrayPlans: model.AlignerTrayPlans && model.AlignerTrayPlans.map(function (i) { return AlignerTrayPlan_1.AlignerTrayPlan_Factory.CreateIncoming(i); }),
            AlignerTrayUsageTypes: model.AlignerTrayUsageTypes && model.AlignerTrayUsageTypes.map(function (i) { return AlignerTrayUsageType_1.AlignerTrayUsageType_Factory.CreateIncoming(i); }),
            AllergyTypes: model.AllergyTypes && model.AllergyTypes.map(function (i) { return AllergyType_1.AllergyType_Factory.CreateIncoming(i); }),
            AllowanceTables: model.AllowanceTables && model.AllowanceTables.map(function (i) { return AllowanceTable_1.AllowanceTable_Factory.CreateIncoming(i); }),
            AmbulatingTypes: model.AmbulatingTypes && model.AmbulatingTypes.map(function (i) { return AmbulatingType_1.AmbulatingType_Factory.CreateIncoming(i); }),
            AnesthesiaRecords: model.AnesthesiaRecords && model.AnesthesiaRecords.map(function (i) { return AnesthesiaRecord_1.AnesthesiaRecord_Factory.CreateIncoming(i); }),
            Appliances: model.Appliances && model.Appliances.map(function (i) { return Appliance_1.Appliance_Factory.CreateIncoming(i); }),
            ApplianceTypes: model.ApplianceTypes && model.ApplianceTypes.map(function (i) { return ApplianceType_1.ApplianceType_Factory.CreateIncoming(i); }),
            AppointmentAuditLogs: model.AppointmentAuditLogs && model.AppointmentAuditLogs.map(function (i) { return AppointmentAuditLog_1.AppointmentAuditLog_Factory.CreateIncoming(i); }),
            AppointmentNotes: model.AppointmentNotes && model.AppointmentNotes.map(function (i) { return AppointmentNote_1.AppointmentNote_Factory.CreateIncoming(i); }),
            AppointmentNoteTypes: model.AppointmentNoteTypes && model.AppointmentNoteTypes.map(function (i) { return AppointmentNoteType_1.AppointmentNoteType_Factory.CreateIncoming(i); }),
            Appointments: model.Appointments && model.Appointments.map(function (i) { return Appointment_1.Appointment_Factory.CreateIncoming(i); }),
            AppointmentStatusCodes: model.AppointmentStatusCodes && model.AppointmentStatusCodes.map(function (i) { return AppointmentStatusCode_1.AppointmentStatusCode_Factory.CreateIncoming(i); }),
            AppointmentTypes: model.AppointmentTypes && model.AppointmentTypes.map(function (i) { return AppointmentType_1.AppointmentType_Factory.CreateIncoming(i); }),
            ArchiveAccessRequests: model.ArchiveAccessRequests && model.ArchiveAccessRequests.map(function (i) { return ArchiveAccessRequest_1.ArchiveAccessRequest_Factory.CreateIncoming(i); }),
            ArchivedDaysheets: model.ArchivedDaysheets && model.ArchivedDaysheets.map(function (i) { return ArchivedDaysheet_1.ArchivedDaysheet_Factory.CreateIncoming(i); }),
            ArchTypes: model.ArchTypes && model.ArchTypes.map(function (i) { return ArchType_1.ArchType_Factory.CreateIncoming(i); }),
            Archwires: model.Archwires && model.Archwires.map(function (i) { return Archwire_1.Archwire_Factory.CreateIncoming(i); }),
            ArchwireTypes: model.ArchwireTypes && model.ArchwireTypes.map(function (i) { return ArchwireType_1.ArchwireType_Factory.CreateIncoming(i); }),
            BalanceAdjustments: model.BalanceAdjustments && model.BalanceAdjustments.map(function (i) { return BalanceAdjustment_1.BalanceAdjustment_Factory.CreateIncoming(i); }),
            BlockBookingTemplateDates: model.BlockBookingTemplateDates && model.BlockBookingTemplateDates.map(function (i) { return BlockBookingTemplateDate_1.BlockBookingTemplateDate_Factory.CreateIncoming(i); }),
            BlockBookingTemplates: model.BlockBookingTemplates && model.BlockBookingTemplates.map(function (i) { return BlockBookingTemplate_1.BlockBookingTemplate_Factory.CreateIncoming(i); }),
            BookingTemplateBlocks: model.BookingTemplateBlocks && model.BookingTemplateBlocks.map(function (i) { return BookingTemplateBlock_1.BookingTemplateBlock_Factory.CreateIncoming(i); }),
            BoxFileMigrationLogs: model.BoxFileMigrationLogs && model.BoxFileMigrationLogs.map(function (i) { return BoxFileMigrationLog_1.BoxFileMigrationLog_Factory.CreateIncoming(i); }),
            BPEExams: model.BPEExams && model.BPEExams.map(function (i) { return BPEExam_1.BPEExam_Factory.CreateIncoming(i); }),
            Brackets: model.Brackets && model.Brackets.map(function (i) { return Bracket_1.Bracket_Factory.CreateIncoming(i); }),
            BracketTypes: model.BracketTypes && model.BracketTypes.map(function (i) { return BracketType_1.BracketType_Factory.CreateIncoming(i); }),
            BulkCorrespondenceBatches: model.BulkCorrespondenceBatches && model.BulkCorrespondenceBatches.map(function (i) { return BulkCorrespondenceBatch_1.BulkCorrespondenceBatch_Factory.CreateIncoming(i); }),
            BulkCorrespondencePatientInstances: model.BulkCorrespondencePatientInstances && model.BulkCorrespondencePatientInstances.map(function (i) { return BulkCorrespondencePatientInstance_1.BulkCorrespondencePatientInstance_Factory.CreateIncoming(i); }),
            BulkInsurancePaymentAdjustments: model.BulkInsurancePaymentAdjustments && model.BulkInsurancePaymentAdjustments.map(function (i) { return BulkInsurancePaymentAdjustment_1.BulkInsurancePaymentAdjustment_Factory.CreateIncoming(i); }),
            BulkInsurancePaymentClaims: model.BulkInsurancePaymentClaims && model.BulkInsurancePaymentClaims.map(function (i) { return BulkInsurancePaymentClaim_1.BulkInsurancePaymentClaim_Factory.CreateIncoming(i); }),
            BulkInsurancePayments: model.BulkInsurancePayments && model.BulkInsurancePayments.map(function (i) { return BulkInsurancePayment_1.BulkInsurancePayment_Factory.CreateIncoming(i); }),
            BulkStatementBatches: model.BulkStatementBatches && model.BulkStatementBatches.map(function (i) { return BulkStatementBatch_1.BulkStatementBatch_Factory.CreateIncoming(i); }),
            BulkStatementBatchGuarantorPatients: model.BulkStatementBatchGuarantorPatients && model.BulkStatementBatchGuarantorPatients.map(function (i) { return BulkStatementBatchGuarantorPatient_1.BulkStatementBatchGuarantorPatient_Factory.CreateIncoming(i); }),
            BulkStatementBatchGuarantors: model.BulkStatementBatchGuarantors && model.BulkStatementBatchGuarantors.map(function (i) { return BulkStatementBatchGuarantor_1.BulkStatementBatchGuarantor_Factory.CreateIncoming(i); }),
            BulkStatementBatchPatients: model.BulkStatementBatchPatients && model.BulkStatementBatchPatients.map(function (i) { return BulkStatementBatchPatient_1.BulkStatementBatchPatient_Factory.CreateIncoming(i); }),
            CDTCodeCategoryTypes: model.CDTCodeCategoryTypes && model.CDTCodeCategoryTypes.map(function (i) { return CDTCodeCategoryType_1.CDTCodeCategoryType_Factory.CreateIncoming(i); }),
            CDTCodes: model.CDTCodes && model.CDTCodes.map(function (i) { return CDTCode_1.CDTCode_Factory.CreateIncoming(i); }),
            Chairs: model.Chairs && model.Chairs.map(function (i) { return Chair_1.Chair_Factory.CreateIncoming(i); }),
            ClinicalEventConditionCodes: model.ClinicalEventConditionCodes && model.ClinicalEventConditionCodes.map(function (i) { return ClinicalEventConditionCode_1.ClinicalEventConditionCode_Factory.CreateIncoming(i); }),
            ClinicalEventGroups: model.ClinicalEventGroups && model.ClinicalEventGroups.map(function (i) { return ClinicalEventGroup_1.ClinicalEventGroup_Factory.CreateIncoming(i); }),
            ClinicalEvents: model.ClinicalEvents && model.ClinicalEvents.map(function (i) { return ClinicalEvent_1.ClinicalEvent_Factory.CreateIncoming(i); }),
            ClinicalEventWatchDetails: model.ClinicalEventWatchDetails && model.ClinicalEventWatchDetails.map(function (i) { return ClinicalEventWatchDetail_1.ClinicalEventWatchDetail_Factory.CreateIncoming(i); }),
            ClinicalNotes: model.ClinicalNotes && model.ClinicalNotes.map(function (i) { return ClinicalNote_1.ClinicalNote_Factory.CreateIncoming(i); }),
            ClinicalTreatmentMaterials: model.ClinicalTreatmentMaterials && model.ClinicalTreatmentMaterials.map(function (i) { return ClinicalTreatmentMaterial_1.ClinicalTreatmentMaterial_Factory.CreateIncoming(i); }),
            CollectionAllocations: model.CollectionAllocations && model.CollectionAllocations.map(function (i) { return CollectionAllocation_1.CollectionAllocation_Factory.CreateIncoming(i); }),
            CompletedMedicalQuestionnaireAnswers: model.CompletedMedicalQuestionnaireAnswers && model.CompletedMedicalQuestionnaireAnswers.map(function (i) { return CompletedMedicalQuestionnaireAnswer_1.CompletedMedicalQuestionnaireAnswer_Factory.CreateIncoming(i); }),
            CompletedMedicalQuestionnaires: model.CompletedMedicalQuestionnaires && model.CompletedMedicalQuestionnaires.map(function (i) { return CompletedMedicalQuestionnaire_1.CompletedMedicalQuestionnaire_Factory.CreateIncoming(i); }),
            ConcernTypes: model.ConcernTypes && model.ConcernTypes.map(function (i) { return ConcernType_1.ConcernType_Factory.CreateIncoming(i); }),
            ConnectedDevices: model.ConnectedDevices && model.ConnectedDevices.map(function (i) { return ConnectedDevice_1.ConnectedDevice_Factory.CreateIncoming(i); }),
            ConnectedMonitors: model.ConnectedMonitors && model.ConnectedMonitors.map(function (i) { return ConnectedMonitor_1.ConnectedMonitor_Factory.CreateIncoming(i); }),
            ContactInfoTypes: model.ContactInfoTypes && model.ContactInfoTypes.map(function (i) { return ContactInfoType_1.ContactInfoType_Factory.CreateIncoming(i); }),
            ControlNumbers: model.ControlNumbers && model.ControlNumbers.map(function (i) { return ControlNumber_1.ControlNumber_Factory.CreateIncoming(i); }),
            ConversionNotes: model.ConversionNotes && model.ConversionNotes.map(function (i) { return ConversionNote_1.ConversionNote_Factory.CreateIncoming(i); }),
            CorrespondenceLetterPurposeTypes: model.CorrespondenceLetterPurposeTypes && model.CorrespondenceLetterPurposeTypes.map(function (i) { return CorrespondenceLetterPurposeType_1.CorrespondenceLetterPurposeType_Factory.CreateIncoming(i); }),
            CorrespondenceLetters: model.CorrespondenceLetters && model.CorrespondenceLetters.map(function (i) { return CorrespondenceLetter_1.CorrespondenceLetter_Factory.CreateIncoming(i); }),
            CorrespondenceMergeFieldGroups: model.CorrespondenceMergeFieldGroups && model.CorrespondenceMergeFieldGroups.map(function (i) { return CorrespondenceMergeFieldGroup_1.CorrespondenceMergeFieldGroup_Factory.CreateIncoming(i); }),
            CorrespondenceMergeFields: model.CorrespondenceMergeFields && model.CorrespondenceMergeFields.map(function (i) { return CorrespondenceMergeField_1.CorrespondenceMergeField_Factory.CreateIncoming(i); }),
            CorrespondenceTemplateAddresseeTypes: model.CorrespondenceTemplateAddresseeTypes && model.CorrespondenceTemplateAddresseeTypes.map(function (i) { return CorrespondenceTemplateAddresseeType_1.CorrespondenceTemplateAddresseeType_Factory.CreateIncoming(i); }),
            CorrespondenceTemplateCategories: model.CorrespondenceTemplateCategories && model.CorrespondenceTemplateCategories.map(function (i) { return CorrespondenceTemplateCategory_1.CorrespondenceTemplateCategory_Factory.CreateIncoming(i); }),
            CorrespondenceTemplateContents: model.CorrespondenceTemplateContents && model.CorrespondenceTemplateContents.map(function (i) { return CorrespondenceTemplateContent_1.CorrespondenceTemplateContent_Factory.CreateIncoming(i); }),
            CorrespondenceTemplateContentTypes: model.CorrespondenceTemplateContentTypes && model.CorrespondenceTemplateContentTypes.map(function (i) { return CorrespondenceTemplateContentType_1.CorrespondenceTemplateContentType_Factory.CreateIncoming(i); }),
            CorrespondenceTemplateFormatTypes: model.CorrespondenceTemplateFormatTypes && model.CorrespondenceTemplateFormatTypes.map(function (i) { return CorrespondenceTemplateFormatType_1.CorrespondenceTemplateFormatType_Factory.CreateIncoming(i); }),
            CorrespondenceTemplates: model.CorrespondenceTemplates && model.CorrespondenceTemplates.map(function (i) { return CorrespondenceTemplate_1.CorrespondenceTemplate_Factory.CreateIncoming(i); }),
            Countries: model.Countries && model.Countries.map(function (i) { return Country_1.Country_Factory.CreateIncoming(i); }),
            CPTCodes: model.CPTCodes && model.CPTCodes.map(function (i) { return CPTCode_1.CPTCode_Factory.CreateIncoming(i); }),
            CPTModifiers: model.CPTModifiers && model.CPTModifiers.map(function (i) { return CPTModifier_1.CPTModifier_Factory.CreateIncoming(i); }),
            DataExportTemplateMergeFields: model.DataExportTemplateMergeFields && model.DataExportTemplateMergeFields.map(function (i) { return DataExportTemplateMergeField_1.DataExportTemplateMergeField_Factory.CreateIncoming(i); }),
            DataExportTemplates: model.DataExportTemplates && model.DataExportTemplates.map(function (i) { return DataExportTemplate_1.DataExportTemplate_Factory.CreateIncoming(i); }),
            DaysheetTransactions: model.DaysheetTransactions && model.DaysheetTransactions.map(function (i) { return DaysheetTransaction_1.DaysheetTransaction_Factory.CreateIncoming(i); }),
            DBScriptsLogs: model.DBScriptsLogs && model.DBScriptsLogs.map(function (i) { return DBScriptsLog_1.DBScriptsLog_Factory.CreateIncoming(i); }),
            DefaultRecallSettings: model.DefaultRecallSettings && model.DefaultRecallSettings.map(function (i) { return DefaultRecallSetting_1.DefaultRecallSetting_Factory.CreateIncoming(i); }),
            ECGSnapshots: model.ECGSnapshots && model.ECGSnapshots.map(function (i) { return ECGSnapshot_1.ECGSnapshot_Factory.CreateIncoming(i); }),
            EFormAttachments: model.EFormAttachments && model.EFormAttachments.map(function (i) { return EFormsAttachment_1.EFormsAttachment_Factory.CreateIncoming(i); }),
            EFormAttachmentTypes: model.EFormAttachmentTypes && model.EFormAttachmentTypes.map(function (i) { return EFormsAttachmentType_1.EFormsAttachmentType_Factory.CreateIncoming(i); }),
            EFormInstances: model.EFormInstances && model.EFormInstances.map(function (i) { return EFormsInstance_1.EFormsInstance_Factory.CreateIncoming(i); }),
            EFormsBinaryElementDetails: model.EFormsBinaryElementDetails && model.EFormsBinaryElementDetails.map(function (i) { return EFormsBinaryElementDetail_1.EFormsBinaryElementDetail_Factory.CreateIncoming(i); }),
            EFormsDataElementCategories: model.EFormsDataElementCategories && model.EFormsDataElementCategories.map(function (i) { return EFormsDataElementCategory_1.EFormsDataElementCategory_Factory.CreateIncoming(i); }),
            EFormsDataElementOptionTypes: model.EFormsDataElementOptionTypes && model.EFormsDataElementOptionTypes.map(function (i) { return EFormsDataElementOptionType_1.EFormsDataElementOptionType_Factory.CreateIncoming(i); }),
            EFormsDataElements: model.EFormsDataElements && model.EFormsDataElements.map(function (i) { return EFormsDataElement_1.EFormsDataElement_Factory.CreateIncoming(i); }),
            EFormsDataElementTypes: model.EFormsDataElementTypes && model.EFormsDataElementTypes.map(function (i) { return EFormsDataElementType_1.EFormsDataElementType_Factory.CreateIncoming(i); }),
            EFormsInstanceDetails: model.EFormsInstanceDetails && model.EFormsInstanceDetails.map(function (i) { return EFormsInstanceDetail_1.EFormsInstanceDetail_Factory.CreateIncoming(i); }),
            EFormsInstanceHistoryLogs: model.EFormsInstanceHistoryLogs && model.EFormsInstanceHistoryLogs.map(function (i) { return EFormsInstanceHistoryLog_1.EFormsInstanceHistoryLog_Factory.CreateIncoming(i); }),
            EFormsInstancesView: model.EFormsInstancesView && model.EFormsInstancesView.map(function (i) { return EFormsInstanceView_1.EFormsInstanceView_Factory.CreateIncoming(i); }),
            EFormsLocationSettings: model.EFormsLocationSettings && model.EFormsLocationSettings.map(function (i) { return EFormsLocationSettings_1.EFormsLocationSettings_Factory.CreateIncoming(i); }),
            EFormsNumericElementDetails: model.EFormsNumericElementDetails && model.EFormsNumericElementDetails.map(function (i) { return EFormsNumericElementDetail_1.EFormsNumericElementDetail_Factory.CreateIncoming(i); }),
            EFormsSelectElementOptions: model.EFormsSelectElementOptions && model.EFormsSelectElementOptions.map(function (i) { return EFormsSelectElementOption_1.EFormsSelectElementOption_Factory.CreateIncoming(i); }),
            EFormsSourceTypes: model.EFormsSourceTypes && model.EFormsSourceTypes.map(function (i) { return EFormsSourceType_1.EFormsSourceType_Factory.CreateIncoming(i); }),
            EFormsStatusTrackings: model.EFormsStatusTrackings && model.EFormsStatusTrackings.map(function (i) { return EFormsStatusTracking_1.EFormsStatusTracking_Factory.CreateIncoming(i); }),
            EFormStatuses: model.EFormStatuses && model.EFormStatuses.map(function (i) { return EFormsStatus_1.EFormsStatus_Factory.CreateIncoming(i); }),
            EFormsTemplateCategories: model.EFormsTemplateCategories && model.EFormsTemplateCategories.map(function (i) { return EFormsTemplateCategory_1.EFormsTemplateCategory_Factory.CreateIncoming(i); }),
            EFormsTemplateDataElements: model.EFormsTemplateDataElements && model.EFormsTemplateDataElements.map(function (i) { return EFormsTemplateDataElement_1.EFormsTemplateDataElement_Factory.CreateIncoming(i); }),
            EFormsTemplateDetails: model.EFormsTemplateDetails && model.EFormsTemplateDetails.map(function (i) { return EFormsTemplateDetail_1.EFormsTemplateDetail_Factory.CreateIncoming(i); }),
            EFormsToothChartElementDentitionTypes: model.EFormsToothChartElementDentitionTypes && model.EFormsToothChartElementDentitionTypes.map(function (i) { return EFormsToothChartElementDentitionType_1.EFormsToothChartElementDentitionType_Factory.CreateIncoming(i); }),
            EFormsToothChartElementDetails: model.EFormsToothChartElementDetails && model.EFormsToothChartElementDetails.map(function (i) { return EFormsToothChartElementDetail_1.EFormsToothChartElementDetail_Factory.CreateIncoming(i); }),
            EFormsToothChartElementTeeth: model.EFormsToothChartElementTeeth && model.EFormsToothChartElementTeeth.map(function (i) { return EFormsToothChartElementTooth_1.EFormsToothChartElementTooth_Factory.CreateIncoming(i); }),
            EFormTemplates: model.EFormTemplates && model.EFormTemplates.map(function (i) { return EFormsTemplate_1.EFormsTemplate_Factory.CreateIncoming(i); }),
            Elastics: model.Elastics && model.Elastics.map(function (i) { return Elastic_1.Elastic_Factory.CreateIncoming(i); }),
            ElasticTypes: model.ElasticTypes && model.ElasticTypes.map(function (i) { return ElasticType_1.ElasticType_Factory.CreateIncoming(i); }),
            ElasticUsageTypes: model.ElasticUsageTypes && model.ElasticUsageTypes.map(function (i) { return ElasticUsageType_1.ElasticUsageType_Factory.CreateIncoming(i); }),
            EmploymentStatuses: model.EmploymentStatuses && model.EmploymentStatuses.map(function (i) { return EmploymentStatus_1.EmploymentStatus_Factory.CreateIncoming(i); }),
            EnterpriseConsultations: model.EnterpriseConsultations && model.EnterpriseConsultations.map(function (i) { return EnterpriseConsultation_1.EnterpriseConsultation_Factory.CreateIncoming(i); }),
            EnterpriseConsultationsAuditLogs: model.EnterpriseConsultationsAuditLogs && model.EnterpriseConsultationsAuditLogs.map(function (i) { return EnterpriseConsultationsAuditLog_1.EnterpriseConsultationsAuditLog_Factory.CreateIncoming(i); }),
            EnterpriseConsultationSubjectLinks: model.EnterpriseConsultationSubjectLinks && model.EnterpriseConsultationSubjectLinks.map(function (i) { return EnterpriseConsultationSubjectLink_1.EnterpriseConsultationSubjectLink_Factory.CreateIncoming(i); }),
            EnterpriseLocationDepartments: model.EnterpriseLocationDepartments && model.EnterpriseLocationDepartments.map(function (i) { return EnterpriseLocationDepartment_1.EnterpriseLocationDepartment_Factory.CreateIncoming(i); }),
            EnterpriseLocations: model.EnterpriseLocations && model.EnterpriseLocations.map(function (i) { return EnterpriseLocation_1.EnterpriseLocation_Factory.CreateIncoming(i); }),
            EnterpriseReportingColumnDefinitions: model.EnterpriseReportingColumnDefinitions && model.EnterpriseReportingColumnDefinitions.map(function (i) { return EnterpriseReportingColumnDefinition_1.EnterpriseReportingColumnDefinition_Factory.CreateIncoming(i); }),
            EnterpriseReportingReports: model.EnterpriseReportingReports && model.EnterpriseReportingReports.map(function (i) { return EnterpriseReportingReport_1.EnterpriseReportingReport_Factory.CreateIncoming(i); }),
            EnterpriseUserLocations: model.EnterpriseUserLocations && model.EnterpriseUserLocations.map(function (i) { return EnterpriseUserLocation_1.EnterpriseUserLocation_Factory.CreateIncoming(i); }),
            EntityTypes: model.EntityTypes && model.EntityTypes.map(function (i) { return EntityType_1.EntityType_Factory.CreateIncoming(i); }),
            EPaymentDevices: model.EPaymentDevices && model.EPaymentDevices.map(function (i) { return EPaymentDevice_1.EPaymentDevice_Factory.CreateIncoming(i); }),
            EPaymentMerchants: model.EPaymentMerchants && model.EPaymentMerchants.map(function (i) { return EPaymentMerchant_1.EPaymentMerchant_Factory.CreateIncoming(i); }),
            EscortTypes: model.EscortTypes && model.EscortTypes.map(function (i) { return EscortType_1.EscortType_Factory.CreateIncoming(i); }),
            EServicesReports: model.EServicesReports && model.EServicesReports.map(function (i) { return EServicesReport_1.EServicesReport_Factory.CreateIncoming(i); }),
            EServicesSettings: model.EServicesSettings && model.EServicesSettings.map(function (i) { return EServicesSetting_1.EServicesSetting_Factory.CreateIncoming(i); }),
            EstateReferralAdditionalProcedures: model.EstateReferralAdditionalProcedures && model.EstateReferralAdditionalProcedures.map(function (i) { return EstateReferralAdditionalProcedure_1.EstateReferralAdditionalProcedure_Factory.CreateIncoming(i); }),
            EstateReferralAuditLogs: model.EstateReferralAuditLogs && model.EstateReferralAuditLogs.map(function (i) { return EstateReferralAuditLog_1.EstateReferralAuditLog_Factory.CreateIncoming(i); }),
            EstateReferralRejectionReasons: model.EstateReferralRejectionReasons && model.EstateReferralRejectionReasons.map(function (i) { return EstateReferralRejectionReason_1.EstateReferralRejectionReason_Factory.CreateIncoming(i); }),
            EstateReferrals: model.EstateReferrals && model.EstateReferrals.map(function (i) { return EstateReferral_1.EstateReferral_Factory.CreateIncoming(i); }),
            EthnicityTypes: model.EthnicityTypes && model.EthnicityTypes.map(function (i) { return EthnicityType_1.EthnicityType_Factory.CreateIncoming(i); }),
            GenderTypes: model.GenderTypes && model.GenderTypes.map(function (i) { return GenderType_1.GenderType_Factory.CreateIncoming(i); }),
            HealthMeasurements: model.HealthMeasurements && model.HealthMeasurements.map(function (i) { return HealthMeasurement_1.HealthMeasurement_Factory.CreateIncoming(i); }),
            HealthMeasurementTypes: model.HealthMeasurementTypes && model.HealthMeasurementTypes.map(function (i) { return HealthMeasurementType_1.HealthMeasurementType_Factory.CreateIncoming(i); }),
            HISIntegrationQueryHistory: model.HISIntegrationQueryHistory && model.HISIntegrationQueryHistory.map(function (i) { return HISIntegrationQueryHistory_1.HISIntegrationQueryHistory_Factory.CreateIncoming(i); }),
            ImagingOrders: model.ImagingOrders && model.ImagingOrders.map(function (i) { return ImagingOrder_1.ImagingOrder_Factory.CreateIncoming(i); }),
            ImagingReferenceTypes: model.ImagingReferenceTypes && model.ImagingReferenceTypes.map(function (i) { return ImagingReferenceType_1.ImagingReferenceType_Factory.CreateIncoming(i); }),
            ImagingRequests: model.ImagingRequests && model.ImagingRequests.map(function (i) { return CreateImagingRequest_1.CreateImagingRequest_Factory.CreateIncoming(i); }),
            ImplantBrands: model.ImplantBrands && model.ImplantBrands.map(function (i) { return ImplantBrand_1.ImplantBrand_Factory.CreateIncoming(i); }),
            ImplantBrandSizes: model.ImplantBrandSizes && model.ImplantBrandSizes.map(function (i) { return ImplantBrandSize_1.ImplantBrandSize_Factory.CreateIncoming(i); }),
            ImplantDetails: model.ImplantDetails && model.ImplantDetails.map(function (i) { return ImplantDetail_1.ImplantDetail_Factory.CreateIncoming(i); }),
            ImplantDetailVersions: model.ImplantDetailVersions && model.ImplantDetailVersions.map(function (i) { return ImplantDetailVersion_1.ImplantDetailVersion_Factory.CreateIncoming(i); }),
            ImplantManufacturers: model.ImplantManufacturers && model.ImplantManufacturers.map(function (i) { return ImplantManufacturer_1.ImplantManufacturer_Factory.CreateIncoming(i); }),
            ImplantRecords: model.ImplantRecords && model.ImplantRecords.map(function (i) { return ImplantRecord_1.ImplantRecord_Factory.CreateIncoming(i); }),
            InitialBalances: model.InitialBalances && model.InitialBalances.map(function (i) { return InitialBalance_1.InitialBalance_Factory.CreateIncoming(i); }),
            InsuranceClaimAttachmentFiles: model.InsuranceClaimAttachmentFiles && model.InsuranceClaimAttachmentFiles.map(function (i) { return InsuranceClaimAttachmentFile_1.InsuranceClaimAttachmentFile_Factory.CreateIncoming(i); }),
            InsuranceClaimAttachments: model.InsuranceClaimAttachments && model.InsuranceClaimAttachments.map(function (i) { return InsuranceClaimAttachment_1.InsuranceClaimAttachment_Factory.CreateIncoming(i); }),
            InsuranceClaimBatchSubmissions: model.InsuranceClaimBatchSubmissions && model.InsuranceClaimBatchSubmissions.map(function (i) { return InsuranceClaimBatchSubmission_1.InsuranceClaimBatchSubmission_Factory.CreateIncoming(i); }),
            InsuranceClaimContinuationItems: model.InsuranceClaimContinuationItems && model.InsuranceClaimContinuationItems.map(function (i) { return InsuranceClaimContinuationItem_1.InsuranceClaimContinuationItem_Factory.CreateIncoming(i); }),
            InsuranceClaimItemAttachmentRequirements: model.InsuranceClaimItemAttachmentRequirements && model.InsuranceClaimItemAttachmentRequirements.map(function (i) { return InsuranceClaimItemAttachmentRequirement_1.InsuranceClaimItemAttachmentRequirement_Factory.CreateIncoming(i); }),
            InsuranceClaimItems: model.InsuranceClaimItems && model.InsuranceClaimItems.map(function (i) { return InsuranceClaimItem_1.InsuranceClaimItem_Factory.CreateIncoming(i); }),
            InsuranceClaimNotes: model.InsuranceClaimNotes && model.InsuranceClaimNotes.map(function (i) { return InsuranceClaimNote_1.InsuranceClaimNote_Factory.CreateIncoming(i); }),
            InsuranceClaimNoteTypes: model.InsuranceClaimNoteTypes && model.InsuranceClaimNoteTypes.map(function (i) { return InsuranceClaimNoteType_1.InsuranceClaimNoteType_Factory.CreateIncoming(i); }),
            InsuranceClaims: model.InsuranceClaims && model.InsuranceClaims.map(function (i) { return InsuranceClaim_1.InsuranceClaim_Factory.CreateIncoming(i); }),
            InsuranceClaimStatusCodes: model.InsuranceClaimStatusCodes && model.InsuranceClaimStatusCodes.map(function (i) { return InsuranceClaimStatusCode_1.InsuranceClaimStatusCode_Factory.CreateIncoming(i); }),
            InsuranceClaimTypes: model.InsuranceClaimTypes && model.InsuranceClaimTypes.map(function (i) { return InsuranceClaimType_1.InsuranceClaimType_Factory.CreateIncoming(i); }),
            InsuranceEligibilityTypes: model.InsuranceEligibilityTypes && model.InsuranceEligibilityTypes.map(function (i) { return InsuranceEligibilityType_1.InsuranceEligibilityType_Factory.CreateIncoming(i); }),
            InsurancePaperClaimFormats: model.InsurancePaperClaimFormats && model.InsurancePaperClaimFormats.map(function (i) { return InsurancePaperClaimFormat_1.InsurancePaperClaimFormat_Factory.CreateIncoming(i); }),
            InsurancePayerDetails: model.InsurancePayerDetails && model.InsurancePayerDetails.map(function (i) { return InsurancePayerDetail_1.InsurancePayerDetail_Factory.CreateIncoming(i); }),
            InsurancePayers: model.InsurancePayers && model.InsurancePayers.map(function (i) { return InsurancePayer_1.InsurancePayer_Factory.CreateIncoming(i); }),
            InsurancePayerTypes: model.InsurancePayerTypes && model.InsurancePayerTypes.map(function (i) { return InsurancePayerType_1.InsurancePayerType_Factory.CreateIncoming(i); }),
            InsurancePlanDetails: model.InsurancePlanDetails && model.InsurancePlanDetails.map(function (i) { return InsurancePlanDetail_1.InsurancePlanDetail_Factory.CreateIncoming(i); }),
            InsurancePlans: model.InsurancePlans && model.InsurancePlans.map(function (i) { return InsurancePlan_1.InsurancePlan_Factory.CreateIncoming(i); }),
            InsurancePolicies: model.InsurancePolicies && model.InsurancePolicies.map(function (i) { return InsurancePolicy_1.InsurancePolicy_Factory.CreateIncoming(i); }),
            InsurancePolicyCoverageTypes: model.InsurancePolicyCoverageTypes && model.InsurancePolicyCoverageTypes.map(function (i) { return InsurancePolicyCoverageType_1.InsurancePolicyCoverageType_Factory.CreateIncoming(i); }),
            ITeroScans: model.ITeroScans && model.ITeroScans.map(function (i) { return ITeroScan_1.ITeroScan_Factory.CreateIncoming(i); }),
            IVLocations: model.IVLocations && model.IVLocations.map(function (i) { return IVLocation_1.IVLocation_Factory.CreateIncoming(i); }),
            IVTypes: model.IVTypes && model.IVTypes.map(function (i) { return IVType_1.IVType_Factory.CreateIncoming(i); }),
            JobStatuses: model.JobStatuses && model.JobStatuses.map(function (i) { return JobStatus_1.JobStatus_Factory.CreateIncoming(i); }),
            LabCases: model.LabCases && model.LabCases.map(function (i) { return LabCase_1.LabCase_Factory.CreateIncoming(i); }),
            Labs: model.Labs && model.Labs.map(function (i) { return Lab_1.Lab_Factory.CreateIncoming(i); }),
            LabtracDoctors: model.LabtracDoctors && model.LabtracDoctors.map(function (i) { return LabtracDoctor_1.LabtracDoctor_Factory.CreateIncoming(i); }),
            LabtracEnclosures: model.LabtracEnclosures && model.LabtracEnclosures.map(function (i) { return LabtracEnclosure_1.LabtracEnclosure_Factory.CreateIncoming(i); }),
            LabtracItemOptions: model.LabtracItemOptions && model.LabtracItemOptions.map(function (i) { return LabtracItemOption_1.LabtracItemOption_Factory.CreateIncoming(i); }),
            LabtracLabs: model.LabtracLabs && model.LabtracLabs.map(function (i) { return LabtracLab_1.LabtracLab_Factory.CreateIncoming(i); }),
            LabtracShippingRoutes: model.LabtracShippingRoutes && model.LabtracShippingRoutes.map(function (i) { return LabtracShippingRoute_1.LabtracShippingRoute_Factory.CreateIncoming(i); }),
            Languages: model.Languages && model.Languages.map(function (i) { return Language_1.Language_Factory.CreateIncoming(i); }),
            LegacyIdentifiers: model.LegacyIdentifiers && model.LegacyIdentifiers.map(function (i) { return LegacyIdentifier_1.LegacyIdentifier_Factory.CreateIncoming(i); }),
            LocationAccountsReceivable: model.LocationAccountsReceivable && model.LocationAccountsReceivable.map(function (i) { return LocationAccountsReceivable_1.LocationAccountsReceivable_Factory.CreateIncoming(i); }),
            LocationBulkStatementSettings: model.LocationBulkStatementSettings && model.LocationBulkStatementSettings.map(function (i) { return LocationBulkStatementSetting_1.LocationBulkStatementSetting_Factory.CreateIncoming(i); }),
            LocationClaimsActivities: model.LocationClaimsActivities && model.LocationClaimsActivities.map(function (i) { return LocationClaimsActivity_1.LocationClaimsActivity_Factory.CreateIncoming(i); }),
            LocationDefaultWorkingDays: model.LocationDefaultWorkingDays && model.LocationDefaultWorkingDays.map(function (i) { return LocationDefaultWorkingDay_1.LocationDefaultWorkingDay_Factory.CreateIncoming(i); }),
            LocationFinancialActivities: model.LocationFinancialActivities && model.LocationFinancialActivities.map(function (i) { return LocationFinancialActivity_1.LocationFinancialActivity_Factory.CreateIncoming(i); }),
            Locations: model.Locations && model.Locations.map(function (i) { return Location_1.Location_Factory.CreateIncoming(i); }),
            LocationSettings: model.LocationSettings && model.LocationSettings.map(function (i) { return LocationSetting_1.LocationSetting_Factory.CreateIncoming(i); }),
            LocationWorkingDays: model.LocationWorkingDays && model.LocationWorkingDays.map(function (i) { return LocationWorkingDay_1.LocationWorkingDay_Factory.CreateIncoming(i); }),
            LocationWorkingDayTimes: model.LocationWorkingDayTimes && model.LocationWorkingDayTimes.map(function (i) { return LocationWorkingDayTime_1.LocationWorkingDayTime_Factory.CreateIncoming(i); }),
            MasterDataDistributionEvents: model.MasterDataDistributionEvents && model.MasterDataDistributionEvents.map(function (i) { return MasterDataDistributionEvent_1.MasterDataDistributionEvent_Factory.CreateIncoming(i); }),
            MasterDataDistributionEventStats: model.MasterDataDistributionEventStats && model.MasterDataDistributionEventStats.map(function (i) { return MasterDataDistributionEventStats_1.MasterDataDistributionEventStats_Factory.CreateIncoming(i); }),
            MawidDeltaExtractProblems: model.MawidDeltaExtractProblems && model.MawidDeltaExtractProblems.map(function (i) { return MawidDeltaExtractProblem_1.MawidDeltaExtractProblem_Factory.CreateIncoming(i); }),
            MawidDeltaExtracts: model.MawidDeltaExtracts && model.MawidDeltaExtracts.map(function (i) { return MawidDeltaExtract_1.MawidDeltaExtract_Factory.CreateIncoming(i); }),
            MawidDeltaResponses: model.MawidDeltaResponses && model.MawidDeltaResponses.map(function (i) { return MawidDeltaResponse_1.MawidDeltaResponse_Factory.CreateIncoming(i); }),
            MawidFirstExtractProblems: model.MawidFirstExtractProblems && model.MawidFirstExtractProblems.map(function (i) { return MawidFirstExtractProblem_1.MawidFirstExtractProblem_Factory.CreateIncoming(i); }),
            MawidFirstExtracts: model.MawidFirstExtracts && model.MawidFirstExtracts.map(function (i) { return MawidFirstExtract_1.MawidFirstExtract_Factory.CreateIncoming(i); }),
            MawidFirstResponses: model.MawidFirstResponses && model.MawidFirstResponses.map(function (i) { return MawidFirstResponse_1.MawidFirstResponse_Factory.CreateIncoming(i); }),
            MawidRequests: model.MawidRequests && model.MawidRequests.map(function (i) { return MawidRequest_1.MawidRequest_Factory.CreateIncoming(i); }),
            MedicalAlertTypes: model.MedicalAlertTypes && model.MedicalAlertTypes.map(function (i) { return MedicalAlertType_1.MedicalAlertType_Factory.CreateIncoming(i); }),
            MedicalClaimAdditionalInfo: model.MedicalClaimAdditionalInfo && model.MedicalClaimAdditionalInfo.map(function (i) { return MedicalClaimAdditionalInfo_1.MedicalClaimAdditionalInfo_Factory.CreateIncoming(i); }),
            MedicalClaimItemAdditionalInfo: model.MedicalClaimItemAdditionalInfo && model.MedicalClaimItemAdditionalInfo.map(function (i) { return MedicalClaimItemAdditionalInfo_1.MedicalClaimItemAdditionalInfo_Factory.CreateIncoming(i); }),
            MedicalInsuranceTypes: model.MedicalInsuranceTypes && model.MedicalInsuranceTypes.map(function (i) { return MedicalInsuranceType_1.MedicalInsuranceType_Factory.CreateIncoming(i); }),
            MedicalQuestionnaireQuestionGroups: model.MedicalQuestionnaireQuestionGroups && model.MedicalQuestionnaireQuestionGroups.map(function (i) { return MedicalQuestionnaireQuestionGroup_1.MedicalQuestionnaireQuestionGroup_Factory.CreateIncoming(i); }),
            MedicalQuestionnaireQuestions: model.MedicalQuestionnaireQuestions && model.MedicalQuestionnaireQuestions.map(function (i) { return MedicalQuestionnaireQuestion_1.MedicalQuestionnaireQuestion_Factory.CreateIncoming(i); }),
            MedicalQuestionnaires: model.MedicalQuestionnaires && model.MedicalQuestionnaires.map(function (i) { return MedicalQuestionnaire_1.MedicalQuestionnaire_Factory.CreateIncoming(i); }),
            MedicationTypes: model.MedicationTypes && model.MedicationTypes.map(function (i) { return MedicationType_1.MedicationType_Factory.CreateIncoming(i); }),
            MouthRegionGroups: model.MouthRegionGroups && model.MouthRegionGroups.map(function (i) { return MouthRegionGroup_1.MouthRegionGroup_Factory.CreateIncoming(i); }),
            NewChargeProcedures: model.NewChargeProcedures && model.NewChargeProcedures.map(function (i) { return NewChargeProcedure_1.NewChargeProcedure_Factory.CreateIncoming(i); }),
            NewCharges: model.NewCharges && model.NewCharges.map(function (i) { return NewCharge_1.NewCharge_Factory.CreateIncoming(i); }),
            NewPaymentResults: model.NewPaymentResults && model.NewPaymentResults.map(function (i) { return NewPaymentResult_1.NewPaymentResult_Factory.CreateIncoming(i); }),
            NewPayments: model.NewPayments && model.NewPayments.map(function (i) { return NewPayment_1.NewPayment_Factory.CreateIncoming(i); }),
            NewPaymentSplits: model.NewPaymentSplits && model.NewPaymentSplits.map(function (i) { return NewPaymentSplit_1.NewPaymentSplit_Factory.CreateIncoming(i); }),
            NHSAcornAssessments: model.NHSAcornAssessments && model.NHSAcornAssessments.map(function (i) { return NHSAcornAssessment_1.NHSAcornAssessment_Factory.CreateIncoming(i); }),
            NHSActivityBands: model.NHSActivityBands && model.NHSActivityBands.map(function (i) { return NHSActivityBand_1.NHSActivityBand_Factory.CreateIncoming(i); }),
            NHSActivityBandValues: model.NHSActivityBandValues && model.NHSActivityBandValues.map(function (i) { return NHSActivityBandValue_1.NHSActivityBandValue_Factory.CreateIncoming(i); }),
            NHSActivityValueSets: model.NHSActivityValueSets && model.NHSActivityValueSets.map(function (i) { return NHSActivityValueSet_1.NHSActivityValueSet_Factory.CreateIncoming(i); }),
            NHSCaseMixRecordedCriteria: model.NHSCaseMixRecordedCriteria && model.NHSCaseMixRecordedCriteria.map(function (i) { return NHSCaseMixRecordedCriteria_1.NHSCaseMixRecordedCriteria_Factory.CreateIncoming(i); }),
            NHSCaseMixRecordings: model.NHSCaseMixRecordings && model.NHSCaseMixRecordings.map(function (i) { return NHSCaseMixRecording_1.NHSCaseMixRecording_Factory.CreateIncoming(i); }),
            NHSCaseMixScoreWeights: model.NHSCaseMixScoreWeights && model.NHSCaseMixScoreWeights.map(function (i) { return NHSCaseMixScoreWeight_1.NHSCaseMixScoreWeight_Factory.CreateIncoming(i); }),
            NHSCategories: model.NHSCategories && model.NHSCategories.map(function (i) { return NHSCategory_1.NHSCategory_Factory.CreateIncoming(i); }),
            NHSCategoryBands: model.NHSCategoryBands && model.NHSCategoryBands.map(function (i) { return NHSCategoryBand_1.NHSCategoryBand_Factory.CreateIncoming(i); }),
            NHSCategoryDates: model.NHSCategoryDates && model.NHSCategoryDates.map(function (i) { return NHSCategoryDate_1.NHSCategoryDate_Factory.CreateIncoming(i); }),
            NHSCDSBands: model.NHSCDSBands && model.NHSCDSBands.map(function (i) { return NHSCDSBand_1.NHSCDSBand_Factory.CreateIncoming(i); }),
            NHSChargeBands: model.NHSChargeBands && model.NHSChargeBands.map(function (i) { return NHSChargeBand_1.NHSChargeBand_Factory.CreateIncoming(i); }),
            NHSChargeBandValues: model.NHSChargeBandValues && model.NHSChargeBandValues.map(function (i) { return NHSChargeBandValue_1.NHSChargeBandValue_Factory.CreateIncoming(i); }),
            NHSChargeValueSets: model.NHSChargeValueSets && model.NHSChargeValueSets.map(function (i) { return NHSChargeValueSet_1.NHSChargeValueSet_Factory.CreateIncoming(i); }),
            NHSClaimComments: model.NHSClaimComments && model.NHSClaimComments.map(function (i) { return NHSClaimComment_1.NHSClaimComment_Factory.CreateIncoming(i); }),
            NHSClaimMessageTypes: model.NHSClaimMessageTypes && model.NHSClaimMessageTypes.map(function (i) { return NHSClaimMessageType_1.NHSClaimMessageType_Factory.CreateIncoming(i); }),
            NHSClaims: model.NHSClaims && model.NHSClaims.map(function (i) { return NHSClaim_1.NHSClaim_Factory.CreateIncoming(i); }),
            NHSClaimStatusChanges: model.NHSClaimStatusChanges && model.NHSClaimStatusChanges.map(function (i) { return NHSClaimStatusChange_1.NHSClaimStatusChange_Factory.CreateIncoming(i); }),
            NHSClaimSubmissions: model.NHSClaimSubmissions && model.NHSClaimSubmissions.map(function (i) { return NHSClaimSubmission_1.NHSClaimSubmission_Factory.CreateIncoming(i); }),
            NHSClinicalDataSets: model.NHSClinicalDataSets && model.NHSClinicalDataSets.map(function (i) { return NHSClinicalDataSet_1.NHSClinicalDataSet_Factory.CreateIncoming(i); }),
            NHSCLRNs: model.NHSCLRNs && model.NHSCLRNs.map(function (i) { return NHSCLRN_1.NHSCLRN_Factory.CreateIncoming(i); }),
            NHSContractAdjustments: model.NHSContractAdjustments && model.NHSContractAdjustments.map(function (i) { return NHSContractAdjustment_1.NHSContractAdjustment_Factory.CreateIncoming(i); }),
            NHSContractMemberships: model.NHSContractMemberships && model.NHSContractMemberships.map(function (i) { return NHSContractMembership_1.NHSContractMembership_Factory.CreateIncoming(i); }),
            NHSContractPeriodMonths: model.NHSContractPeriodMonths && model.NHSContractPeriodMonths.map(function (i) { return NHSContractPeriodMonth_1.NHSContractPeriodMonth_Factory.CreateIncoming(i); }),
            NHSContractPeriods: model.NHSContractPeriods && model.NHSContractPeriods.map(function (i) { return NHSContractPeriod_1.NHSContractPeriod_Factory.CreateIncoming(i); }),
            NHSContracts: model.NHSContracts && model.NHSContracts.map(function (i) { return NHSContract_1.NHSContract_Factory.CreateIncoming(i); }),
            NHSContractTargets: model.NHSContractTargets && model.NHSContractTargets.map(function (i) { return NHSContractTarget_1.NHSContractTarget_Factory.CreateIncoming(i); }),
            NHSCourseClaimMessageInfos: model.NHSCourseClaimMessageInfos && model.NHSCourseClaimMessageInfos.map(function (i) { return NHSCourseClaimMessageInfo_1.NHSCourseClaimMessageInfo_Factory.CreateIncoming(i); }),
            NHSCourseDetails: model.NHSCourseDetails && model.NHSCourseDetails.map(function (i) { return NHSCourseDetail_1.NHSCourseDetail_Factory.CreateIncoming(i); }),
            NHSCourseDetailsOrtho: model.NHSCourseDetailsOrtho && model.NHSCourseDetailsOrtho.map(function (i) { return NHSCourseDetailOrtho_1.NHSCourseDetailOrtho_Factory.CreateIncoming(i); }),
            NHSCourseSnapshots: model.NHSCourseSnapshots && model.NHSCourseSnapshots.map(function (i) { return NHSCourseSnapshot_1.NHSCourseSnapshot_Factory.CreateIncoming(i); }),
            NHSExemptCodes: model.NHSExemptCodes && model.NHSExemptCodes.map(function (i) { return NHSExemptCode_1.NHSExemptCode_Factory.CreateIncoming(i); }),
            NHSExemptionChecks: model.NHSExemptionChecks && model.NHSExemptionChecks.map(function (i) { return NHSExemptionCheck_1.NHSExemptionCheck_Factory.CreateIncoming(i); }),
            NHSFP17TriageDetails: model.NHSFP17TriageDetails && model.NHSFP17TriageDetails.map(function (i) { return NHSFP17TriageDetail_1.NHSFP17TriageDetail_Factory.CreateIncoming(i); }),
            NHSInterchanges: model.NHSInterchanges && model.NHSInterchanges.map(function (i) { return NHSInterchange_1.NHSInterchange_Factory.CreateIncoming(i); }),
            NHSLocations: model.NHSLocations && model.NHSLocations.map(function (i) { return NHSLocation_1.NHSLocation_Factory.CreateIncoming(i); }),
            NHSMailboxes: model.NHSMailboxes && model.NHSMailboxes.map(function (i) { return NHSMailbox_1.NHSMailbox_Factory.CreateIncoming(i); }),
            NHSPatientDeclarations: model.NHSPatientDeclarations && model.NHSPatientDeclarations.map(function (i) { return NHSPatientDeclaration_1.NHSPatientDeclaration_Factory.CreateIncoming(i); }),
            NHSPatientDetails: model.NHSPatientDetails && model.NHSPatientDetails.map(function (i) { return NHSPatientDetail_1.NHSPatientDetail_Factory.CreateIncoming(i); }),
            NHSProcessingErrors: model.NHSProcessingErrors && model.NHSProcessingErrors.map(function (i) { return NHSProcessingError_1.NHSProcessingError_Factory.CreateIncoming(i); }),
            NHSRegions: model.NHSRegions && model.NHSRegions.map(function (i) { return NHSRegion_1.NHSRegion_Factory.CreateIncoming(i); }),
            NHSRegistrationTypes: model.NHSRegistrationTypes && model.NHSRegistrationTypes.map(function (i) { return NHSRegistrationType_1.NHSRegistrationType_Factory.CreateIncoming(i); }),
            NHSResponseHistoryEntries: model.NHSResponseHistoryEntries && model.NHSResponseHistoryEntries.map(function (i) { return NHSResponseHistory_1.NHSResponseHistory_Factory.CreateIncoming(i); }),
            NHSSchedulePayments: model.NHSSchedulePayments && model.NHSSchedulePayments.map(function (i) { return NHSSchedulePayment_1.NHSSchedulePayment_Factory.CreateIncoming(i); }),
            NHSSchedules: model.NHSSchedules && model.NHSSchedules.map(function (i) { return NHSSchedule_1.NHSSchedule_Factory.CreateIncoming(i); }),
            NHSTransmissionFiles: model.NHSTransmissionFiles && model.NHSTransmissionFiles.map(function (i) { return NHSTransmissionFile_1.NHSTransmissionFile_Factory.CreateIncoming(i); }),
            NHSTreatmentArrangements: model.NHSTreatmentArrangements && model.NHSTreatmentArrangements.map(function (i) { return NHSTreatmentArrangement_1.NHSTreatmentArrangement_Factory.CreateIncoming(i); }),
            NHSTriageDetails: model.NHSTriageDetails && model.NHSTriageDetails.map(function (i) { return NHSTriageDetail_1.NHSTriageDetail_Factory.CreateIncoming(i); }),
            NHSUserDetails: model.NHSUserDetails && model.NHSUserDetails.map(function (i) { return NHSUserDetail_1.NHSUserDetail_Factory.CreateIncoming(i); }),
            NoteContentTypes: model.NoteContentTypes && model.NoteContentTypes.map(function (i) { return NoteContentType_1.NoteContentType_Factory.CreateIncoming(i); }),
            Notes: model.Notes && model.Notes.map(function (i) { return Note_1.Note_Factory.CreateIncoming(i); }),
            NoteTemplateCategories: model.NoteTemplateCategories && model.NoteTemplateCategories.map(function (i) { return NoteTemplateCategory_1.NoteTemplateCategory_Factory.CreateIncoming(i); }),
            NoteTemplateFavourites: model.NoteTemplateFavourites && model.NoteTemplateFavourites.map(function (i) { return NoteTemplateFavourite_1.NoteTemplateFavourite_Factory.CreateIncoming(i); }),
            NoteTemplates: model.NoteTemplates && model.NoteTemplates.map(function (i) { return NoteTemplate_1.NoteTemplate_Factory.CreateIncoming(i); }),
            NoteVersions: model.NoteVersions && model.NoteVersions.map(function (i) { return NoteVersion_1.NoteVersion_Factory.CreateIncoming(i); }),
            OrthoContractChargeSchedules: model.OrthoContractChargeSchedules && model.OrthoContractChargeSchedules.map(function (i) { return OrthoContractChargeSchedule_1.OrthoContractChargeSchedule_Factory.CreateIncoming(i); }),
            OrthoContractCorrespondenceSettings: model.OrthoContractCorrespondenceSettings && model.OrthoContractCorrespondenceSettings.map(function (i) { return OrthoContractCorrespondenceSetting_1.OrthoContractCorrespondenceSetting_Factory.CreateIncoming(i); }),
            OrthoContractFrequencies: model.OrthoContractFrequencies && model.OrthoContractFrequencies.map(function (i) { return OrthoContractFrequency_1.OrthoContractFrequency_Factory.CreateIncoming(i); }),
            OrthoContracts: model.OrthoContracts && model.OrthoContracts.map(function (i) { return OrthoContract_1.OrthoContract_Factory.CreateIncoming(i); }),
            OrthoContractStatuses: model.OrthoContractStatuses && model.OrthoContractStatuses.map(function (i) { return OrthoContractStatus_1.OrthoContractStatus_Factory.CreateIncoming(i); }),
            OrthoPatientTreatmentStatuses: model.OrthoPatientTreatmentStatuses && model.OrthoPatientTreatmentStatuses.map(function (i) { return OrthoPatientTreatmentStatus_1.OrthoPatientTreatmentStatus_Factory.CreateIncoming(i); }),
            OrthoTreatmentStatuses: model.OrthoTreatmentStatuses && model.OrthoTreatmentStatuses.map(function (i) { return OrthoTreatmentStatus_1.OrthoTreatmentStatus_Factory.CreateIncoming(i); }),
            PatientAllergies: model.PatientAllergies && model.PatientAllergies.map(function (i) { return PatientAllergy_1.PatientAllergy_Factory.CreateIncoming(i); }),
            PatientAllergyVersions: model.PatientAllergyVersions && model.PatientAllergyVersions.map(function (i) { return PatientAllergyVersion_1.PatientAllergyVersion_Factory.CreateIncoming(i); }),
            PatientArchiveRecords: model.PatientArchiveRecords && model.PatientArchiveRecords.map(function (i) { return PatientArchiveRecord_1.PatientArchiveRecord_Factory.CreateIncoming(i); }),
            PatientConcerns: model.PatientConcerns && model.PatientConcerns.map(function (i) { return PatientConcern_1.PatientConcern_Factory.CreateIncoming(i); }),
            PatientConnections: model.PatientConnections && model.PatientConnections.map(function (i) { return PatientConnection_1.PatientConnection_Factory.CreateIncoming(i); }),
            PatientConnectionTypes: model.PatientConnectionTypes && model.PatientConnectionTypes.map(function (i) { return PatientConnectionType_1.PatientConnectionType_Factory.CreateIncoming(i); }),
            PatientContactNotes: model.PatientContactNotes && model.PatientContactNotes.map(function (i) { return PatientContactNote_1.PatientContactNote_Factory.CreateIncoming(i); }),
            PatientERxDetails: model.PatientERxDetails && model.PatientERxDetails.map(function (i) { return PatientERxDetail_1.PatientERxDetail_Factory.CreateIncoming(i); }),
            PatientHistories: model.PatientHistories && model.PatientHistories.map(function (i) { return PatientHistory_1.PatientHistory_Factory.CreateIncoming(i); }),
            PatientImageFavourites: model.PatientImageFavourites && model.PatientImageFavourites.map(function (i) { return PatientImageFavourite_1.PatientImageFavourite_Factory.CreateIncoming(i); }),
            PatientImagingIdentifiers: model.PatientImagingIdentifiers && model.PatientImagingIdentifiers.map(function (i) { return PatientImagingIdentifier_1.PatientImagingIdentifier_Factory.CreateIncoming(i); }),
            PatientLedgerBalances: model.PatientLedgerBalances && model.PatientLedgerBalances.map(function (i) { return PatientLedgerBalance_1.PatientLedgerBalance_Factory.CreateIncoming(i); }),
            PatientMedications: model.PatientMedications && model.PatientMedications.map(function (i) { return PatientMedication_1.PatientMedication_Factory.CreateIncoming(i); }),
            PatientMedicationVersions: model.PatientMedicationVersions && model.PatientMedicationVersions.map(function (i) { return PatientMedicationVersion_1.PatientMedicationVersion_Factory.CreateIncoming(i); }),
            PatientNoteTypes: model.PatientNoteTypes && model.PatientNoteTypes.map(function (i) { return PatientNoteType_1.PatientNoteType_Factory.CreateIncoming(i); }),
            PatientNotificationAlertDismissals: model.PatientNotificationAlertDismissals && model.PatientNotificationAlertDismissals.map(function (i) { return PatientNotificationAlertDismissal_1.PatientNotificationAlertDismissal_Factory.CreateIncoming(i); }),
            PatientNotifications: model.PatientNotifications && model.PatientNotifications.map(function (i) { return PatientNotification_1.PatientNotification_Factory.CreateIncoming(i); }),
            PatientPaymentMethods: model.PatientPaymentMethods && model.PatientPaymentMethods.map(function (i) { return PatientPaymentMethod_1.PatientPaymentMethod_Factory.CreateIncoming(i); }),
            PatientProcedureCPTModifiers: model.PatientProcedureCPTModifiers && model.PatientProcedureCPTModifiers.map(function (i) { return PatientProcedureCPTModifiers_1.PatientProcedureCPTModifiers_Factory.CreateIncoming(i); }),
            PatientProcedureGroups: model.PatientProcedureGroups && model.PatientProcedureGroups.map(function (i) { return PatientProcedureGroup_1.PatientProcedureGroup_Factory.CreateIncoming(i); }),
            PatientProcedureNotes: model.PatientProcedureNotes && model.PatientProcedureNotes.map(function (i) { return PatientProcedureNote_1.PatientProcedureNote_Factory.CreateIncoming(i); }),
            PatientProcedureNoteTypes: model.PatientProcedureNoteTypes && model.PatientProcedureNoteTypes.map(function (i) { return PatientProcedureNoteType_1.PatientProcedureNoteType_Factory.CreateIncoming(i); }),
            PatientProcedures: model.PatientProcedures && model.PatientProcedures.map(function (i) { return PatientProcedure_1.PatientProcedure_Factory.CreateIncoming(i); }),
            PatientRecallCycleUnitTypes: model.PatientRecallCycleUnitTypes && model.PatientRecallCycleUnitTypes.map(function (i) { return PatientRecallCycleUnitType_1.PatientRecallCycleUnitType_Factory.CreateIncoming(i); }),
            PatientRecallInfo: model.PatientRecallInfo && model.PatientRecallInfo.map(function (i) { return PatientRecallInfo_1.PatientRecallInfo_Factory.CreateIncoming(i); }),
            PatientRecallPreferences: model.PatientRecallPreferences && model.PatientRecallPreferences.map(function (i) { return PatientRecallPreference_1.PatientRecallPreference_Factory.CreateIncoming(i); }),
            PatientRecalls: model.PatientRecalls && model.PatientRecalls.map(function (i) { return PatientRecall_1.PatientRecall_Factory.CreateIncoming(i); }),
            PatientResponsibleParties: model.PatientResponsibleParties && model.PatientResponsibleParties.map(function (i) { return PatientResponsibleParty_1.PatientResponsibleParty_Factory.CreateIncoming(i); }),
            PatientRxAuditLogs: model.PatientRxAuditLogs && model.PatientRxAuditLogs.map(function (i) { return PatientRxAuditLog_1.PatientRxAuditLog_Factory.CreateIncoming(i); }),
            PatientRxs: model.PatientRxs && model.PatientRxs.map(function (i) { return PatientRx_1.PatientRx_Factory.CreateIncoming(i); }),
            Patients: model.Patients && model.Patients.map(function (i) { return Patient_1.Patient_Factory.CreateIncoming(i); }),
            PatientsMedicalAlerts: model.PatientsMedicalAlerts && model.PatientsMedicalAlerts.map(function (i) { return PatientsMedicalAlert_1.PatientsMedicalAlert_Factory.CreateIncoming(i); }),
            PatientSnapshots: model.PatientSnapshots && model.PatientSnapshots.map(function (i) { return PatientSnapshot_1.PatientSnapshot_Factory.CreateIncoming(i); }),
            PatientStatements: model.PatientStatements && model.PatientStatements.map(function (i) { return PatientStatement_1.PatientStatement_Factory.CreateIncoming(i); }),
            PatientStatuses: model.PatientStatuses && model.PatientStatuses.map(function (i) { return PatientStatus_1.PatientStatus_Factory.CreateIncoming(i); }),
            PatientTreatmentStatuses: model.PatientTreatmentStatuses && model.PatientTreatmentStatuses.map(function (i) { return PatientTreatmentStatus_1.PatientTreatmentStatus_Factory.CreateIncoming(i); }),
            PatientWaitingListNotes: model.PatientWaitingListNotes && model.PatientWaitingListNotes.map(function (i) { return PatientWaitingListNote_1.PatientWaitingListNote_Factory.CreateIncoming(i); }),
            PatientWaitingListNoteTypes: model.PatientWaitingListNoteTypes && model.PatientWaitingListNoteTypes.map(function (i) { return PatientWaitingListNoteType_1.PatientWaitingListNoteType_Factory.CreateIncoming(i); }),
            PatientWaitingLists: model.PatientWaitingLists && model.PatientWaitingLists.map(function (i) { return PatientWaitingList_1.PatientWaitingList_Factory.CreateIncoming(i); }),
            PatientWaitingListStatusHistory: model.PatientWaitingListStatusHistory && model.PatientWaitingListStatusHistory.map(function (i) { return PatientWaitingListStatusHistory_1.PatientWaitingListStatusHistory_Factory.CreateIncoming(i); }),
            PatientWatches: model.PatientWatches && model.PatientWatches.map(function (i) { return PatientWatch_1.PatientWatch_Factory.CreateIncoming(i); }),
            People: model.People && model.People.map(function (i) { return Person_1.Person_Factory.CreateIncoming(i); }),
            PeopleAdditionalInfoKSA: model.PeopleAdditionalInfoKSA && model.PeopleAdditionalInfoKSA.map(function (i) { return PeopleAdditionalInfoKSA_1.PeopleAdditionalInfoKSA_Factory.CreateIncoming(i); }),
            PerioExams: model.PerioExams && model.PerioExams.map(function (i) { return PerioExam_1.PerioExam_Factory.CreateIncoming(i); }),
            PerioExamSettings: model.PerioExamSettings && model.PerioExamSettings.map(function (i) { return PerioExamSettings_1.PerioExamSettings_Factory.CreateIncoming(i); }),
            PerioProblemThresholds: model.PerioProblemThresholds && model.PerioProblemThresholds.map(function (i) { return PerioProblemThresholds_1.PerioProblemThresholds_Factory.CreateIncoming(i); }),
            PerioRecords: model.PerioRecords && model.PerioRecords.map(function (i) { return PerioRecord_1.PerioRecord_Factory.CreateIncoming(i); }),
            PermissionGroups: model.PermissionGroups && model.PermissionGroups.map(function (i) { return PermissionGroup_1.PermissionGroup_Factory.CreateIncoming(i); }),
            Pharmacies: model.Pharmacies && model.Pharmacies.map(function (i) { return Pharmacy_1.Pharmacy_Factory.CreateIncoming(i); }),
            PhoenixDBConnectionString: model.PhoenixDBConnectionString,
            PhoneNumbers: model.PhoneNumbers && model.PhoneNumbers.map(function (i) { return PhoneNumber_1.PhoneNumber_Factory.CreateIncoming(i); }),
            PhoneNumberTypes: model.PhoneNumberTypes && model.PhoneNumberTypes.map(function (i) { return PhoneNumberType_1.PhoneNumberType_Factory.CreateIncoming(i); }),
            PostOpEvaluations: model.PostOpEvaluations && model.PostOpEvaluations.map(function (i) { return PostOpEvaluation_1.PostOpEvaluation_Factory.CreateIncoming(i); }),
            PracticeEmailAccountTypes: model.PracticeEmailAccountTypes && model.PracticeEmailAccountTypes.map(function (i) { return PracticeEmailAccountType_1.PracticeEmailAccountType_Factory.CreateIncoming(i); }),
            PreferredContactMethodTypes: model.PreferredContactMethodTypes && model.PreferredContactMethodTypes.map(function (i) { return PreferredContactMethodType_1.PreferredContactMethodType_Factory.CreateIncoming(i); }),
            PreOpEvaluations: model.PreOpEvaluations && model.PreOpEvaluations.map(function (i) { return PreOpEvaluation_1.PreOpEvaluation_Factory.CreateIncoming(i); }),
            PreScreenResponses: model.PreScreenResponses && model.PreScreenResponses.map(function (i) { return PreScreenResponse_1.PreScreenResponse_Factory.CreateIncoming(i); }),
            ProcedureCategoryTypes: model.ProcedureCategoryTypes && model.ProcedureCategoryTypes.map(function (i) { return ProcedureCategoryType_1.ProcedureCategoryType_Factory.CreateIncoming(i); }),
            ProcedureDiagnosisCodes: model.ProcedureDiagnosisCodes && model.ProcedureDiagnosisCodes.map(function (i) { return ProcedureDiagnosisCode_1.ProcedureDiagnosisCode_Factory.CreateIncoming(i); }),
            ProcedureGroupings: model.ProcedureGroupings && model.ProcedureGroupings.map(function (i) { return ProcedureGrouping_1.ProcedureGrouping_Factory.CreateIncoming(i); }),
            ProcedureLists: model.ProcedureLists && model.ProcedureLists.map(function (i) { return ProcedureList_1.ProcedureList_Factory.CreateIncoming(i); }),
            Procedures: model.Procedures && model.Procedures.map(function (i) { return Procedure_1.Procedure_Factory.CreateIncoming(i); }),
            ProviderFinancnialActivities: model.ProviderFinancnialActivities && model.ProviderFinancnialActivities.map(function (i) { return ProviderFinancialActivity_1.ProviderFinancialActivity_Factory.CreateIncoming(i); }),
            ProviderTaxonomyCodes: model.ProviderTaxonomyCodes && model.ProviderTaxonomyCodes.map(function (i) { return ProviderTaxonomyCode_1.ProviderTaxonomyCode_Factory.CreateIncoming(i); }),
            QuickbooksDefaultMappings: model.QuickbooksDefaultMappings && model.QuickbooksDefaultMappings.map(function (i) { return QuickbooksDefaultMapping_1.QuickbooksDefaultMapping_Factory.CreateIncoming(i); }),
            QuickbooksLocationDefaultMappings: model.QuickbooksLocationDefaultMappings && model.QuickbooksLocationDefaultMappings.map(function (i) { return QuickbooksLocationDefaultMapping_1.QuickbooksLocationDefaultMapping_Factory.CreateIncoming(i); }),
            QuickbooksLocationPaymentMappings: model.QuickbooksLocationPaymentMappings && model.QuickbooksLocationPaymentMappings.map(function (i) { return QuickbooksLocationPaymentMapping_1.QuickbooksLocationPaymentMapping_Factory.CreateIncoming(i); }),
            QuickbooksPaymentMappings: model.QuickbooksPaymentMappings && model.QuickbooksPaymentMappings.map(function (i) { return QuickbooksPaymentMapping_1.QuickbooksPaymentMapping_Factory.CreateIncoming(i); }),
            QuickbooksSettings: model.QuickbooksSettings && model.QuickbooksSettings.map(function (i) { return QuickbooksSetting_1.QuickbooksSetting_Factory.CreateIncoming(i); }),
            QuickbooksTransmissionHistories: model.QuickbooksTransmissionHistories && model.QuickbooksTransmissionHistories.map(function (i) { return QuickbooksTransmissionHistory_1.QuickbooksTransmissionHistory_Factory.CreateIncoming(i); }),
            QuickbooksTransmissions: model.QuickbooksTransmissions && model.QuickbooksTransmissions.map(function (i) { return QuickbooksTransmission_1.QuickbooksTransmission_Factory.CreateIncoming(i); }),
            RecallDateGroupingsTypes: model.RecallDateGroupingsTypes && model.RecallDateGroupingsTypes.map(function (i) { return RecallDateGroupingsType_1.RecallDateGroupingsType_Factory.CreateIncoming(i); }),
            RecipientTypes: model.RecipientTypes && model.RecipientTypes.map(function (i) { return RecipientType_1.RecipientType_Factory.CreateIncoming(i); }),
            RecordStatuses: model.RecordStatuses && model.RecordStatuses.map(function (i) { return RecordStatus_1.RecordStatus_Factory.CreateIncoming(i); }),
            ReferralFollowUps: model.ReferralFollowUps && model.ReferralFollowUps.map(function (i) { return ReferralFollowUp_1.ReferralFollowUp_Factory.CreateIncoming(i); }),
            Referrals: model.Referrals && model.Referrals.map(function (i) { return Referral_1.Referral_Factory.CreateIncoming(i); }),
            ReferralSourceImplantBrands: model.ReferralSourceImplantBrands && model.ReferralSourceImplantBrands.map(function (i) { return ReferralSourceImplantBrand_1.ReferralSourceImplantBrand_Factory.CreateIncoming(i); }),
            ReferralSourcePracticeNames: model.ReferralSourcePracticeNames && model.ReferralSourcePracticeNames.map(function (i) { return ReferralSourcePracticeName_1.ReferralSourcePracticeName_Factory.CreateIncoming(i); }),
            ReferralSources: model.ReferralSources && model.ReferralSources.map(function (i) { return ReferralSource_1.ReferralSource_Factory.CreateIncoming(i); }),
            ReferralSourceSpecialties: model.ReferralSourceSpecialties && model.ReferralSourceSpecialties.map(function (i) { return ReferralSourceSpecialty_1.ReferralSourceSpecialty_Factory.CreateIncoming(i); }),
            ReferralSourceTransactions: model.ReferralSourceTransactions && model.ReferralSourceTransactions.map(function (i) { return ReferralSourceTransactions_1.ReferralSourceTransactions_Factory.CreateIncoming(i); }),
            ReferralSourceTransactionTypes: model.ReferralSourceTransactionTypes && model.ReferralSourceTransactionTypes.map(function (i) { return ReferralSourceTransactionType_1.ReferralSourceTransactionType_Factory.CreateIncoming(i); }),
            ReferralTypes: model.ReferralTypes && model.ReferralTypes.map(function (i) { return ReferralType_1.ReferralType_Factory.CreateIncoming(i); }),
            RelationshipToPolicyHolderTypes: model.RelationshipToPolicyHolderTypes && model.RelationshipToPolicyHolderTypes.map(function (i) { return RelationshipToPolicyHolderType_1.RelationshipToPolicyHolderType_Factory.CreateIncoming(i); }),
            ReminderPatientDetails: model.ReminderPatientDetails && model.ReminderPatientDetails.map(function (i) { return ReminderPatientDetail_1.ReminderPatientDetail_Factory.CreateIncoming(i); }),
            Reminders: model.Reminders && model.Reminders.map(function (i) { return Reminder_1.Reminder_Factory.CreateIncoming(i); }),
            ReminderUsers: model.ReminderUsers && model.ReminderUsers.map(function (i) { return ReminderUser_1.ReminderUser_Factory.CreateIncoming(i); }),
            ReportCategories: model.ReportCategories && model.ReportCategories.map(function (i) { return ReportCategory_1.ReportCategory_Factory.CreateIncoming(i); }),
            ReportColumns: model.ReportColumns && model.ReportColumns.map(function (i) { return ReportColumn_1.ReportColumn_Factory.CreateIncoming(i); }),
            ReportGenerator_ReportCategory: model.ReportGenerator_ReportCategory && model.ReportGenerator_ReportCategory.map(function (i) { return ReportGeneratorReportCategory_1.ReportGeneratorReportCategory_Factory.CreateIncoming(i); }),
            ReportGenerator_ReportType: model.ReportGenerator_ReportType && model.ReportGenerator_ReportType.map(function (i) { return ReportGeneratorReportType_1.ReportGeneratorReportType_Factory.CreateIncoming(i); }),
            ReportGeneratorColumns: model.ReportGeneratorColumns && model.ReportGeneratorColumns.map(function (i) { return ReportGeneratorColumn_1.ReportGeneratorColumn_Factory.CreateIncoming(i); }),
            ReportGeneratorFilters: model.ReportGeneratorFilters && model.ReportGeneratorFilters.map(function (i) { return ReportGeneratorFilter_1.ReportGeneratorFilter_Factory.CreateIncoming(i); }),
            ReportGroupBys: model.ReportGroupBys && model.ReportGroupBys.map(function (i) { return ReportGroupBy_1.ReportGroupBy_Factory.CreateIncoming(i); }),
            ReportTypeCategoryColumns: model.ReportTypeCategoryColumns && model.ReportTypeCategoryColumns.map(function (i) { return ReportTypeCategoryColumn_1.ReportTypeCategoryColumn_Factory.CreateIncoming(i); }),
            ReportTypeFilters: model.ReportTypeFilters && model.ReportTypeFilters.map(function (i) { return ReportTypeFilter_1.ReportTypeFilter_Factory.CreateIncoming(i); }),
            ReportTypes: model.ReportTypes && model.ReportTypes.map(function (i) { return ReportType_1.ReportType_Factory.CreateIncoming(i); }),
            RequestedReports: model.RequestedReports && model.RequestedReports.map(function (i) { return RequestedReport_1.RequestedReport_Factory.CreateIncoming(i); }),
            RequestedReportStatus: model.RequestedReportStatus && model.RequestedReportStatus.map(function (i) { return RequestedReportStatus_1.RequestedReportStatus_Factory.CreateIncoming(i); }),
            RxDosages: model.RxDosages && model.RxDosages.map(function (i) { return RxDosage_1.RxDosage_Factory.CreateIncoming(i); }),
            RxDrugs: model.RxDrugs && model.RxDrugs.map(function (i) { return RxDrug_1.RxDrug_Factory.CreateIncoming(i); }),
            RxFormulations: model.RxFormulations && model.RxFormulations.map(function (i) { return RxFormulation_1.RxFormulation_Factory.CreateIncoming(i); }),
            RxSigs: model.RxSigs && model.RxSigs.map(function (i) { return RxSig_1.RxSig_Factory.CreateIncoming(i); }),
            RxTemplates: model.RxTemplates && model.RxTemplates.map(function (i) { return RxTemplate_1.RxTemplate_Factory.CreateIncoming(i); }),
            SalutationTypes: model.SalutationTypes && model.SalutationTypes.map(function (i) { return SalutationType_1.SalutationType_Factory.CreateIncoming(i); }),
            SavedReports: model.SavedReports && model.SavedReports.map(function (i) { return SavedReport_1.SavedReport_Factory.CreateIncoming(i); }),
            ScheduledPaymentResults: model.ScheduledPaymentResults && model.ScheduledPaymentResults.map(function (i) { return ScheduledPaymentResult_1.ScheduledPaymentResult_Factory.CreateIncoming(i); }),
            ScheduledPayments: model.ScheduledPayments && model.ScheduledPayments.map(function (i) { return ScheduledPayment_1.ScheduledPayment_Factory.CreateIncoming(i); }),
            ScheduleNotes: model.ScheduleNotes && model.ScheduleNotes.map(function (i) { return ScheduleNote_1.ScheduleNote_Factory.CreateIncoming(i); }),
            ScheduleViewChairs: model.ScheduleViewChairs && model.ScheduleViewChairs.map(function (i) { return ScheduleViewChair_1.ScheduleViewChair_Factory.CreateIncoming(i); }),
            ScheduleViews: model.ScheduleViews && model.ScheduleViews.map(function (i) { return ScheduleView_1.ScheduleView_Factory.CreateIncoming(i); }),
            Schools: model.Schools && model.Schools.map(function (i) { return School_1.School_Factory.CreateIncoming(i); }),
            ServiceLocationInsuranceClaims: model.ServiceLocationInsuranceClaims && model.ServiceLocationInsuranceClaims.map(function (i) { return ServiceLocationInsuranceClaim_1.ServiceLocationInsuranceClaim_Factory.CreateIncoming(i); }),
            ServiceLocations: model.ServiceLocations && model.ServiceLocations.map(function (i) { return ServiceLocation_1.ServiceLocation_Factory.CreateIncoming(i); }),
            ServiceLocationTransactions: model.ServiceLocationTransactions && model.ServiceLocationTransactions.map(function (i) { return ServiceLocationTransaction_1.ServiceLocationTransaction_Factory.CreateIncoming(i); }),
            ServiceSchemes: model.ServiceSchemes && model.ServiceSchemes.map(function (i) { return ServiceScheme_1.ServiceScheme_Factory.CreateIncoming(i); }),
            ServiceSchemeTypes: model.ServiceSchemeTypes && model.ServiceSchemeTypes.map(function (i) { return ServiceSchemeType_1.ServiceSchemeType_Factory.CreateIncoming(i); }),
            SimpleStringContainers: model.SimpleStringContainers && model.SimpleStringContainers.map(function (i) { return SimpleStringContainer_1.SimpleStringContainer_Factory.CreateIncoming(i); }),
            SmsApptConfirmationCodes: model.SmsApptConfirmationCodes && model.SmsApptConfirmationCodes.map(function (i) { return SmsApptConfirmationCode_1.SmsApptConfirmationCode_Factory.CreateIncoming(i); }),
            SmsApptReminders: model.SmsApptReminders && model.SmsApptReminders.map(function (i) { return SmsApptReminder_1.SmsApptReminder_Factory.CreateIncoming(i); }),
            SmsMessages: model.SmsMessages && model.SmsMessages.map(function (i) { return SmsMessage_1.SmsMessage_Factory.CreateIncoming(i); }),
            SmsOptOuts: model.SmsOptOuts && model.SmsOptOuts.map(function (i) { return SmsOptOut_1.SmsOptOut_Factory.CreateIncoming(i); }),
            SmsSettings: model.SmsSettings && model.SmsSettings.map(function (i) { return SmsSetting_1.SmsSetting_Factory.CreateIncoming(i); }),
            SoftTissueConditions: model.SoftTissueConditions && model.SoftTissueConditions.map(function (i) { return SoftTissueCondition_1.SoftTissueCondition_Factory.CreateIncoming(i); }),
            SoftTissueConditionTypes: model.SoftTissueConditionTypes && model.SoftTissueConditionTypes.map(function (i) { return SoftTissueConditionType_1.SoftTissueConditionType_Factory.CreateIncoming(i); }),
            SoftTissueExamFindings: model.SoftTissueExamFindings && model.SoftTissueExamFindings.map(function (i) { return SoftTissueExamFinding_1.SoftTissueExamFinding_Factory.CreateIncoming(i); }),
            SoftTissueExams: model.SoftTissueExams && model.SoftTissueExams.map(function (i) { return SoftTissueExam_1.SoftTissueExam_Factory.CreateIncoming(i); }),
            SoftTissueRegions: model.SoftTissueRegions && model.SoftTissueRegions.map(function (i) { return SoftTissueRegion_1.SoftTissueRegion_Factory.CreateIncoming(i); }),
            SplitTransactions: model.SplitTransactions && model.SplitTransactions.map(function (i) { return SplitTransaction_1.SplitTransaction_Factory.CreateIncoming(i); }),
            StatesProvinces: model.StatesProvinces && model.StatesProvinces.map(function (i) { return StatesProvince_1.StatesProvince_Factory.CreateIncoming(i); }),
            SurgeryDrugDosageTypes: model.SurgeryDrugDosageTypes && model.SurgeryDrugDosageTypes.map(function (i) { return SurgeryDrugDosageType_1.SurgeryDrugDosageType_Factory.CreateIncoming(i); }),
            SurgeryDrugRecordings: model.SurgeryDrugRecordings && model.SurgeryDrugRecordings.map(function (i) { return SurgeryDrugRecording_1.SurgeryDrugRecording_Factory.CreateIncoming(i); }),
            SurgeryDrugs: model.SurgeryDrugs && model.SurgeryDrugs.map(function (i) { return SurgeryDrug_1.SurgeryDrug_Factory.CreateIncoming(i); }),
            SurgeryEventRecordings: model.SurgeryEventRecordings && model.SurgeryEventRecordings.map(function (i) { return SurgeryEventRecording_1.SurgeryEventRecording_Factory.CreateIncoming(i); }),
            SurgeryEventTypes: model.SurgeryEventTypes && model.SurgeryEventTypes.map(function (i) { return SurgeryEventType_1.SurgeryEventType_Factory.CreateIncoming(i); }),
            SurgeryGases: model.SurgeryGases && model.SurgeryGases.map(function (i) { return SurgeryGas_1.SurgeryGas_Factory.CreateIncoming(i); }),
            SurgeryGasRecordings: model.SurgeryGasRecordings && model.SurgeryGasRecordings.map(function (i) { return SurgeryGasRecording_1.SurgeryGasRecording_Factory.CreateIncoming(i); }),
            SurgeryInfos: model.SurgeryInfos && model.SurgeryInfos.map(function (i) { return SurgeryInfo_1.SurgeryInfo_Factory.CreateIncoming(i); }),
            SurgeryRecordings: model.SurgeryRecordings && model.SurgeryRecordings.map(function (i) { return SurgeryRecording_1.SurgeryRecording_Factory.CreateIncoming(i); }),
            SurgeryVitals: model.SurgeryVitals && model.SurgeryVitals.map(function (i) { return SurgeryVitals_1.SurgeryVitals_Factory.CreateIncoming(i); }),
            SurgeryVitalsRecordingTimes: model.SurgeryVitalsRecordingTimes && model.SurgeryVitalsRecordingTimes.map(function (i) { return SurgeryVitalsRecordingTime_1.SurgeryVitalsRecordingTime_Factory.CreateIncoming(i); }),
            Tags: model.Tags && model.Tags.map(function (i) { return Tag_1.Tag_Factory.CreateIncoming(i); }),
            Teeth: model.Teeth && model.Teeth.map(function (i) { return Tooth_1.Tooth_Factory.CreateIncoming(i); }),
            TenantSettings: model.TenantSettings && model.TenantSettings.map(function (i) { return TenantSetting_1.TenantSetting_Factory.CreateIncoming(i); }),
            TimeLocks: model.TimeLocks && model.TimeLocks.map(function (i) { return TimeLock_1.TimeLock_Factory.CreateIncoming(i); }),
            TimeLocksChairs: model.TimeLocksChairs && model.TimeLocksChairs.map(function (i) { return TimeLocksChair_1.TimeLocksChair_Factory.CreateIncoming(i); }),
            TimeZones: model.TimeZones && model.TimeZones.map(function (i) { return TimeZone_1.TimeZone_Factory.CreateIncoming(i); }),
            ToothNumberingTypes: model.ToothNumberingTypes && model.ToothNumberingTypes.map(function (i) { return ToothNumberingType_1.ToothNumberingType_Factory.CreateIncoming(i); }),
            ToothRootMaskValues: model.ToothRootMaskValues && model.ToothRootMaskValues.map(function (i) { return ToothRootMaskValue_1.ToothRootMaskValue_Factory.CreateIncoming(i); }),
            ToothSurfaceMaskValues: model.ToothSurfaceMaskValues && model.ToothSurfaceMaskValues.map(function (i) { return ToothSurfaceMaskValue_1.ToothSurfaceMaskValue_Factory.CreateIncoming(i); }),
            ToothSurfaceNumberTypes: model.ToothSurfaceNumberTypes && model.ToothSurfaceNumberTypes.map(function (i) { return ToothSurfaceNumberType_1.ToothSurfaceNumberType_Factory.CreateIncoming(i); }),
            ToothSurfaceTypes: model.ToothSurfaceTypes && model.ToothSurfaceTypes.map(function (i) { return ToothSurfaceType_1.ToothSurfaceType_Factory.CreateIncoming(i); }),
            TransactionAdjustments: model.TransactionAdjustments && model.TransactionAdjustments.map(function (i) { return TransactionAdjustment_1.TransactionAdjustment_Factory.CreateIncoming(i); }),
            TransactionPendingSplits: model.TransactionPendingSplits && model.TransactionPendingSplits.map(function (i) { return TransactionPendingSplit_1.TransactionPendingSplit_Factory.CreateIncoming(i); }),
            Transactions: model.Transactions && model.Transactions.map(function (i) { return Transaction_1.Transaction_Factory.CreateIncoming(i); }),
            TransactionSubTypes: model.TransactionSubTypes && model.TransactionSubTypes.map(function (i) { return TransactionSubType_1.TransactionSubType_Factory.CreateIncoming(i); }),
            TransactionTypes: model.TransactionTypes && model.TransactionTypes.map(function (i) { return TransactionType_1.TransactionType_Factory.CreateIncoming(i); }),
            TreatmentModalityTypes: model.TreatmentModalityTypes && model.TreatmentModalityTypes.map(function (i) { return TreatmentModalityType_1.TreatmentModalityType_Factory.CreateIncoming(i); }),
            TreatmentPlanNotes: model.TreatmentPlanNotes && model.TreatmentPlanNotes.map(function (i) { return TreatmentPlanNote_1.TreatmentPlanNote_Factory.CreateIncoming(i); }),
            TreatmentPlanNoteTypes: model.TreatmentPlanNoteTypes && model.TreatmentPlanNoteTypes.map(function (i) { return TreatmentPlanNoteType_1.TreatmentPlanNoteType_Factory.CreateIncoming(i); }),
            TreatmentPlanProcedureGroups: model.TreatmentPlanProcedureGroups && model.TreatmentPlanProcedureGroups.map(function (i) { return TreatmentPlanProcedureGroup_1.TreatmentPlanProcedureGroup_Factory.CreateIncoming(i); }),
            TreatmentPlans: model.TreatmentPlans && model.TreatmentPlans.map(function (i) { return TreatmentPlan_1.TreatmentPlan_Factory.CreateIncoming(i); }),
            TreatmentPlanStatus: model.TreatmentPlanStatus && model.TreatmentPlanStatus.map(function (i) { return TreatmentPlanStatus_1.TreatmentPlanStatus_Factory.CreateIncoming(i); }),
            TreatmentStatuses: model.TreatmentStatuses && model.TreatmentStatuses.map(function (i) { return TreatmentStatus_1.TreatmentStatus_Factory.CreateIncoming(i); }),
            TreatmentSubTypes: model.TreatmentSubTypes && model.TreatmentSubTypes.map(function (i) { return TreatmentSubType_1.TreatmentSubType_Factory.CreateIncoming(i); }),
            UserAdditionalInfoKSA: model.UserAdditionalInfoKSA && model.UserAdditionalInfoKSA.map(function (i) { return UserAdditionalInfoKSA_1.UserAdditionalInfoKSA_Factory.CreateIncoming(i); }),
            UserChairMappings: model.UserChairMappings && model.UserChairMappings.map(function (i) { return UserChairMapping_1.UserChairMapping_Factory.CreateIncoming(i); }),
            UserCredentials: model.UserCredentials && model.UserCredentials.map(function (i) { return UserCredentials_1.UserCredentials_Factory.CreateIncoming(i); }),
            UserCredentialTypes: model.UserCredentialTypes && model.UserCredentialTypes.map(function (i) { return UserCredentialType_1.UserCredentialType_Factory.CreateIncoming(i); }),
            UserDefaultWorkingDays: model.UserDefaultWorkingDays && model.UserDefaultWorkingDays.map(function (i) { return UserDefaultWorkingDay_1.UserDefaultWorkingDay_Factory.CreateIncoming(i); }),
            UserLoginHistories: model.UserLoginHistories && model.UserLoginHistories.map(function (i) { return UserLoginHistory_1.UserLoginHistory_Factory.CreateIncoming(i); }),
            UserNotifications: model.UserNotifications && model.UserNotifications.map(function (i) { return UserNotification_1.UserNotification_Factory.CreateIncoming(i); }),
            UserPermissions: model.UserPermissions && model.UserPermissions.map(function (i) { return UserPermission_1.UserPermission_Factory.CreateIncoming(i); }),
            UserRegistrations: model.UserRegistrations && model.UserRegistrations.map(function (i) { return UserRegistration_1.UserRegistration_Factory.CreateIncoming(i); }),
            UserRegistrationTypes: model.UserRegistrationTypes && model.UserRegistrationTypes.map(function (i) { return UserRegistrationType_1.UserRegistrationType_Factory.CreateIncoming(i); }),
            UserReportFavourites: model.UserReportFavourites && model.UserReportFavourites.map(function (i) { return UserReportFavourite_1.UserReportFavourite_Factory.CreateIncoming(i); }),
            UserRoleTypes: model.UserRoleTypes && model.UserRoleTypes.map(function (i) { return UserRoleType_1.UserRoleType_Factory.CreateIncoming(i); }),
            Users: model.Users && model.Users.map(function (i) { return User_1.User_Factory.CreateIncoming(i); }),
            UserSettings: model.UserSettings && model.UserSettings.map(function (i) { return UserSetting_1.UserSetting_Factory.CreateIncoming(i); }),
            UserTimecardOverrides: model.UserTimecardOverrides && model.UserTimecardOverrides.map(function (i) { return UserTimecardOverride_1.UserTimecardOverride_Factory.CreateIncoming(i); }),
            UserTimecards: model.UserTimecards && model.UserTimecards.map(function (i) { return UserTimecard_1.UserTimecard_Factory.CreateIncoming(i); }),
            UserWorkingDayChairs: model.UserWorkingDayChairs && model.UserWorkingDayChairs.map(function (i) { return UserWorkingDayChair_1.UserWorkingDayChair_Factory.CreateIncoming(i); }),
            UserWorkingDayChairTimes: model.UserWorkingDayChairTimes && model.UserWorkingDayChairTimes.map(function (i) { return UserWorkingDayChairTime_1.UserWorkingDayChairTime_Factory.CreateIncoming(i); }),
            UserWorkingDays: model.UserWorkingDays && model.UserWorkingDays.map(function (i) { return UserWorkingDay_1.UserWorkingDay_Factory.CreateIncoming(i); }),
            UserWorkingDayTimes: model.UserWorkingDayTimes && model.UserWorkingDayTimes.map(function (i) { return UserWorkingDayTime_1.UserWorkingDayTime_Factory.CreateIncoming(i); }),
            VitalsRecords: model.VitalsRecords && model.VitalsRecords.map(function (i) { return VitalsRecord_1.VitalsRecord_Factory.CreateIncoming(i); }),
            VitalsRecordTypes: model.VitalsRecordTypes && model.VitalsRecordTypes.map(function (i) { return VitalsRecordType_1.VitalsRecordType_Factory.CreateIncoming(i); }),
            VitalsRecordVersions: model.VitalsRecordVersions && model.VitalsRecordVersions.map(function (i) { return VitalsRecordVersion_1.VitalsRecordVersion_Factory.CreateIncoming(i); }),
            WaitingLists: model.WaitingLists && model.WaitingLists.map(function (i) { return WaitingList_1.WaitingList_Factory.CreateIncoming(i); }),
        };
        return result;
    };
    PhoenixModel_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Addresses: model.Addresses && model.Addresses.map(function (i) { return Address_1.Address_Factory.CreateOutgoing(i); }),
            AddressTypes: model.AddressTypes && model.AddressTypes.map(function (i) { return AddressType_1.AddressType_Factory.CreateOutgoing(i); }),
            AlignerBrands: model.AlignerBrands && model.AlignerBrands.map(function (i) { return AlignerBrand_1.AlignerBrand_Factory.CreateOutgoing(i); }),
            AlignerTrackingPlans: model.AlignerTrackingPlans && model.AlignerTrackingPlans.map(function (i) { return AlignerTrackingPlan_1.AlignerTrackingPlan_Factory.CreateOutgoing(i); }),
            AlignerTrayPlans: model.AlignerTrayPlans && model.AlignerTrayPlans.map(function (i) { return AlignerTrayPlan_1.AlignerTrayPlan_Factory.CreateOutgoing(i); }),
            AlignerTrayUsageTypes: model.AlignerTrayUsageTypes && model.AlignerTrayUsageTypes.map(function (i) { return AlignerTrayUsageType_1.AlignerTrayUsageType_Factory.CreateOutgoing(i); }),
            AllergyTypes: model.AllergyTypes && model.AllergyTypes.map(function (i) { return AllergyType_1.AllergyType_Factory.CreateOutgoing(i); }),
            AllowanceTables: model.AllowanceTables && model.AllowanceTables.map(function (i) { return AllowanceTable_1.AllowanceTable_Factory.CreateOutgoing(i); }),
            AmbulatingTypes: model.AmbulatingTypes && model.AmbulatingTypes.map(function (i) { return AmbulatingType_1.AmbulatingType_Factory.CreateOutgoing(i); }),
            AnesthesiaRecords: model.AnesthesiaRecords && model.AnesthesiaRecords.map(function (i) { return AnesthesiaRecord_1.AnesthesiaRecord_Factory.CreateOutgoing(i); }),
            Appliances: model.Appliances && model.Appliances.map(function (i) { return Appliance_1.Appliance_Factory.CreateOutgoing(i); }),
            ApplianceTypes: model.ApplianceTypes && model.ApplianceTypes.map(function (i) { return ApplianceType_1.ApplianceType_Factory.CreateOutgoing(i); }),
            AppointmentAuditLogs: model.AppointmentAuditLogs && model.AppointmentAuditLogs.map(function (i) { return AppointmentAuditLog_1.AppointmentAuditLog_Factory.CreateOutgoing(i); }),
            AppointmentNotes: model.AppointmentNotes && model.AppointmentNotes.map(function (i) { return AppointmentNote_1.AppointmentNote_Factory.CreateOutgoing(i); }),
            AppointmentNoteTypes: model.AppointmentNoteTypes && model.AppointmentNoteTypes.map(function (i) { return AppointmentNoteType_1.AppointmentNoteType_Factory.CreateOutgoing(i); }),
            Appointments: model.Appointments && model.Appointments.map(function (i) { return Appointment_1.Appointment_Factory.CreateOutgoing(i); }),
            AppointmentStatusCodes: model.AppointmentStatusCodes && model.AppointmentStatusCodes.map(function (i) { return AppointmentStatusCode_1.AppointmentStatusCode_Factory.CreateOutgoing(i); }),
            AppointmentTypes: model.AppointmentTypes && model.AppointmentTypes.map(function (i) { return AppointmentType_1.AppointmentType_Factory.CreateOutgoing(i); }),
            ArchiveAccessRequests: model.ArchiveAccessRequests && model.ArchiveAccessRequests.map(function (i) { return ArchiveAccessRequest_1.ArchiveAccessRequest_Factory.CreateOutgoing(i); }),
            ArchivedDaysheets: model.ArchivedDaysheets && model.ArchivedDaysheets.map(function (i) { return ArchivedDaysheet_1.ArchivedDaysheet_Factory.CreateOutgoing(i); }),
            ArchTypes: model.ArchTypes && model.ArchTypes.map(function (i) { return ArchType_1.ArchType_Factory.CreateOutgoing(i); }),
            Archwires: model.Archwires && model.Archwires.map(function (i) { return Archwire_1.Archwire_Factory.CreateOutgoing(i); }),
            ArchwireTypes: model.ArchwireTypes && model.ArchwireTypes.map(function (i) { return ArchwireType_1.ArchwireType_Factory.CreateOutgoing(i); }),
            BalanceAdjustments: model.BalanceAdjustments && model.BalanceAdjustments.map(function (i) { return BalanceAdjustment_1.BalanceAdjustment_Factory.CreateOutgoing(i); }),
            BlockBookingTemplateDates: model.BlockBookingTemplateDates && model.BlockBookingTemplateDates.map(function (i) { return BlockBookingTemplateDate_1.BlockBookingTemplateDate_Factory.CreateOutgoing(i); }),
            BlockBookingTemplates: model.BlockBookingTemplates && model.BlockBookingTemplates.map(function (i) { return BlockBookingTemplate_1.BlockBookingTemplate_Factory.CreateOutgoing(i); }),
            BookingTemplateBlocks: model.BookingTemplateBlocks && model.BookingTemplateBlocks.map(function (i) { return BookingTemplateBlock_1.BookingTemplateBlock_Factory.CreateOutgoing(i); }),
            BoxFileMigrationLogs: model.BoxFileMigrationLogs && model.BoxFileMigrationLogs.map(function (i) { return BoxFileMigrationLog_1.BoxFileMigrationLog_Factory.CreateOutgoing(i); }),
            BPEExams: model.BPEExams && model.BPEExams.map(function (i) { return BPEExam_1.BPEExam_Factory.CreateOutgoing(i); }),
            Brackets: model.Brackets && model.Brackets.map(function (i) { return Bracket_1.Bracket_Factory.CreateOutgoing(i); }),
            BracketTypes: model.BracketTypes && model.BracketTypes.map(function (i) { return BracketType_1.BracketType_Factory.CreateOutgoing(i); }),
            BulkCorrespondenceBatches: model.BulkCorrespondenceBatches && model.BulkCorrespondenceBatches.map(function (i) { return BulkCorrespondenceBatch_1.BulkCorrespondenceBatch_Factory.CreateOutgoing(i); }),
            BulkCorrespondencePatientInstances: model.BulkCorrespondencePatientInstances && model.BulkCorrespondencePatientInstances.map(function (i) { return BulkCorrespondencePatientInstance_1.BulkCorrespondencePatientInstance_Factory.CreateOutgoing(i); }),
            BulkInsurancePaymentAdjustments: model.BulkInsurancePaymentAdjustments && model.BulkInsurancePaymentAdjustments.map(function (i) { return BulkInsurancePaymentAdjustment_1.BulkInsurancePaymentAdjustment_Factory.CreateOutgoing(i); }),
            BulkInsurancePaymentClaims: model.BulkInsurancePaymentClaims && model.BulkInsurancePaymentClaims.map(function (i) { return BulkInsurancePaymentClaim_1.BulkInsurancePaymentClaim_Factory.CreateOutgoing(i); }),
            BulkInsurancePayments: model.BulkInsurancePayments && model.BulkInsurancePayments.map(function (i) { return BulkInsurancePayment_1.BulkInsurancePayment_Factory.CreateOutgoing(i); }),
            BulkStatementBatches: model.BulkStatementBatches && model.BulkStatementBatches.map(function (i) { return BulkStatementBatch_1.BulkStatementBatch_Factory.CreateOutgoing(i); }),
            BulkStatementBatchGuarantorPatients: model.BulkStatementBatchGuarantorPatients && model.BulkStatementBatchGuarantorPatients.map(function (i) { return BulkStatementBatchGuarantorPatient_1.BulkStatementBatchGuarantorPatient_Factory.CreateOutgoing(i); }),
            BulkStatementBatchGuarantors: model.BulkStatementBatchGuarantors && model.BulkStatementBatchGuarantors.map(function (i) { return BulkStatementBatchGuarantor_1.BulkStatementBatchGuarantor_Factory.CreateOutgoing(i); }),
            BulkStatementBatchPatients: model.BulkStatementBatchPatients && model.BulkStatementBatchPatients.map(function (i) { return BulkStatementBatchPatient_1.BulkStatementBatchPatient_Factory.CreateOutgoing(i); }),
            CDTCodeCategoryTypes: model.CDTCodeCategoryTypes && model.CDTCodeCategoryTypes.map(function (i) { return CDTCodeCategoryType_1.CDTCodeCategoryType_Factory.CreateOutgoing(i); }),
            CDTCodes: model.CDTCodes && model.CDTCodes.map(function (i) { return CDTCode_1.CDTCode_Factory.CreateOutgoing(i); }),
            Chairs: model.Chairs && model.Chairs.map(function (i) { return Chair_1.Chair_Factory.CreateOutgoing(i); }),
            ClinicalEventConditionCodes: model.ClinicalEventConditionCodes && model.ClinicalEventConditionCodes.map(function (i) { return ClinicalEventConditionCode_1.ClinicalEventConditionCode_Factory.CreateOutgoing(i); }),
            ClinicalEventGroups: model.ClinicalEventGroups && model.ClinicalEventGroups.map(function (i) { return ClinicalEventGroup_1.ClinicalEventGroup_Factory.CreateOutgoing(i); }),
            ClinicalEvents: model.ClinicalEvents && model.ClinicalEvents.map(function (i) { return ClinicalEvent_1.ClinicalEvent_Factory.CreateOutgoing(i); }),
            ClinicalEventWatchDetails: model.ClinicalEventWatchDetails && model.ClinicalEventWatchDetails.map(function (i) { return ClinicalEventWatchDetail_1.ClinicalEventWatchDetail_Factory.CreateOutgoing(i); }),
            ClinicalNotes: model.ClinicalNotes && model.ClinicalNotes.map(function (i) { return ClinicalNote_1.ClinicalNote_Factory.CreateOutgoing(i); }),
            ClinicalTreatmentMaterials: model.ClinicalTreatmentMaterials && model.ClinicalTreatmentMaterials.map(function (i) { return ClinicalTreatmentMaterial_1.ClinicalTreatmentMaterial_Factory.CreateOutgoing(i); }),
            CollectionAllocations: model.CollectionAllocations && model.CollectionAllocations.map(function (i) { return CollectionAllocation_1.CollectionAllocation_Factory.CreateOutgoing(i); }),
            CompletedMedicalQuestionnaireAnswers: model.CompletedMedicalQuestionnaireAnswers && model.CompletedMedicalQuestionnaireAnswers.map(function (i) { return CompletedMedicalQuestionnaireAnswer_1.CompletedMedicalQuestionnaireAnswer_Factory.CreateOutgoing(i); }),
            CompletedMedicalQuestionnaires: model.CompletedMedicalQuestionnaires && model.CompletedMedicalQuestionnaires.map(function (i) { return CompletedMedicalQuestionnaire_1.CompletedMedicalQuestionnaire_Factory.CreateOutgoing(i); }),
            ConcernTypes: model.ConcernTypes && model.ConcernTypes.map(function (i) { return ConcernType_1.ConcernType_Factory.CreateOutgoing(i); }),
            ConnectedDevices: model.ConnectedDevices && model.ConnectedDevices.map(function (i) { return ConnectedDevice_1.ConnectedDevice_Factory.CreateOutgoing(i); }),
            ConnectedMonitors: model.ConnectedMonitors && model.ConnectedMonitors.map(function (i) { return ConnectedMonitor_1.ConnectedMonitor_Factory.CreateOutgoing(i); }),
            ContactInfoTypes: model.ContactInfoTypes && model.ContactInfoTypes.map(function (i) { return ContactInfoType_1.ContactInfoType_Factory.CreateOutgoing(i); }),
            ControlNumbers: model.ControlNumbers && model.ControlNumbers.map(function (i) { return ControlNumber_1.ControlNumber_Factory.CreateOutgoing(i); }),
            ConversionNotes: model.ConversionNotes && model.ConversionNotes.map(function (i) { return ConversionNote_1.ConversionNote_Factory.CreateOutgoing(i); }),
            CorrespondenceLetterPurposeTypes: model.CorrespondenceLetterPurposeTypes && model.CorrespondenceLetterPurposeTypes.map(function (i) { return CorrespondenceLetterPurposeType_1.CorrespondenceLetterPurposeType_Factory.CreateOutgoing(i); }),
            CorrespondenceLetters: model.CorrespondenceLetters && model.CorrespondenceLetters.map(function (i) { return CorrespondenceLetter_1.CorrespondenceLetter_Factory.CreateOutgoing(i); }),
            CorrespondenceMergeFieldGroups: model.CorrespondenceMergeFieldGroups && model.CorrespondenceMergeFieldGroups.map(function (i) { return CorrespondenceMergeFieldGroup_1.CorrespondenceMergeFieldGroup_Factory.CreateOutgoing(i); }),
            CorrespondenceMergeFields: model.CorrespondenceMergeFields && model.CorrespondenceMergeFields.map(function (i) { return CorrespondenceMergeField_1.CorrespondenceMergeField_Factory.CreateOutgoing(i); }),
            CorrespondenceTemplateAddresseeTypes: model.CorrespondenceTemplateAddresseeTypes && model.CorrespondenceTemplateAddresseeTypes.map(function (i) { return CorrespondenceTemplateAddresseeType_1.CorrespondenceTemplateAddresseeType_Factory.CreateOutgoing(i); }),
            CorrespondenceTemplateCategories: model.CorrespondenceTemplateCategories && model.CorrespondenceTemplateCategories.map(function (i) { return CorrespondenceTemplateCategory_1.CorrespondenceTemplateCategory_Factory.CreateOutgoing(i); }),
            CorrespondenceTemplateContents: model.CorrespondenceTemplateContents && model.CorrespondenceTemplateContents.map(function (i) { return CorrespondenceTemplateContent_1.CorrespondenceTemplateContent_Factory.CreateOutgoing(i); }),
            CorrespondenceTemplateContentTypes: model.CorrespondenceTemplateContentTypes && model.CorrespondenceTemplateContentTypes.map(function (i) { return CorrespondenceTemplateContentType_1.CorrespondenceTemplateContentType_Factory.CreateOutgoing(i); }),
            CorrespondenceTemplateFormatTypes: model.CorrespondenceTemplateFormatTypes && model.CorrespondenceTemplateFormatTypes.map(function (i) { return CorrespondenceTemplateFormatType_1.CorrespondenceTemplateFormatType_Factory.CreateOutgoing(i); }),
            CorrespondenceTemplates: model.CorrespondenceTemplates && model.CorrespondenceTemplates.map(function (i) { return CorrespondenceTemplate_1.CorrespondenceTemplate_Factory.CreateOutgoing(i); }),
            Countries: model.Countries && model.Countries.map(function (i) { return Country_1.Country_Factory.CreateOutgoing(i); }),
            CPTCodes: model.CPTCodes && model.CPTCodes.map(function (i) { return CPTCode_1.CPTCode_Factory.CreateOutgoing(i); }),
            CPTModifiers: model.CPTModifiers && model.CPTModifiers.map(function (i) { return CPTModifier_1.CPTModifier_Factory.CreateOutgoing(i); }),
            DataExportTemplateMergeFields: model.DataExportTemplateMergeFields && model.DataExportTemplateMergeFields.map(function (i) { return DataExportTemplateMergeField_1.DataExportTemplateMergeField_Factory.CreateOutgoing(i); }),
            DataExportTemplates: model.DataExportTemplates && model.DataExportTemplates.map(function (i) { return DataExportTemplate_1.DataExportTemplate_Factory.CreateOutgoing(i); }),
            DaysheetTransactions: model.DaysheetTransactions && model.DaysheetTransactions.map(function (i) { return DaysheetTransaction_1.DaysheetTransaction_Factory.CreateOutgoing(i); }),
            DBScriptsLogs: model.DBScriptsLogs && model.DBScriptsLogs.map(function (i) { return DBScriptsLog_1.DBScriptsLog_Factory.CreateOutgoing(i); }),
            DefaultRecallSettings: model.DefaultRecallSettings && model.DefaultRecallSettings.map(function (i) { return DefaultRecallSetting_1.DefaultRecallSetting_Factory.CreateOutgoing(i); }),
            ECGSnapshots: model.ECGSnapshots && model.ECGSnapshots.map(function (i) { return ECGSnapshot_1.ECGSnapshot_Factory.CreateOutgoing(i); }),
            EFormAttachments: model.EFormAttachments && model.EFormAttachments.map(function (i) { return EFormsAttachment_1.EFormsAttachment_Factory.CreateOutgoing(i); }),
            EFormAttachmentTypes: model.EFormAttachmentTypes && model.EFormAttachmentTypes.map(function (i) { return EFormsAttachmentType_1.EFormsAttachmentType_Factory.CreateOutgoing(i); }),
            EFormInstances: model.EFormInstances && model.EFormInstances.map(function (i) { return EFormsInstance_1.EFormsInstance_Factory.CreateOutgoing(i); }),
            EFormsBinaryElementDetails: model.EFormsBinaryElementDetails && model.EFormsBinaryElementDetails.map(function (i) { return EFormsBinaryElementDetail_1.EFormsBinaryElementDetail_Factory.CreateOutgoing(i); }),
            EFormsDataElementCategories: model.EFormsDataElementCategories && model.EFormsDataElementCategories.map(function (i) { return EFormsDataElementCategory_1.EFormsDataElementCategory_Factory.CreateOutgoing(i); }),
            EFormsDataElementOptionTypes: model.EFormsDataElementOptionTypes && model.EFormsDataElementOptionTypes.map(function (i) { return EFormsDataElementOptionType_1.EFormsDataElementOptionType_Factory.CreateOutgoing(i); }),
            EFormsDataElements: model.EFormsDataElements && model.EFormsDataElements.map(function (i) { return EFormsDataElement_1.EFormsDataElement_Factory.CreateOutgoing(i); }),
            EFormsDataElementTypes: model.EFormsDataElementTypes && model.EFormsDataElementTypes.map(function (i) { return EFormsDataElementType_1.EFormsDataElementType_Factory.CreateOutgoing(i); }),
            EFormsInstanceDetails: model.EFormsInstanceDetails && model.EFormsInstanceDetails.map(function (i) { return EFormsInstanceDetail_1.EFormsInstanceDetail_Factory.CreateOutgoing(i); }),
            EFormsInstanceHistoryLogs: model.EFormsInstanceHistoryLogs && model.EFormsInstanceHistoryLogs.map(function (i) { return EFormsInstanceHistoryLog_1.EFormsInstanceHistoryLog_Factory.CreateOutgoing(i); }),
            EFormsInstancesView: model.EFormsInstancesView && model.EFormsInstancesView.map(function (i) { return EFormsInstanceView_1.EFormsInstanceView_Factory.CreateOutgoing(i); }),
            EFormsLocationSettings: model.EFormsLocationSettings && model.EFormsLocationSettings.map(function (i) { return EFormsLocationSettings_1.EFormsLocationSettings_Factory.CreateOutgoing(i); }),
            EFormsNumericElementDetails: model.EFormsNumericElementDetails && model.EFormsNumericElementDetails.map(function (i) { return EFormsNumericElementDetail_1.EFormsNumericElementDetail_Factory.CreateOutgoing(i); }),
            EFormsSelectElementOptions: model.EFormsSelectElementOptions && model.EFormsSelectElementOptions.map(function (i) { return EFormsSelectElementOption_1.EFormsSelectElementOption_Factory.CreateOutgoing(i); }),
            EFormsSourceTypes: model.EFormsSourceTypes && model.EFormsSourceTypes.map(function (i) { return EFormsSourceType_1.EFormsSourceType_Factory.CreateOutgoing(i); }),
            EFormsStatusTrackings: model.EFormsStatusTrackings && model.EFormsStatusTrackings.map(function (i) { return EFormsStatusTracking_1.EFormsStatusTracking_Factory.CreateOutgoing(i); }),
            EFormStatuses: model.EFormStatuses && model.EFormStatuses.map(function (i) { return EFormsStatus_1.EFormsStatus_Factory.CreateOutgoing(i); }),
            EFormsTemplateCategories: model.EFormsTemplateCategories && model.EFormsTemplateCategories.map(function (i) { return EFormsTemplateCategory_1.EFormsTemplateCategory_Factory.CreateOutgoing(i); }),
            EFormsTemplateDataElements: model.EFormsTemplateDataElements && model.EFormsTemplateDataElements.map(function (i) { return EFormsTemplateDataElement_1.EFormsTemplateDataElement_Factory.CreateOutgoing(i); }),
            EFormsTemplateDetails: model.EFormsTemplateDetails && model.EFormsTemplateDetails.map(function (i) { return EFormsTemplateDetail_1.EFormsTemplateDetail_Factory.CreateOutgoing(i); }),
            EFormsToothChartElementDentitionTypes: model.EFormsToothChartElementDentitionTypes && model.EFormsToothChartElementDentitionTypes.map(function (i) { return EFormsToothChartElementDentitionType_1.EFormsToothChartElementDentitionType_Factory.CreateOutgoing(i); }),
            EFormsToothChartElementDetails: model.EFormsToothChartElementDetails && model.EFormsToothChartElementDetails.map(function (i) { return EFormsToothChartElementDetail_1.EFormsToothChartElementDetail_Factory.CreateOutgoing(i); }),
            EFormsToothChartElementTeeth: model.EFormsToothChartElementTeeth && model.EFormsToothChartElementTeeth.map(function (i) { return EFormsToothChartElementTooth_1.EFormsToothChartElementTooth_Factory.CreateOutgoing(i); }),
            EFormTemplates: model.EFormTemplates && model.EFormTemplates.map(function (i) { return EFormsTemplate_1.EFormsTemplate_Factory.CreateOutgoing(i); }),
            Elastics: model.Elastics && model.Elastics.map(function (i) { return Elastic_1.Elastic_Factory.CreateOutgoing(i); }),
            ElasticTypes: model.ElasticTypes && model.ElasticTypes.map(function (i) { return ElasticType_1.ElasticType_Factory.CreateOutgoing(i); }),
            ElasticUsageTypes: model.ElasticUsageTypes && model.ElasticUsageTypes.map(function (i) { return ElasticUsageType_1.ElasticUsageType_Factory.CreateOutgoing(i); }),
            EmploymentStatuses: model.EmploymentStatuses && model.EmploymentStatuses.map(function (i) { return EmploymentStatus_1.EmploymentStatus_Factory.CreateOutgoing(i); }),
            EnterpriseConsultations: model.EnterpriseConsultations && model.EnterpriseConsultations.map(function (i) { return EnterpriseConsultation_1.EnterpriseConsultation_Factory.CreateOutgoing(i); }),
            EnterpriseConsultationsAuditLogs: model.EnterpriseConsultationsAuditLogs && model.EnterpriseConsultationsAuditLogs.map(function (i) { return EnterpriseConsultationsAuditLog_1.EnterpriseConsultationsAuditLog_Factory.CreateOutgoing(i); }),
            EnterpriseConsultationSubjectLinks: model.EnterpriseConsultationSubjectLinks && model.EnterpriseConsultationSubjectLinks.map(function (i) { return EnterpriseConsultationSubjectLink_1.EnterpriseConsultationSubjectLink_Factory.CreateOutgoing(i); }),
            EnterpriseLocationDepartments: model.EnterpriseLocationDepartments && model.EnterpriseLocationDepartments.map(function (i) { return EnterpriseLocationDepartment_1.EnterpriseLocationDepartment_Factory.CreateOutgoing(i); }),
            EnterpriseLocations: model.EnterpriseLocations && model.EnterpriseLocations.map(function (i) { return EnterpriseLocation_1.EnterpriseLocation_Factory.CreateOutgoing(i); }),
            EnterpriseReportingColumnDefinitions: model.EnterpriseReportingColumnDefinitions && model.EnterpriseReportingColumnDefinitions.map(function (i) { return EnterpriseReportingColumnDefinition_1.EnterpriseReportingColumnDefinition_Factory.CreateOutgoing(i); }),
            EnterpriseReportingReports: model.EnterpriseReportingReports && model.EnterpriseReportingReports.map(function (i) { return EnterpriseReportingReport_1.EnterpriseReportingReport_Factory.CreateOutgoing(i); }),
            EnterpriseUserLocations: model.EnterpriseUserLocations && model.EnterpriseUserLocations.map(function (i) { return EnterpriseUserLocation_1.EnterpriseUserLocation_Factory.CreateOutgoing(i); }),
            EntityTypes: model.EntityTypes && model.EntityTypes.map(function (i) { return EntityType_1.EntityType_Factory.CreateOutgoing(i); }),
            EPaymentDevices: model.EPaymentDevices && model.EPaymentDevices.map(function (i) { return EPaymentDevice_1.EPaymentDevice_Factory.CreateOutgoing(i); }),
            EPaymentMerchants: model.EPaymentMerchants && model.EPaymentMerchants.map(function (i) { return EPaymentMerchant_1.EPaymentMerchant_Factory.CreateOutgoing(i); }),
            EscortTypes: model.EscortTypes && model.EscortTypes.map(function (i) { return EscortType_1.EscortType_Factory.CreateOutgoing(i); }),
            EServicesReports: model.EServicesReports && model.EServicesReports.map(function (i) { return EServicesReport_1.EServicesReport_Factory.CreateOutgoing(i); }),
            EServicesSettings: model.EServicesSettings && model.EServicesSettings.map(function (i) { return EServicesSetting_1.EServicesSetting_Factory.CreateOutgoing(i); }),
            EstateReferralAdditionalProcedures: model.EstateReferralAdditionalProcedures && model.EstateReferralAdditionalProcedures.map(function (i) { return EstateReferralAdditionalProcedure_1.EstateReferralAdditionalProcedure_Factory.CreateOutgoing(i); }),
            EstateReferralAuditLogs: model.EstateReferralAuditLogs && model.EstateReferralAuditLogs.map(function (i) { return EstateReferralAuditLog_1.EstateReferralAuditLog_Factory.CreateOutgoing(i); }),
            EstateReferralRejectionReasons: model.EstateReferralRejectionReasons && model.EstateReferralRejectionReasons.map(function (i) { return EstateReferralRejectionReason_1.EstateReferralRejectionReason_Factory.CreateOutgoing(i); }),
            EstateReferrals: model.EstateReferrals && model.EstateReferrals.map(function (i) { return EstateReferral_1.EstateReferral_Factory.CreateOutgoing(i); }),
            EthnicityTypes: model.EthnicityTypes && model.EthnicityTypes.map(function (i) { return EthnicityType_1.EthnicityType_Factory.CreateOutgoing(i); }),
            GenderTypes: model.GenderTypes && model.GenderTypes.map(function (i) { return GenderType_1.GenderType_Factory.CreateOutgoing(i); }),
            HealthMeasurements: model.HealthMeasurements && model.HealthMeasurements.map(function (i) { return HealthMeasurement_1.HealthMeasurement_Factory.CreateOutgoing(i); }),
            HealthMeasurementTypes: model.HealthMeasurementTypes && model.HealthMeasurementTypes.map(function (i) { return HealthMeasurementType_1.HealthMeasurementType_Factory.CreateOutgoing(i); }),
            HISIntegrationQueryHistory: model.HISIntegrationQueryHistory && model.HISIntegrationQueryHistory.map(function (i) { return HISIntegrationQueryHistory_1.HISIntegrationQueryHistory_Factory.CreateOutgoing(i); }),
            ImagingOrders: model.ImagingOrders && model.ImagingOrders.map(function (i) { return ImagingOrder_1.ImagingOrder_Factory.CreateOutgoing(i); }),
            ImagingReferenceTypes: model.ImagingReferenceTypes && model.ImagingReferenceTypes.map(function (i) { return ImagingReferenceType_1.ImagingReferenceType_Factory.CreateOutgoing(i); }),
            ImagingRequests: model.ImagingRequests && model.ImagingRequests.map(function (i) { return CreateImagingRequest_1.CreateImagingRequest_Factory.CreateOutgoing(i); }),
            ImplantBrands: model.ImplantBrands && model.ImplantBrands.map(function (i) { return ImplantBrand_1.ImplantBrand_Factory.CreateOutgoing(i); }),
            ImplantBrandSizes: model.ImplantBrandSizes && model.ImplantBrandSizes.map(function (i) { return ImplantBrandSize_1.ImplantBrandSize_Factory.CreateOutgoing(i); }),
            ImplantDetails: model.ImplantDetails && model.ImplantDetails.map(function (i) { return ImplantDetail_1.ImplantDetail_Factory.CreateOutgoing(i); }),
            ImplantDetailVersions: model.ImplantDetailVersions && model.ImplantDetailVersions.map(function (i) { return ImplantDetailVersion_1.ImplantDetailVersion_Factory.CreateOutgoing(i); }),
            ImplantManufacturers: model.ImplantManufacturers && model.ImplantManufacturers.map(function (i) { return ImplantManufacturer_1.ImplantManufacturer_Factory.CreateOutgoing(i); }),
            ImplantRecords: model.ImplantRecords && model.ImplantRecords.map(function (i) { return ImplantRecord_1.ImplantRecord_Factory.CreateOutgoing(i); }),
            InitialBalances: model.InitialBalances && model.InitialBalances.map(function (i) { return InitialBalance_1.InitialBalance_Factory.CreateOutgoing(i); }),
            InsuranceClaimAttachmentFiles: model.InsuranceClaimAttachmentFiles && model.InsuranceClaimAttachmentFiles.map(function (i) { return InsuranceClaimAttachmentFile_1.InsuranceClaimAttachmentFile_Factory.CreateOutgoing(i); }),
            InsuranceClaimAttachments: model.InsuranceClaimAttachments && model.InsuranceClaimAttachments.map(function (i) { return InsuranceClaimAttachment_1.InsuranceClaimAttachment_Factory.CreateOutgoing(i); }),
            InsuranceClaimBatchSubmissions: model.InsuranceClaimBatchSubmissions && model.InsuranceClaimBatchSubmissions.map(function (i) { return InsuranceClaimBatchSubmission_1.InsuranceClaimBatchSubmission_Factory.CreateOutgoing(i); }),
            InsuranceClaimContinuationItems: model.InsuranceClaimContinuationItems && model.InsuranceClaimContinuationItems.map(function (i) { return InsuranceClaimContinuationItem_1.InsuranceClaimContinuationItem_Factory.CreateOutgoing(i); }),
            InsuranceClaimItemAttachmentRequirements: model.InsuranceClaimItemAttachmentRequirements && model.InsuranceClaimItemAttachmentRequirements.map(function (i) { return InsuranceClaimItemAttachmentRequirement_1.InsuranceClaimItemAttachmentRequirement_Factory.CreateOutgoing(i); }),
            InsuranceClaimItems: model.InsuranceClaimItems && model.InsuranceClaimItems.map(function (i) { return InsuranceClaimItem_1.InsuranceClaimItem_Factory.CreateOutgoing(i); }),
            InsuranceClaimNotes: model.InsuranceClaimNotes && model.InsuranceClaimNotes.map(function (i) { return InsuranceClaimNote_1.InsuranceClaimNote_Factory.CreateOutgoing(i); }),
            InsuranceClaimNoteTypes: model.InsuranceClaimNoteTypes && model.InsuranceClaimNoteTypes.map(function (i) { return InsuranceClaimNoteType_1.InsuranceClaimNoteType_Factory.CreateOutgoing(i); }),
            InsuranceClaims: model.InsuranceClaims && model.InsuranceClaims.map(function (i) { return InsuranceClaim_1.InsuranceClaim_Factory.CreateOutgoing(i); }),
            InsuranceClaimStatusCodes: model.InsuranceClaimStatusCodes && model.InsuranceClaimStatusCodes.map(function (i) { return InsuranceClaimStatusCode_1.InsuranceClaimStatusCode_Factory.CreateOutgoing(i); }),
            InsuranceClaimTypes: model.InsuranceClaimTypes && model.InsuranceClaimTypes.map(function (i) { return InsuranceClaimType_1.InsuranceClaimType_Factory.CreateOutgoing(i); }),
            InsuranceEligibilityTypes: model.InsuranceEligibilityTypes && model.InsuranceEligibilityTypes.map(function (i) { return InsuranceEligibilityType_1.InsuranceEligibilityType_Factory.CreateOutgoing(i); }),
            InsurancePaperClaimFormats: model.InsurancePaperClaimFormats && model.InsurancePaperClaimFormats.map(function (i) { return InsurancePaperClaimFormat_1.InsurancePaperClaimFormat_Factory.CreateOutgoing(i); }),
            InsurancePayerDetails: model.InsurancePayerDetails && model.InsurancePayerDetails.map(function (i) { return InsurancePayerDetail_1.InsurancePayerDetail_Factory.CreateOutgoing(i); }),
            InsurancePayers: model.InsurancePayers && model.InsurancePayers.map(function (i) { return InsurancePayer_1.InsurancePayer_Factory.CreateOutgoing(i); }),
            InsurancePayerTypes: model.InsurancePayerTypes && model.InsurancePayerTypes.map(function (i) { return InsurancePayerType_1.InsurancePayerType_Factory.CreateOutgoing(i); }),
            InsurancePlanDetails: model.InsurancePlanDetails && model.InsurancePlanDetails.map(function (i) { return InsurancePlanDetail_1.InsurancePlanDetail_Factory.CreateOutgoing(i); }),
            InsurancePlans: model.InsurancePlans && model.InsurancePlans.map(function (i) { return InsurancePlan_1.InsurancePlan_Factory.CreateOutgoing(i); }),
            InsurancePolicies: model.InsurancePolicies && model.InsurancePolicies.map(function (i) { return InsurancePolicy_1.InsurancePolicy_Factory.CreateOutgoing(i); }),
            InsurancePolicyCoverageTypes: model.InsurancePolicyCoverageTypes && model.InsurancePolicyCoverageTypes.map(function (i) { return InsurancePolicyCoverageType_1.InsurancePolicyCoverageType_Factory.CreateOutgoing(i); }),
            ITeroScans: model.ITeroScans && model.ITeroScans.map(function (i) { return ITeroScan_1.ITeroScan_Factory.CreateOutgoing(i); }),
            IVLocations: model.IVLocations && model.IVLocations.map(function (i) { return IVLocation_1.IVLocation_Factory.CreateOutgoing(i); }),
            IVTypes: model.IVTypes && model.IVTypes.map(function (i) { return IVType_1.IVType_Factory.CreateOutgoing(i); }),
            JobStatuses: model.JobStatuses && model.JobStatuses.map(function (i) { return JobStatus_1.JobStatus_Factory.CreateOutgoing(i); }),
            LabCases: model.LabCases && model.LabCases.map(function (i) { return LabCase_1.LabCase_Factory.CreateOutgoing(i); }),
            Labs: model.Labs && model.Labs.map(function (i) { return Lab_1.Lab_Factory.CreateOutgoing(i); }),
            LabtracDoctors: model.LabtracDoctors && model.LabtracDoctors.map(function (i) { return LabtracDoctor_1.LabtracDoctor_Factory.CreateOutgoing(i); }),
            LabtracEnclosures: model.LabtracEnclosures && model.LabtracEnclosures.map(function (i) { return LabtracEnclosure_1.LabtracEnclosure_Factory.CreateOutgoing(i); }),
            LabtracItemOptions: model.LabtracItemOptions && model.LabtracItemOptions.map(function (i) { return LabtracItemOption_1.LabtracItemOption_Factory.CreateOutgoing(i); }),
            LabtracLabs: model.LabtracLabs && model.LabtracLabs.map(function (i) { return LabtracLab_1.LabtracLab_Factory.CreateOutgoing(i); }),
            LabtracShippingRoutes: model.LabtracShippingRoutes && model.LabtracShippingRoutes.map(function (i) { return LabtracShippingRoute_1.LabtracShippingRoute_Factory.CreateOutgoing(i); }),
            Languages: model.Languages && model.Languages.map(function (i) { return Language_1.Language_Factory.CreateOutgoing(i); }),
            LegacyIdentifiers: model.LegacyIdentifiers && model.LegacyIdentifiers.map(function (i) { return LegacyIdentifier_1.LegacyIdentifier_Factory.CreateOutgoing(i); }),
            LocationAccountsReceivable: model.LocationAccountsReceivable && model.LocationAccountsReceivable.map(function (i) { return LocationAccountsReceivable_1.LocationAccountsReceivable_Factory.CreateOutgoing(i); }),
            LocationBulkStatementSettings: model.LocationBulkStatementSettings && model.LocationBulkStatementSettings.map(function (i) { return LocationBulkStatementSetting_1.LocationBulkStatementSetting_Factory.CreateOutgoing(i); }),
            LocationClaimsActivities: model.LocationClaimsActivities && model.LocationClaimsActivities.map(function (i) { return LocationClaimsActivity_1.LocationClaimsActivity_Factory.CreateOutgoing(i); }),
            LocationDefaultWorkingDays: model.LocationDefaultWorkingDays && model.LocationDefaultWorkingDays.map(function (i) { return LocationDefaultWorkingDay_1.LocationDefaultWorkingDay_Factory.CreateOutgoing(i); }),
            LocationFinancialActivities: model.LocationFinancialActivities && model.LocationFinancialActivities.map(function (i) { return LocationFinancialActivity_1.LocationFinancialActivity_Factory.CreateOutgoing(i); }),
            Locations: model.Locations && model.Locations.map(function (i) { return Location_1.Location_Factory.CreateOutgoing(i); }),
            LocationSettings: model.LocationSettings && model.LocationSettings.map(function (i) { return LocationSetting_1.LocationSetting_Factory.CreateOutgoing(i); }),
            LocationWorkingDays: model.LocationWorkingDays && model.LocationWorkingDays.map(function (i) { return LocationWorkingDay_1.LocationWorkingDay_Factory.CreateOutgoing(i); }),
            LocationWorkingDayTimes: model.LocationWorkingDayTimes && model.LocationWorkingDayTimes.map(function (i) { return LocationWorkingDayTime_1.LocationWorkingDayTime_Factory.CreateOutgoing(i); }),
            MasterDataDistributionEvents: model.MasterDataDistributionEvents && model.MasterDataDistributionEvents.map(function (i) { return MasterDataDistributionEvent_1.MasterDataDistributionEvent_Factory.CreateOutgoing(i); }),
            MasterDataDistributionEventStats: model.MasterDataDistributionEventStats && model.MasterDataDistributionEventStats.map(function (i) { return MasterDataDistributionEventStats_1.MasterDataDistributionEventStats_Factory.CreateOutgoing(i); }),
            MawidDeltaExtractProblems: model.MawidDeltaExtractProblems && model.MawidDeltaExtractProblems.map(function (i) { return MawidDeltaExtractProblem_1.MawidDeltaExtractProblem_Factory.CreateOutgoing(i); }),
            MawidDeltaExtracts: model.MawidDeltaExtracts && model.MawidDeltaExtracts.map(function (i) { return MawidDeltaExtract_1.MawidDeltaExtract_Factory.CreateOutgoing(i); }),
            MawidDeltaResponses: model.MawidDeltaResponses && model.MawidDeltaResponses.map(function (i) { return MawidDeltaResponse_1.MawidDeltaResponse_Factory.CreateOutgoing(i); }),
            MawidFirstExtractProblems: model.MawidFirstExtractProblems && model.MawidFirstExtractProblems.map(function (i) { return MawidFirstExtractProblem_1.MawidFirstExtractProblem_Factory.CreateOutgoing(i); }),
            MawidFirstExtracts: model.MawidFirstExtracts && model.MawidFirstExtracts.map(function (i) { return MawidFirstExtract_1.MawidFirstExtract_Factory.CreateOutgoing(i); }),
            MawidFirstResponses: model.MawidFirstResponses && model.MawidFirstResponses.map(function (i) { return MawidFirstResponse_1.MawidFirstResponse_Factory.CreateOutgoing(i); }),
            MawidRequests: model.MawidRequests && model.MawidRequests.map(function (i) { return MawidRequest_1.MawidRequest_Factory.CreateOutgoing(i); }),
            MedicalAlertTypes: model.MedicalAlertTypes && model.MedicalAlertTypes.map(function (i) { return MedicalAlertType_1.MedicalAlertType_Factory.CreateOutgoing(i); }),
            MedicalClaimAdditionalInfo: model.MedicalClaimAdditionalInfo && model.MedicalClaimAdditionalInfo.map(function (i) { return MedicalClaimAdditionalInfo_1.MedicalClaimAdditionalInfo_Factory.CreateOutgoing(i); }),
            MedicalClaimItemAdditionalInfo: model.MedicalClaimItemAdditionalInfo && model.MedicalClaimItemAdditionalInfo.map(function (i) { return MedicalClaimItemAdditionalInfo_1.MedicalClaimItemAdditionalInfo_Factory.CreateOutgoing(i); }),
            MedicalInsuranceTypes: model.MedicalInsuranceTypes && model.MedicalInsuranceTypes.map(function (i) { return MedicalInsuranceType_1.MedicalInsuranceType_Factory.CreateOutgoing(i); }),
            MedicalQuestionnaireQuestionGroups: model.MedicalQuestionnaireQuestionGroups && model.MedicalQuestionnaireQuestionGroups.map(function (i) { return MedicalQuestionnaireQuestionGroup_1.MedicalQuestionnaireQuestionGroup_Factory.CreateOutgoing(i); }),
            MedicalQuestionnaireQuestions: model.MedicalQuestionnaireQuestions && model.MedicalQuestionnaireQuestions.map(function (i) { return MedicalQuestionnaireQuestion_1.MedicalQuestionnaireQuestion_Factory.CreateOutgoing(i); }),
            MedicalQuestionnaires: model.MedicalQuestionnaires && model.MedicalQuestionnaires.map(function (i) { return MedicalQuestionnaire_1.MedicalQuestionnaire_Factory.CreateOutgoing(i); }),
            MedicationTypes: model.MedicationTypes && model.MedicationTypes.map(function (i) { return MedicationType_1.MedicationType_Factory.CreateOutgoing(i); }),
            MouthRegionGroups: model.MouthRegionGroups && model.MouthRegionGroups.map(function (i) { return MouthRegionGroup_1.MouthRegionGroup_Factory.CreateOutgoing(i); }),
            NewChargeProcedures: model.NewChargeProcedures && model.NewChargeProcedures.map(function (i) { return NewChargeProcedure_1.NewChargeProcedure_Factory.CreateOutgoing(i); }),
            NewCharges: model.NewCharges && model.NewCharges.map(function (i) { return NewCharge_1.NewCharge_Factory.CreateOutgoing(i); }),
            NewPaymentResults: model.NewPaymentResults && model.NewPaymentResults.map(function (i) { return NewPaymentResult_1.NewPaymentResult_Factory.CreateOutgoing(i); }),
            NewPayments: model.NewPayments && model.NewPayments.map(function (i) { return NewPayment_1.NewPayment_Factory.CreateOutgoing(i); }),
            NewPaymentSplits: model.NewPaymentSplits && model.NewPaymentSplits.map(function (i) { return NewPaymentSplit_1.NewPaymentSplit_Factory.CreateOutgoing(i); }),
            NHSAcornAssessments: model.NHSAcornAssessments && model.NHSAcornAssessments.map(function (i) { return NHSAcornAssessment_1.NHSAcornAssessment_Factory.CreateOutgoing(i); }),
            NHSActivityBands: model.NHSActivityBands && model.NHSActivityBands.map(function (i) { return NHSActivityBand_1.NHSActivityBand_Factory.CreateOutgoing(i); }),
            NHSActivityBandValues: model.NHSActivityBandValues && model.NHSActivityBandValues.map(function (i) { return NHSActivityBandValue_1.NHSActivityBandValue_Factory.CreateOutgoing(i); }),
            NHSActivityValueSets: model.NHSActivityValueSets && model.NHSActivityValueSets.map(function (i) { return NHSActivityValueSet_1.NHSActivityValueSet_Factory.CreateOutgoing(i); }),
            NHSCaseMixRecordedCriteria: model.NHSCaseMixRecordedCriteria && model.NHSCaseMixRecordedCriteria.map(function (i) { return NHSCaseMixRecordedCriteria_1.NHSCaseMixRecordedCriteria_Factory.CreateOutgoing(i); }),
            NHSCaseMixRecordings: model.NHSCaseMixRecordings && model.NHSCaseMixRecordings.map(function (i) { return NHSCaseMixRecording_1.NHSCaseMixRecording_Factory.CreateOutgoing(i); }),
            NHSCaseMixScoreWeights: model.NHSCaseMixScoreWeights && model.NHSCaseMixScoreWeights.map(function (i) { return NHSCaseMixScoreWeight_1.NHSCaseMixScoreWeight_Factory.CreateOutgoing(i); }),
            NHSCategories: model.NHSCategories && model.NHSCategories.map(function (i) { return NHSCategory_1.NHSCategory_Factory.CreateOutgoing(i); }),
            NHSCategoryBands: model.NHSCategoryBands && model.NHSCategoryBands.map(function (i) { return NHSCategoryBand_1.NHSCategoryBand_Factory.CreateOutgoing(i); }),
            NHSCategoryDates: model.NHSCategoryDates && model.NHSCategoryDates.map(function (i) { return NHSCategoryDate_1.NHSCategoryDate_Factory.CreateOutgoing(i); }),
            NHSCDSBands: model.NHSCDSBands && model.NHSCDSBands.map(function (i) { return NHSCDSBand_1.NHSCDSBand_Factory.CreateOutgoing(i); }),
            NHSChargeBands: model.NHSChargeBands && model.NHSChargeBands.map(function (i) { return NHSChargeBand_1.NHSChargeBand_Factory.CreateOutgoing(i); }),
            NHSChargeBandValues: model.NHSChargeBandValues && model.NHSChargeBandValues.map(function (i) { return NHSChargeBandValue_1.NHSChargeBandValue_Factory.CreateOutgoing(i); }),
            NHSChargeValueSets: model.NHSChargeValueSets && model.NHSChargeValueSets.map(function (i) { return NHSChargeValueSet_1.NHSChargeValueSet_Factory.CreateOutgoing(i); }),
            NHSClaimComments: model.NHSClaimComments && model.NHSClaimComments.map(function (i) { return NHSClaimComment_1.NHSClaimComment_Factory.CreateOutgoing(i); }),
            NHSClaimMessageTypes: model.NHSClaimMessageTypes && model.NHSClaimMessageTypes.map(function (i) { return NHSClaimMessageType_1.NHSClaimMessageType_Factory.CreateOutgoing(i); }),
            NHSClaims: model.NHSClaims && model.NHSClaims.map(function (i) { return NHSClaim_1.NHSClaim_Factory.CreateOutgoing(i); }),
            NHSClaimStatusChanges: model.NHSClaimStatusChanges && model.NHSClaimStatusChanges.map(function (i) { return NHSClaimStatusChange_1.NHSClaimStatusChange_Factory.CreateOutgoing(i); }),
            NHSClaimSubmissions: model.NHSClaimSubmissions && model.NHSClaimSubmissions.map(function (i) { return NHSClaimSubmission_1.NHSClaimSubmission_Factory.CreateOutgoing(i); }),
            NHSClinicalDataSets: model.NHSClinicalDataSets && model.NHSClinicalDataSets.map(function (i) { return NHSClinicalDataSet_1.NHSClinicalDataSet_Factory.CreateOutgoing(i); }),
            NHSCLRNs: model.NHSCLRNs && model.NHSCLRNs.map(function (i) { return NHSCLRN_1.NHSCLRN_Factory.CreateOutgoing(i); }),
            NHSContractAdjustments: model.NHSContractAdjustments && model.NHSContractAdjustments.map(function (i) { return NHSContractAdjustment_1.NHSContractAdjustment_Factory.CreateOutgoing(i); }),
            NHSContractMemberships: model.NHSContractMemberships && model.NHSContractMemberships.map(function (i) { return NHSContractMembership_1.NHSContractMembership_Factory.CreateOutgoing(i); }),
            NHSContractPeriodMonths: model.NHSContractPeriodMonths && model.NHSContractPeriodMonths.map(function (i) { return NHSContractPeriodMonth_1.NHSContractPeriodMonth_Factory.CreateOutgoing(i); }),
            NHSContractPeriods: model.NHSContractPeriods && model.NHSContractPeriods.map(function (i) { return NHSContractPeriod_1.NHSContractPeriod_Factory.CreateOutgoing(i); }),
            NHSContracts: model.NHSContracts && model.NHSContracts.map(function (i) { return NHSContract_1.NHSContract_Factory.CreateOutgoing(i); }),
            NHSContractTargets: model.NHSContractTargets && model.NHSContractTargets.map(function (i) { return NHSContractTarget_1.NHSContractTarget_Factory.CreateOutgoing(i); }),
            NHSCourseClaimMessageInfos: model.NHSCourseClaimMessageInfos && model.NHSCourseClaimMessageInfos.map(function (i) { return NHSCourseClaimMessageInfo_1.NHSCourseClaimMessageInfo_Factory.CreateOutgoing(i); }),
            NHSCourseDetails: model.NHSCourseDetails && model.NHSCourseDetails.map(function (i) { return NHSCourseDetail_1.NHSCourseDetail_Factory.CreateOutgoing(i); }),
            NHSCourseDetailsOrtho: model.NHSCourseDetailsOrtho && model.NHSCourseDetailsOrtho.map(function (i) { return NHSCourseDetailOrtho_1.NHSCourseDetailOrtho_Factory.CreateOutgoing(i); }),
            NHSCourseSnapshots: model.NHSCourseSnapshots && model.NHSCourseSnapshots.map(function (i) { return NHSCourseSnapshot_1.NHSCourseSnapshot_Factory.CreateOutgoing(i); }),
            NHSExemptCodes: model.NHSExemptCodes && model.NHSExemptCodes.map(function (i) { return NHSExemptCode_1.NHSExemptCode_Factory.CreateOutgoing(i); }),
            NHSExemptionChecks: model.NHSExemptionChecks && model.NHSExemptionChecks.map(function (i) { return NHSExemptionCheck_1.NHSExemptionCheck_Factory.CreateOutgoing(i); }),
            NHSFP17TriageDetails: model.NHSFP17TriageDetails && model.NHSFP17TriageDetails.map(function (i) { return NHSFP17TriageDetail_1.NHSFP17TriageDetail_Factory.CreateOutgoing(i); }),
            NHSInterchanges: model.NHSInterchanges && model.NHSInterchanges.map(function (i) { return NHSInterchange_1.NHSInterchange_Factory.CreateOutgoing(i); }),
            NHSLocations: model.NHSLocations && model.NHSLocations.map(function (i) { return NHSLocation_1.NHSLocation_Factory.CreateOutgoing(i); }),
            NHSMailboxes: model.NHSMailboxes && model.NHSMailboxes.map(function (i) { return NHSMailbox_1.NHSMailbox_Factory.CreateOutgoing(i); }),
            NHSPatientDeclarations: model.NHSPatientDeclarations && model.NHSPatientDeclarations.map(function (i) { return NHSPatientDeclaration_1.NHSPatientDeclaration_Factory.CreateOutgoing(i); }),
            NHSPatientDetails: model.NHSPatientDetails && model.NHSPatientDetails.map(function (i) { return NHSPatientDetail_1.NHSPatientDetail_Factory.CreateOutgoing(i); }),
            NHSProcessingErrors: model.NHSProcessingErrors && model.NHSProcessingErrors.map(function (i) { return NHSProcessingError_1.NHSProcessingError_Factory.CreateOutgoing(i); }),
            NHSRegions: model.NHSRegions && model.NHSRegions.map(function (i) { return NHSRegion_1.NHSRegion_Factory.CreateOutgoing(i); }),
            NHSRegistrationTypes: model.NHSRegistrationTypes && model.NHSRegistrationTypes.map(function (i) { return NHSRegistrationType_1.NHSRegistrationType_Factory.CreateOutgoing(i); }),
            NHSResponseHistoryEntries: model.NHSResponseHistoryEntries && model.NHSResponseHistoryEntries.map(function (i) { return NHSResponseHistory_1.NHSResponseHistory_Factory.CreateOutgoing(i); }),
            NHSSchedulePayments: model.NHSSchedulePayments && model.NHSSchedulePayments.map(function (i) { return NHSSchedulePayment_1.NHSSchedulePayment_Factory.CreateOutgoing(i); }),
            NHSSchedules: model.NHSSchedules && model.NHSSchedules.map(function (i) { return NHSSchedule_1.NHSSchedule_Factory.CreateOutgoing(i); }),
            NHSTransmissionFiles: model.NHSTransmissionFiles && model.NHSTransmissionFiles.map(function (i) { return NHSTransmissionFile_1.NHSTransmissionFile_Factory.CreateOutgoing(i); }),
            NHSTreatmentArrangements: model.NHSTreatmentArrangements && model.NHSTreatmentArrangements.map(function (i) { return NHSTreatmentArrangement_1.NHSTreatmentArrangement_Factory.CreateOutgoing(i); }),
            NHSTriageDetails: model.NHSTriageDetails && model.NHSTriageDetails.map(function (i) { return NHSTriageDetail_1.NHSTriageDetail_Factory.CreateOutgoing(i); }),
            NHSUserDetails: model.NHSUserDetails && model.NHSUserDetails.map(function (i) { return NHSUserDetail_1.NHSUserDetail_Factory.CreateOutgoing(i); }),
            NoteContentTypes: model.NoteContentTypes && model.NoteContentTypes.map(function (i) { return NoteContentType_1.NoteContentType_Factory.CreateOutgoing(i); }),
            Notes: model.Notes && model.Notes.map(function (i) { return Note_1.Note_Factory.CreateOutgoing(i); }),
            NoteTemplateCategories: model.NoteTemplateCategories && model.NoteTemplateCategories.map(function (i) { return NoteTemplateCategory_1.NoteTemplateCategory_Factory.CreateOutgoing(i); }),
            NoteTemplateFavourites: model.NoteTemplateFavourites && model.NoteTemplateFavourites.map(function (i) { return NoteTemplateFavourite_1.NoteTemplateFavourite_Factory.CreateOutgoing(i); }),
            NoteTemplates: model.NoteTemplates && model.NoteTemplates.map(function (i) { return NoteTemplate_1.NoteTemplate_Factory.CreateOutgoing(i); }),
            NoteVersions: model.NoteVersions && model.NoteVersions.map(function (i) { return NoteVersion_1.NoteVersion_Factory.CreateOutgoing(i); }),
            OrthoContractChargeSchedules: model.OrthoContractChargeSchedules && model.OrthoContractChargeSchedules.map(function (i) { return OrthoContractChargeSchedule_1.OrthoContractChargeSchedule_Factory.CreateOutgoing(i); }),
            OrthoContractCorrespondenceSettings: model.OrthoContractCorrespondenceSettings && model.OrthoContractCorrespondenceSettings.map(function (i) { return OrthoContractCorrespondenceSetting_1.OrthoContractCorrespondenceSetting_Factory.CreateOutgoing(i); }),
            OrthoContractFrequencies: model.OrthoContractFrequencies && model.OrthoContractFrequencies.map(function (i) { return OrthoContractFrequency_1.OrthoContractFrequency_Factory.CreateOutgoing(i); }),
            OrthoContracts: model.OrthoContracts && model.OrthoContracts.map(function (i) { return OrthoContract_1.OrthoContract_Factory.CreateOutgoing(i); }),
            OrthoContractStatuses: model.OrthoContractStatuses && model.OrthoContractStatuses.map(function (i) { return OrthoContractStatus_1.OrthoContractStatus_Factory.CreateOutgoing(i); }),
            OrthoPatientTreatmentStatuses: model.OrthoPatientTreatmentStatuses && model.OrthoPatientTreatmentStatuses.map(function (i) { return OrthoPatientTreatmentStatus_1.OrthoPatientTreatmentStatus_Factory.CreateOutgoing(i); }),
            OrthoTreatmentStatuses: model.OrthoTreatmentStatuses && model.OrthoTreatmentStatuses.map(function (i) { return OrthoTreatmentStatus_1.OrthoTreatmentStatus_Factory.CreateOutgoing(i); }),
            PatientAllergies: model.PatientAllergies && model.PatientAllergies.map(function (i) { return PatientAllergy_1.PatientAllergy_Factory.CreateOutgoing(i); }),
            PatientAllergyVersions: model.PatientAllergyVersions && model.PatientAllergyVersions.map(function (i) { return PatientAllergyVersion_1.PatientAllergyVersion_Factory.CreateOutgoing(i); }),
            PatientArchiveRecords: model.PatientArchiveRecords && model.PatientArchiveRecords.map(function (i) { return PatientArchiveRecord_1.PatientArchiveRecord_Factory.CreateOutgoing(i); }),
            PatientConcerns: model.PatientConcerns && model.PatientConcerns.map(function (i) { return PatientConcern_1.PatientConcern_Factory.CreateOutgoing(i); }),
            PatientConnections: model.PatientConnections && model.PatientConnections.map(function (i) { return PatientConnection_1.PatientConnection_Factory.CreateOutgoing(i); }),
            PatientConnectionTypes: model.PatientConnectionTypes && model.PatientConnectionTypes.map(function (i) { return PatientConnectionType_1.PatientConnectionType_Factory.CreateOutgoing(i); }),
            PatientContactNotes: model.PatientContactNotes && model.PatientContactNotes.map(function (i) { return PatientContactNote_1.PatientContactNote_Factory.CreateOutgoing(i); }),
            PatientERxDetails: model.PatientERxDetails && model.PatientERxDetails.map(function (i) { return PatientERxDetail_1.PatientERxDetail_Factory.CreateOutgoing(i); }),
            PatientHistories: model.PatientHistories && model.PatientHistories.map(function (i) { return PatientHistory_1.PatientHistory_Factory.CreateOutgoing(i); }),
            PatientImageFavourites: model.PatientImageFavourites && model.PatientImageFavourites.map(function (i) { return PatientImageFavourite_1.PatientImageFavourite_Factory.CreateOutgoing(i); }),
            PatientImagingIdentifiers: model.PatientImagingIdentifiers && model.PatientImagingIdentifiers.map(function (i) { return PatientImagingIdentifier_1.PatientImagingIdentifier_Factory.CreateOutgoing(i); }),
            PatientLedgerBalances: model.PatientLedgerBalances && model.PatientLedgerBalances.map(function (i) { return PatientLedgerBalance_1.PatientLedgerBalance_Factory.CreateOutgoing(i); }),
            PatientMedications: model.PatientMedications && model.PatientMedications.map(function (i) { return PatientMedication_1.PatientMedication_Factory.CreateOutgoing(i); }),
            PatientMedicationVersions: model.PatientMedicationVersions && model.PatientMedicationVersions.map(function (i) { return PatientMedicationVersion_1.PatientMedicationVersion_Factory.CreateOutgoing(i); }),
            PatientNoteTypes: model.PatientNoteTypes && model.PatientNoteTypes.map(function (i) { return PatientNoteType_1.PatientNoteType_Factory.CreateOutgoing(i); }),
            PatientNotificationAlertDismissals: model.PatientNotificationAlertDismissals && model.PatientNotificationAlertDismissals.map(function (i) { return PatientNotificationAlertDismissal_1.PatientNotificationAlertDismissal_Factory.CreateOutgoing(i); }),
            PatientNotifications: model.PatientNotifications && model.PatientNotifications.map(function (i) { return PatientNotification_1.PatientNotification_Factory.CreateOutgoing(i); }),
            PatientPaymentMethods: model.PatientPaymentMethods && model.PatientPaymentMethods.map(function (i) { return PatientPaymentMethod_1.PatientPaymentMethod_Factory.CreateOutgoing(i); }),
            PatientProcedureCPTModifiers: model.PatientProcedureCPTModifiers && model.PatientProcedureCPTModifiers.map(function (i) { return PatientProcedureCPTModifiers_1.PatientProcedureCPTModifiers_Factory.CreateOutgoing(i); }),
            PatientProcedureGroups: model.PatientProcedureGroups && model.PatientProcedureGroups.map(function (i) { return PatientProcedureGroup_1.PatientProcedureGroup_Factory.CreateOutgoing(i); }),
            PatientProcedureNotes: model.PatientProcedureNotes && model.PatientProcedureNotes.map(function (i) { return PatientProcedureNote_1.PatientProcedureNote_Factory.CreateOutgoing(i); }),
            PatientProcedureNoteTypes: model.PatientProcedureNoteTypes && model.PatientProcedureNoteTypes.map(function (i) { return PatientProcedureNoteType_1.PatientProcedureNoteType_Factory.CreateOutgoing(i); }),
            PatientProcedures: model.PatientProcedures && model.PatientProcedures.map(function (i) { return PatientProcedure_1.PatientProcedure_Factory.CreateOutgoing(i); }),
            PatientRecallCycleUnitTypes: model.PatientRecallCycleUnitTypes && model.PatientRecallCycleUnitTypes.map(function (i) { return PatientRecallCycleUnitType_1.PatientRecallCycleUnitType_Factory.CreateOutgoing(i); }),
            PatientRecallInfo: model.PatientRecallInfo && model.PatientRecallInfo.map(function (i) { return PatientRecallInfo_1.PatientRecallInfo_Factory.CreateOutgoing(i); }),
            PatientRecallPreferences: model.PatientRecallPreferences && model.PatientRecallPreferences.map(function (i) { return PatientRecallPreference_1.PatientRecallPreference_Factory.CreateOutgoing(i); }),
            PatientRecalls: model.PatientRecalls && model.PatientRecalls.map(function (i) { return PatientRecall_1.PatientRecall_Factory.CreateOutgoing(i); }),
            PatientResponsibleParties: model.PatientResponsibleParties && model.PatientResponsibleParties.map(function (i) { return PatientResponsibleParty_1.PatientResponsibleParty_Factory.CreateOutgoing(i); }),
            PatientRxAuditLogs: model.PatientRxAuditLogs && model.PatientRxAuditLogs.map(function (i) { return PatientRxAuditLog_1.PatientRxAuditLog_Factory.CreateOutgoing(i); }),
            PatientRxs: model.PatientRxs && model.PatientRxs.map(function (i) { return PatientRx_1.PatientRx_Factory.CreateOutgoing(i); }),
            Patients: model.Patients && model.Patients.map(function (i) { return Patient_1.Patient_Factory.CreateOutgoing(i); }),
            PatientsMedicalAlerts: model.PatientsMedicalAlerts && model.PatientsMedicalAlerts.map(function (i) { return PatientsMedicalAlert_1.PatientsMedicalAlert_Factory.CreateOutgoing(i); }),
            PatientSnapshots: model.PatientSnapshots && model.PatientSnapshots.map(function (i) { return PatientSnapshot_1.PatientSnapshot_Factory.CreateOutgoing(i); }),
            PatientStatements: model.PatientStatements && model.PatientStatements.map(function (i) { return PatientStatement_1.PatientStatement_Factory.CreateOutgoing(i); }),
            PatientStatuses: model.PatientStatuses && model.PatientStatuses.map(function (i) { return PatientStatus_1.PatientStatus_Factory.CreateOutgoing(i); }),
            PatientTreatmentStatuses: model.PatientTreatmentStatuses && model.PatientTreatmentStatuses.map(function (i) { return PatientTreatmentStatus_1.PatientTreatmentStatus_Factory.CreateOutgoing(i); }),
            PatientWaitingListNotes: model.PatientWaitingListNotes && model.PatientWaitingListNotes.map(function (i) { return PatientWaitingListNote_1.PatientWaitingListNote_Factory.CreateOutgoing(i); }),
            PatientWaitingListNoteTypes: model.PatientWaitingListNoteTypes && model.PatientWaitingListNoteTypes.map(function (i) { return PatientWaitingListNoteType_1.PatientWaitingListNoteType_Factory.CreateOutgoing(i); }),
            PatientWaitingLists: model.PatientWaitingLists && model.PatientWaitingLists.map(function (i) { return PatientWaitingList_1.PatientWaitingList_Factory.CreateOutgoing(i); }),
            PatientWaitingListStatusHistory: model.PatientWaitingListStatusHistory && model.PatientWaitingListStatusHistory.map(function (i) { return PatientWaitingListStatusHistory_1.PatientWaitingListStatusHistory_Factory.CreateOutgoing(i); }),
            PatientWatches: model.PatientWatches && model.PatientWatches.map(function (i) { return PatientWatch_1.PatientWatch_Factory.CreateOutgoing(i); }),
            People: model.People && model.People.map(function (i) { return Person_1.Person_Factory.CreateOutgoing(i); }),
            PeopleAdditionalInfoKSA: model.PeopleAdditionalInfoKSA && model.PeopleAdditionalInfoKSA.map(function (i) { return PeopleAdditionalInfoKSA_1.PeopleAdditionalInfoKSA_Factory.CreateOutgoing(i); }),
            PerioExams: model.PerioExams && model.PerioExams.map(function (i) { return PerioExam_1.PerioExam_Factory.CreateOutgoing(i); }),
            PerioExamSettings: model.PerioExamSettings && model.PerioExamSettings.map(function (i) { return PerioExamSettings_1.PerioExamSettings_Factory.CreateOutgoing(i); }),
            PerioProblemThresholds: model.PerioProblemThresholds && model.PerioProblemThresholds.map(function (i) { return PerioProblemThresholds_1.PerioProblemThresholds_Factory.CreateOutgoing(i); }),
            PerioRecords: model.PerioRecords && model.PerioRecords.map(function (i) { return PerioRecord_1.PerioRecord_Factory.CreateOutgoing(i); }),
            PermissionGroups: model.PermissionGroups && model.PermissionGroups.map(function (i) { return PermissionGroup_1.PermissionGroup_Factory.CreateOutgoing(i); }),
            Pharmacies: model.Pharmacies && model.Pharmacies.map(function (i) { return Pharmacy_1.Pharmacy_Factory.CreateOutgoing(i); }),
            PhoenixDBConnectionString: model.PhoenixDBConnectionString,
            PhoneNumbers: model.PhoneNumbers && model.PhoneNumbers.map(function (i) { return PhoneNumber_1.PhoneNumber_Factory.CreateOutgoing(i); }),
            PhoneNumberTypes: model.PhoneNumberTypes && model.PhoneNumberTypes.map(function (i) { return PhoneNumberType_1.PhoneNumberType_Factory.CreateOutgoing(i); }),
            PostOpEvaluations: model.PostOpEvaluations && model.PostOpEvaluations.map(function (i) { return PostOpEvaluation_1.PostOpEvaluation_Factory.CreateOutgoing(i); }),
            PracticeEmailAccountTypes: model.PracticeEmailAccountTypes && model.PracticeEmailAccountTypes.map(function (i) { return PracticeEmailAccountType_1.PracticeEmailAccountType_Factory.CreateOutgoing(i); }),
            PreferredContactMethodTypes: model.PreferredContactMethodTypes && model.PreferredContactMethodTypes.map(function (i) { return PreferredContactMethodType_1.PreferredContactMethodType_Factory.CreateOutgoing(i); }),
            PreOpEvaluations: model.PreOpEvaluations && model.PreOpEvaluations.map(function (i) { return PreOpEvaluation_1.PreOpEvaluation_Factory.CreateOutgoing(i); }),
            PreScreenResponses: model.PreScreenResponses && model.PreScreenResponses.map(function (i) { return PreScreenResponse_1.PreScreenResponse_Factory.CreateOutgoing(i); }),
            ProcedureCategoryTypes: model.ProcedureCategoryTypes && model.ProcedureCategoryTypes.map(function (i) { return ProcedureCategoryType_1.ProcedureCategoryType_Factory.CreateOutgoing(i); }),
            ProcedureDiagnosisCodes: model.ProcedureDiagnosisCodes && model.ProcedureDiagnosisCodes.map(function (i) { return ProcedureDiagnosisCode_1.ProcedureDiagnosisCode_Factory.CreateOutgoing(i); }),
            ProcedureGroupings: model.ProcedureGroupings && model.ProcedureGroupings.map(function (i) { return ProcedureGrouping_1.ProcedureGrouping_Factory.CreateOutgoing(i); }),
            ProcedureLists: model.ProcedureLists && model.ProcedureLists.map(function (i) { return ProcedureList_1.ProcedureList_Factory.CreateOutgoing(i); }),
            Procedures: model.Procedures && model.Procedures.map(function (i) { return Procedure_1.Procedure_Factory.CreateOutgoing(i); }),
            ProviderFinancnialActivities: model.ProviderFinancnialActivities && model.ProviderFinancnialActivities.map(function (i) { return ProviderFinancialActivity_1.ProviderFinancialActivity_Factory.CreateOutgoing(i); }),
            ProviderTaxonomyCodes: model.ProviderTaxonomyCodes && model.ProviderTaxonomyCodes.map(function (i) { return ProviderTaxonomyCode_1.ProviderTaxonomyCode_Factory.CreateOutgoing(i); }),
            QuickbooksDefaultMappings: model.QuickbooksDefaultMappings && model.QuickbooksDefaultMappings.map(function (i) { return QuickbooksDefaultMapping_1.QuickbooksDefaultMapping_Factory.CreateOutgoing(i); }),
            QuickbooksLocationDefaultMappings: model.QuickbooksLocationDefaultMappings && model.QuickbooksLocationDefaultMappings.map(function (i) { return QuickbooksLocationDefaultMapping_1.QuickbooksLocationDefaultMapping_Factory.CreateOutgoing(i); }),
            QuickbooksLocationPaymentMappings: model.QuickbooksLocationPaymentMappings && model.QuickbooksLocationPaymentMappings.map(function (i) { return QuickbooksLocationPaymentMapping_1.QuickbooksLocationPaymentMapping_Factory.CreateOutgoing(i); }),
            QuickbooksPaymentMappings: model.QuickbooksPaymentMappings && model.QuickbooksPaymentMappings.map(function (i) { return QuickbooksPaymentMapping_1.QuickbooksPaymentMapping_Factory.CreateOutgoing(i); }),
            QuickbooksSettings: model.QuickbooksSettings && model.QuickbooksSettings.map(function (i) { return QuickbooksSetting_1.QuickbooksSetting_Factory.CreateOutgoing(i); }),
            QuickbooksTransmissionHistories: model.QuickbooksTransmissionHistories && model.QuickbooksTransmissionHistories.map(function (i) { return QuickbooksTransmissionHistory_1.QuickbooksTransmissionHistory_Factory.CreateOutgoing(i); }),
            QuickbooksTransmissions: model.QuickbooksTransmissions && model.QuickbooksTransmissions.map(function (i) { return QuickbooksTransmission_1.QuickbooksTransmission_Factory.CreateOutgoing(i); }),
            RecallDateGroupingsTypes: model.RecallDateGroupingsTypes && model.RecallDateGroupingsTypes.map(function (i) { return RecallDateGroupingsType_1.RecallDateGroupingsType_Factory.CreateOutgoing(i); }),
            RecipientTypes: model.RecipientTypes && model.RecipientTypes.map(function (i) { return RecipientType_1.RecipientType_Factory.CreateOutgoing(i); }),
            RecordStatuses: model.RecordStatuses && model.RecordStatuses.map(function (i) { return RecordStatus_1.RecordStatus_Factory.CreateOutgoing(i); }),
            ReferralFollowUps: model.ReferralFollowUps && model.ReferralFollowUps.map(function (i) { return ReferralFollowUp_1.ReferralFollowUp_Factory.CreateOutgoing(i); }),
            Referrals: model.Referrals && model.Referrals.map(function (i) { return Referral_1.Referral_Factory.CreateOutgoing(i); }),
            ReferralSourceImplantBrands: model.ReferralSourceImplantBrands && model.ReferralSourceImplantBrands.map(function (i) { return ReferralSourceImplantBrand_1.ReferralSourceImplantBrand_Factory.CreateOutgoing(i); }),
            ReferralSourcePracticeNames: model.ReferralSourcePracticeNames && model.ReferralSourcePracticeNames.map(function (i) { return ReferralSourcePracticeName_1.ReferralSourcePracticeName_Factory.CreateOutgoing(i); }),
            ReferralSources: model.ReferralSources && model.ReferralSources.map(function (i) { return ReferralSource_1.ReferralSource_Factory.CreateOutgoing(i); }),
            ReferralSourceSpecialties: model.ReferralSourceSpecialties && model.ReferralSourceSpecialties.map(function (i) { return ReferralSourceSpecialty_1.ReferralSourceSpecialty_Factory.CreateOutgoing(i); }),
            ReferralSourceTransactions: model.ReferralSourceTransactions && model.ReferralSourceTransactions.map(function (i) { return ReferralSourceTransactions_1.ReferralSourceTransactions_Factory.CreateOutgoing(i); }),
            ReferralSourceTransactionTypes: model.ReferralSourceTransactionTypes && model.ReferralSourceTransactionTypes.map(function (i) { return ReferralSourceTransactionType_1.ReferralSourceTransactionType_Factory.CreateOutgoing(i); }),
            ReferralTypes: model.ReferralTypes && model.ReferralTypes.map(function (i) { return ReferralType_1.ReferralType_Factory.CreateOutgoing(i); }),
            RelationshipToPolicyHolderTypes: model.RelationshipToPolicyHolderTypes && model.RelationshipToPolicyHolderTypes.map(function (i) { return RelationshipToPolicyHolderType_1.RelationshipToPolicyHolderType_Factory.CreateOutgoing(i); }),
            ReminderPatientDetails: model.ReminderPatientDetails && model.ReminderPatientDetails.map(function (i) { return ReminderPatientDetail_1.ReminderPatientDetail_Factory.CreateOutgoing(i); }),
            Reminders: model.Reminders && model.Reminders.map(function (i) { return Reminder_1.Reminder_Factory.CreateOutgoing(i); }),
            ReminderUsers: model.ReminderUsers && model.ReminderUsers.map(function (i) { return ReminderUser_1.ReminderUser_Factory.CreateOutgoing(i); }),
            ReportCategories: model.ReportCategories && model.ReportCategories.map(function (i) { return ReportCategory_1.ReportCategory_Factory.CreateOutgoing(i); }),
            ReportColumns: model.ReportColumns && model.ReportColumns.map(function (i) { return ReportColumn_1.ReportColumn_Factory.CreateOutgoing(i); }),
            ReportGenerator_ReportCategory: model.ReportGenerator_ReportCategory && model.ReportGenerator_ReportCategory.map(function (i) { return ReportGeneratorReportCategory_1.ReportGeneratorReportCategory_Factory.CreateOutgoing(i); }),
            ReportGenerator_ReportType: model.ReportGenerator_ReportType && model.ReportGenerator_ReportType.map(function (i) { return ReportGeneratorReportType_1.ReportGeneratorReportType_Factory.CreateOutgoing(i); }),
            ReportGeneratorColumns: model.ReportGeneratorColumns && model.ReportGeneratorColumns.map(function (i) { return ReportGeneratorColumn_1.ReportGeneratorColumn_Factory.CreateOutgoing(i); }),
            ReportGeneratorFilters: model.ReportGeneratorFilters && model.ReportGeneratorFilters.map(function (i) { return ReportGeneratorFilter_1.ReportGeneratorFilter_Factory.CreateOutgoing(i); }),
            ReportGroupBys: model.ReportGroupBys && model.ReportGroupBys.map(function (i) { return ReportGroupBy_1.ReportGroupBy_Factory.CreateOutgoing(i); }),
            ReportTypeCategoryColumns: model.ReportTypeCategoryColumns && model.ReportTypeCategoryColumns.map(function (i) { return ReportTypeCategoryColumn_1.ReportTypeCategoryColumn_Factory.CreateOutgoing(i); }),
            ReportTypeFilters: model.ReportTypeFilters && model.ReportTypeFilters.map(function (i) { return ReportTypeFilter_1.ReportTypeFilter_Factory.CreateOutgoing(i); }),
            ReportTypes: model.ReportTypes && model.ReportTypes.map(function (i) { return ReportType_1.ReportType_Factory.CreateOutgoing(i); }),
            RequestedReports: model.RequestedReports && model.RequestedReports.map(function (i) { return RequestedReport_1.RequestedReport_Factory.CreateOutgoing(i); }),
            RequestedReportStatus: model.RequestedReportStatus && model.RequestedReportStatus.map(function (i) { return RequestedReportStatus_1.RequestedReportStatus_Factory.CreateOutgoing(i); }),
            RxDosages: model.RxDosages && model.RxDosages.map(function (i) { return RxDosage_1.RxDosage_Factory.CreateOutgoing(i); }),
            RxDrugs: model.RxDrugs && model.RxDrugs.map(function (i) { return RxDrug_1.RxDrug_Factory.CreateOutgoing(i); }),
            RxFormulations: model.RxFormulations && model.RxFormulations.map(function (i) { return RxFormulation_1.RxFormulation_Factory.CreateOutgoing(i); }),
            RxSigs: model.RxSigs && model.RxSigs.map(function (i) { return RxSig_1.RxSig_Factory.CreateOutgoing(i); }),
            RxTemplates: model.RxTemplates && model.RxTemplates.map(function (i) { return RxTemplate_1.RxTemplate_Factory.CreateOutgoing(i); }),
            SalutationTypes: model.SalutationTypes && model.SalutationTypes.map(function (i) { return SalutationType_1.SalutationType_Factory.CreateOutgoing(i); }),
            SavedReports: model.SavedReports && model.SavedReports.map(function (i) { return SavedReport_1.SavedReport_Factory.CreateOutgoing(i); }),
            ScheduledPaymentResults: model.ScheduledPaymentResults && model.ScheduledPaymentResults.map(function (i) { return ScheduledPaymentResult_1.ScheduledPaymentResult_Factory.CreateOutgoing(i); }),
            ScheduledPayments: model.ScheduledPayments && model.ScheduledPayments.map(function (i) { return ScheduledPayment_1.ScheduledPayment_Factory.CreateOutgoing(i); }),
            ScheduleNotes: model.ScheduleNotes && model.ScheduleNotes.map(function (i) { return ScheduleNote_1.ScheduleNote_Factory.CreateOutgoing(i); }),
            ScheduleViewChairs: model.ScheduleViewChairs && model.ScheduleViewChairs.map(function (i) { return ScheduleViewChair_1.ScheduleViewChair_Factory.CreateOutgoing(i); }),
            ScheduleViews: model.ScheduleViews && model.ScheduleViews.map(function (i) { return ScheduleView_1.ScheduleView_Factory.CreateOutgoing(i); }),
            Schools: model.Schools && model.Schools.map(function (i) { return School_1.School_Factory.CreateOutgoing(i); }),
            ServiceLocationInsuranceClaims: model.ServiceLocationInsuranceClaims && model.ServiceLocationInsuranceClaims.map(function (i) { return ServiceLocationInsuranceClaim_1.ServiceLocationInsuranceClaim_Factory.CreateOutgoing(i); }),
            ServiceLocations: model.ServiceLocations && model.ServiceLocations.map(function (i) { return ServiceLocation_1.ServiceLocation_Factory.CreateOutgoing(i); }),
            ServiceLocationTransactions: model.ServiceLocationTransactions && model.ServiceLocationTransactions.map(function (i) { return ServiceLocationTransaction_1.ServiceLocationTransaction_Factory.CreateOutgoing(i); }),
            ServiceSchemes: model.ServiceSchemes && model.ServiceSchemes.map(function (i) { return ServiceScheme_1.ServiceScheme_Factory.CreateOutgoing(i); }),
            ServiceSchemeTypes: model.ServiceSchemeTypes && model.ServiceSchemeTypes.map(function (i) { return ServiceSchemeType_1.ServiceSchemeType_Factory.CreateOutgoing(i); }),
            SimpleStringContainers: model.SimpleStringContainers && model.SimpleStringContainers.map(function (i) { return SimpleStringContainer_1.SimpleStringContainer_Factory.CreateOutgoing(i); }),
            SmsApptConfirmationCodes: model.SmsApptConfirmationCodes && model.SmsApptConfirmationCodes.map(function (i) { return SmsApptConfirmationCode_1.SmsApptConfirmationCode_Factory.CreateOutgoing(i); }),
            SmsApptReminders: model.SmsApptReminders && model.SmsApptReminders.map(function (i) { return SmsApptReminder_1.SmsApptReminder_Factory.CreateOutgoing(i); }),
            SmsMessages: model.SmsMessages && model.SmsMessages.map(function (i) { return SmsMessage_1.SmsMessage_Factory.CreateOutgoing(i); }),
            SmsOptOuts: model.SmsOptOuts && model.SmsOptOuts.map(function (i) { return SmsOptOut_1.SmsOptOut_Factory.CreateOutgoing(i); }),
            SmsSettings: model.SmsSettings && model.SmsSettings.map(function (i) { return SmsSetting_1.SmsSetting_Factory.CreateOutgoing(i); }),
            SoftTissueConditions: model.SoftTissueConditions && model.SoftTissueConditions.map(function (i) { return SoftTissueCondition_1.SoftTissueCondition_Factory.CreateOutgoing(i); }),
            SoftTissueConditionTypes: model.SoftTissueConditionTypes && model.SoftTissueConditionTypes.map(function (i) { return SoftTissueConditionType_1.SoftTissueConditionType_Factory.CreateOutgoing(i); }),
            SoftTissueExamFindings: model.SoftTissueExamFindings && model.SoftTissueExamFindings.map(function (i) { return SoftTissueExamFinding_1.SoftTissueExamFinding_Factory.CreateOutgoing(i); }),
            SoftTissueExams: model.SoftTissueExams && model.SoftTissueExams.map(function (i) { return SoftTissueExam_1.SoftTissueExam_Factory.CreateOutgoing(i); }),
            SoftTissueRegions: model.SoftTissueRegions && model.SoftTissueRegions.map(function (i) { return SoftTissueRegion_1.SoftTissueRegion_Factory.CreateOutgoing(i); }),
            SplitTransactions: model.SplitTransactions && model.SplitTransactions.map(function (i) { return SplitTransaction_1.SplitTransaction_Factory.CreateOutgoing(i); }),
            StatesProvinces: model.StatesProvinces && model.StatesProvinces.map(function (i) { return StatesProvince_1.StatesProvince_Factory.CreateOutgoing(i); }),
            SurgeryDrugDosageTypes: model.SurgeryDrugDosageTypes && model.SurgeryDrugDosageTypes.map(function (i) { return SurgeryDrugDosageType_1.SurgeryDrugDosageType_Factory.CreateOutgoing(i); }),
            SurgeryDrugRecordings: model.SurgeryDrugRecordings && model.SurgeryDrugRecordings.map(function (i) { return SurgeryDrugRecording_1.SurgeryDrugRecording_Factory.CreateOutgoing(i); }),
            SurgeryDrugs: model.SurgeryDrugs && model.SurgeryDrugs.map(function (i) { return SurgeryDrug_1.SurgeryDrug_Factory.CreateOutgoing(i); }),
            SurgeryEventRecordings: model.SurgeryEventRecordings && model.SurgeryEventRecordings.map(function (i) { return SurgeryEventRecording_1.SurgeryEventRecording_Factory.CreateOutgoing(i); }),
            SurgeryEventTypes: model.SurgeryEventTypes && model.SurgeryEventTypes.map(function (i) { return SurgeryEventType_1.SurgeryEventType_Factory.CreateOutgoing(i); }),
            SurgeryGases: model.SurgeryGases && model.SurgeryGases.map(function (i) { return SurgeryGas_1.SurgeryGas_Factory.CreateOutgoing(i); }),
            SurgeryGasRecordings: model.SurgeryGasRecordings && model.SurgeryGasRecordings.map(function (i) { return SurgeryGasRecording_1.SurgeryGasRecording_Factory.CreateOutgoing(i); }),
            SurgeryInfos: model.SurgeryInfos && model.SurgeryInfos.map(function (i) { return SurgeryInfo_1.SurgeryInfo_Factory.CreateOutgoing(i); }),
            SurgeryRecordings: model.SurgeryRecordings && model.SurgeryRecordings.map(function (i) { return SurgeryRecording_1.SurgeryRecording_Factory.CreateOutgoing(i); }),
            SurgeryVitals: model.SurgeryVitals && model.SurgeryVitals.map(function (i) { return SurgeryVitals_1.SurgeryVitals_Factory.CreateOutgoing(i); }),
            SurgeryVitalsRecordingTimes: model.SurgeryVitalsRecordingTimes && model.SurgeryVitalsRecordingTimes.map(function (i) { return SurgeryVitalsRecordingTime_1.SurgeryVitalsRecordingTime_Factory.CreateOutgoing(i); }),
            Tags: model.Tags && model.Tags.map(function (i) { return Tag_1.Tag_Factory.CreateOutgoing(i); }),
            Teeth: model.Teeth && model.Teeth.map(function (i) { return Tooth_1.Tooth_Factory.CreateOutgoing(i); }),
            TenantSettings: model.TenantSettings && model.TenantSettings.map(function (i) { return TenantSetting_1.TenantSetting_Factory.CreateOutgoing(i); }),
            TimeLocks: model.TimeLocks && model.TimeLocks.map(function (i) { return TimeLock_1.TimeLock_Factory.CreateOutgoing(i); }),
            TimeLocksChairs: model.TimeLocksChairs && model.TimeLocksChairs.map(function (i) { return TimeLocksChair_1.TimeLocksChair_Factory.CreateOutgoing(i); }),
            TimeZones: model.TimeZones && model.TimeZones.map(function (i) { return TimeZone_1.TimeZone_Factory.CreateOutgoing(i); }),
            ToothNumberingTypes: model.ToothNumberingTypes && model.ToothNumberingTypes.map(function (i) { return ToothNumberingType_1.ToothNumberingType_Factory.CreateOutgoing(i); }),
            ToothRootMaskValues: model.ToothRootMaskValues && model.ToothRootMaskValues.map(function (i) { return ToothRootMaskValue_1.ToothRootMaskValue_Factory.CreateOutgoing(i); }),
            ToothSurfaceMaskValues: model.ToothSurfaceMaskValues && model.ToothSurfaceMaskValues.map(function (i) { return ToothSurfaceMaskValue_1.ToothSurfaceMaskValue_Factory.CreateOutgoing(i); }),
            ToothSurfaceNumberTypes: model.ToothSurfaceNumberTypes && model.ToothSurfaceNumberTypes.map(function (i) { return ToothSurfaceNumberType_1.ToothSurfaceNumberType_Factory.CreateOutgoing(i); }),
            ToothSurfaceTypes: model.ToothSurfaceTypes && model.ToothSurfaceTypes.map(function (i) { return ToothSurfaceType_1.ToothSurfaceType_Factory.CreateOutgoing(i); }),
            TransactionAdjustments: model.TransactionAdjustments && model.TransactionAdjustments.map(function (i) { return TransactionAdjustment_1.TransactionAdjustment_Factory.CreateOutgoing(i); }),
            TransactionPendingSplits: model.TransactionPendingSplits && model.TransactionPendingSplits.map(function (i) { return TransactionPendingSplit_1.TransactionPendingSplit_Factory.CreateOutgoing(i); }),
            Transactions: model.Transactions && model.Transactions.map(function (i) { return Transaction_1.Transaction_Factory.CreateOutgoing(i); }),
            TransactionSubTypes: model.TransactionSubTypes && model.TransactionSubTypes.map(function (i) { return TransactionSubType_1.TransactionSubType_Factory.CreateOutgoing(i); }),
            TransactionTypes: model.TransactionTypes && model.TransactionTypes.map(function (i) { return TransactionType_1.TransactionType_Factory.CreateOutgoing(i); }),
            TreatmentModalityTypes: model.TreatmentModalityTypes && model.TreatmentModalityTypes.map(function (i) { return TreatmentModalityType_1.TreatmentModalityType_Factory.CreateOutgoing(i); }),
            TreatmentPlanNotes: model.TreatmentPlanNotes && model.TreatmentPlanNotes.map(function (i) { return TreatmentPlanNote_1.TreatmentPlanNote_Factory.CreateOutgoing(i); }),
            TreatmentPlanNoteTypes: model.TreatmentPlanNoteTypes && model.TreatmentPlanNoteTypes.map(function (i) { return TreatmentPlanNoteType_1.TreatmentPlanNoteType_Factory.CreateOutgoing(i); }),
            TreatmentPlanProcedureGroups: model.TreatmentPlanProcedureGroups && model.TreatmentPlanProcedureGroups.map(function (i) { return TreatmentPlanProcedureGroup_1.TreatmentPlanProcedureGroup_Factory.CreateOutgoing(i); }),
            TreatmentPlans: model.TreatmentPlans && model.TreatmentPlans.map(function (i) { return TreatmentPlan_1.TreatmentPlan_Factory.CreateOutgoing(i); }),
            TreatmentPlanStatus: model.TreatmentPlanStatus && model.TreatmentPlanStatus.map(function (i) { return TreatmentPlanStatus_1.TreatmentPlanStatus_Factory.CreateOutgoing(i); }),
            TreatmentStatuses: model.TreatmentStatuses && model.TreatmentStatuses.map(function (i) { return TreatmentStatus_1.TreatmentStatus_Factory.CreateOutgoing(i); }),
            TreatmentSubTypes: model.TreatmentSubTypes && model.TreatmentSubTypes.map(function (i) { return TreatmentSubType_1.TreatmentSubType_Factory.CreateOutgoing(i); }),
            UserAdditionalInfoKSA: model.UserAdditionalInfoKSA && model.UserAdditionalInfoKSA.map(function (i) { return UserAdditionalInfoKSA_1.UserAdditionalInfoKSA_Factory.CreateOutgoing(i); }),
            UserChairMappings: model.UserChairMappings && model.UserChairMappings.map(function (i) { return UserChairMapping_1.UserChairMapping_Factory.CreateOutgoing(i); }),
            UserCredentials: model.UserCredentials && model.UserCredentials.map(function (i) { return UserCredentials_1.UserCredentials_Factory.CreateOutgoing(i); }),
            UserCredentialTypes: model.UserCredentialTypes && model.UserCredentialTypes.map(function (i) { return UserCredentialType_1.UserCredentialType_Factory.CreateOutgoing(i); }),
            UserDefaultWorkingDays: model.UserDefaultWorkingDays && model.UserDefaultWorkingDays.map(function (i) { return UserDefaultWorkingDay_1.UserDefaultWorkingDay_Factory.CreateOutgoing(i); }),
            UserLoginHistories: model.UserLoginHistories && model.UserLoginHistories.map(function (i) { return UserLoginHistory_1.UserLoginHistory_Factory.CreateOutgoing(i); }),
            UserNotifications: model.UserNotifications && model.UserNotifications.map(function (i) { return UserNotification_1.UserNotification_Factory.CreateOutgoing(i); }),
            UserPermissions: model.UserPermissions && model.UserPermissions.map(function (i) { return UserPermission_1.UserPermission_Factory.CreateOutgoing(i); }),
            UserRegistrations: model.UserRegistrations && model.UserRegistrations.map(function (i) { return UserRegistration_1.UserRegistration_Factory.CreateOutgoing(i); }),
            UserRegistrationTypes: model.UserRegistrationTypes && model.UserRegistrationTypes.map(function (i) { return UserRegistrationType_1.UserRegistrationType_Factory.CreateOutgoing(i); }),
            UserReportFavourites: model.UserReportFavourites && model.UserReportFavourites.map(function (i) { return UserReportFavourite_1.UserReportFavourite_Factory.CreateOutgoing(i); }),
            UserRoleTypes: model.UserRoleTypes && model.UserRoleTypes.map(function (i) { return UserRoleType_1.UserRoleType_Factory.CreateOutgoing(i); }),
            Users: model.Users && model.Users.map(function (i) { return User_1.User_Factory.CreateOutgoing(i); }),
            UserSettings: model.UserSettings && model.UserSettings.map(function (i) { return UserSetting_1.UserSetting_Factory.CreateOutgoing(i); }),
            UserTimecardOverrides: model.UserTimecardOverrides && model.UserTimecardOverrides.map(function (i) { return UserTimecardOverride_1.UserTimecardOverride_Factory.CreateOutgoing(i); }),
            UserTimecards: model.UserTimecards && model.UserTimecards.map(function (i) { return UserTimecard_1.UserTimecard_Factory.CreateOutgoing(i); }),
            UserWorkingDayChairs: model.UserWorkingDayChairs && model.UserWorkingDayChairs.map(function (i) { return UserWorkingDayChair_1.UserWorkingDayChair_Factory.CreateOutgoing(i); }),
            UserWorkingDayChairTimes: model.UserWorkingDayChairTimes && model.UserWorkingDayChairTimes.map(function (i) { return UserWorkingDayChairTime_1.UserWorkingDayChairTime_Factory.CreateOutgoing(i); }),
            UserWorkingDays: model.UserWorkingDays && model.UserWorkingDays.map(function (i) { return UserWorkingDay_1.UserWorkingDay_Factory.CreateOutgoing(i); }),
            UserWorkingDayTimes: model.UserWorkingDayTimes && model.UserWorkingDayTimes.map(function (i) { return UserWorkingDayTime_1.UserWorkingDayTime_Factory.CreateOutgoing(i); }),
            VitalsRecords: model.VitalsRecords && model.VitalsRecords.map(function (i) { return VitalsRecord_1.VitalsRecord_Factory.CreateOutgoing(i); }),
            VitalsRecordTypes: model.VitalsRecordTypes && model.VitalsRecordTypes.map(function (i) { return VitalsRecordType_1.VitalsRecordType_Factory.CreateOutgoing(i); }),
            VitalsRecordVersions: model.VitalsRecordVersions && model.VitalsRecordVersions.map(function (i) { return VitalsRecordVersion_1.VitalsRecordVersion_Factory.CreateOutgoing(i); }),
            WaitingLists: model.WaitingLists && model.WaitingLists.map(function (i) { return WaitingList_1.WaitingList_Factory.CreateOutgoing(i); }),
        };
        return result;
    };
    return PhoenixModel_Factory;
}());
exports.PhoenixModel_Factory = PhoenixModel_Factory;
